// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
// @import '~bootstrap/scss/bootstrap';
@import './style.css';
@import './perfect-scrollbar.css';
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import "~datatables.net-select-bs4/css/select.bootstrap4.css";
@import "toastr";
table.dataTable tbody tr.selected {
    color: black !important;
    background-color: #B0BFD9 !important;
}