/*
    Table of Contents

    Name                 : Efron
    Author               : Quixkit
    Author Portfolio     : https://themeforest.net/user/quixkit/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/

@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("../icons/simple-line-icons/css/simple-line-icons.css");
@import url("../icons/font-awesome-old/css/font-awesome.min.css");
@import url("../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("../icons/themify-icons/css/themify-icons.css");
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/avasta/css/style.css");
@import url(./animate/animate.min.css);
@import url(./aos/css/aos.min.css);
@import url(./perfect-scrollbar.css);
@import url(./metismenu/css/metisMenu.min.css);
.gradient_one {
    background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
    background: #f0a907;
    background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
    background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
    background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
    background: #4dedf5;
    background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
    background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
    background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3,
.morris-hover {
    background: #51f5ae;
    background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
    background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
    background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
    background: #f25521;
    background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
    background: #f53c79;
    background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
    background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
    background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
    background: #36b9d8;
    background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
    background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
    background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
    background: #4400eb;
    background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
    background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
    background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
    background: #F7B00F;
    background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
    background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
    background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9,
.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover,
.datepicker table tr td.selected,
.datepicker table tr td.active,
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background: #f31e7a !important;
    background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
    background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
    background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
    background: #f25521 !important;
    background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
    background: #3398fb;
    background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
    background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
    background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
    background: #36e1b4;
    background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
    background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
    background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
    background: #ffbf31;
    background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
    background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
    background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
    background: #23bdb8;
    background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
    background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
    background: #9a56ff;
    background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
    background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
    background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
    background: #f48665;
    background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
    background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
    background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
    background: #e36cd9;
    background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
    background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
    background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
    background: #a15cff;
    background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
    background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
    background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/


/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
    --blue: #5e72e4;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #EE3232;
    --orange: #fd7e14;
    --yellow: #FFFA6F;
    --green: #297F00;
    --teal: #20c997;
    --cyan: #3065D0;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #593bdb;
    --secondary: #673BB7;
    --success: #7ED321;
    --info: #50E3C2;
    --warning: #FFAA16;
    --danger: #FF1616;
    --light: #f8f9fa;
    --dark: #454545;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1440;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #BDBDC7;
    text-align: left;
    background-color: rgba(88, 115, 254, 0.04);
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #593bdb;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #391faa;
    text-decoration: underline;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #666666;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #3d4465;
}

h1,
.h1 {
    font-size: 2.25rem;
}

h2,
.h2 {
    font-size: 1.875rem;
}

h3,
.h3 {
    font-size: 1.5rem;
}

h4,
.h4 {
    font-size: 1.125rem;
}

h5,
.h5 {
    font-size: 1rem;
}

h6,
.h6 {
    font-size: 0.875rem;
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "\2014\00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: rgba(88, 115, 254, 0.04);
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-sm-first {
        order: -1;
    }
    .order-sm-last {
        order: 13;
    }
    .order-sm-0 {
        order: 0;
    }
    .order-sm-1 {
        order: 1;
    }
    .order-sm-2 {
        order: 2;
    }
    .order-sm-3 {
        order: 3;
    }
    .order-sm-4 {
        order: 4;
    }
    .order-sm-5 {
        order: 5;
    }
    .order-sm-6 {
        order: 6;
    }
    .order-sm-7 {
        order: 7;
    }
    .order-sm-8 {
        order: 8;
    }
    .order-sm-9 {
        order: 9;
    }
    .order-sm-10 {
        order: 10;
    }
    .order-sm-11 {
        order: 11;
    }
    .order-sm-12 {
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.33333%;
    }
    .offset-sm-2 {
        margin-left: 16.66667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.33333%;
    }
    .offset-sm-5 {
        margin-left: 41.66667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.33333%;
    }
    .offset-sm-8 {
        margin-left: 66.66667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.33333%;
    }
    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-md-first {
        order: -1;
    }
    .order-md-last {
        order: 13;
    }
    .order-md-0 {
        order: 0;
    }
    .order-md-1 {
        order: 1;
    }
    .order-md-2 {
        order: 2;
    }
    .order-md-3 {
        order: 3;
    }
    .order-md-4 {
        order: 4;
    }
    .order-md-5 {
        order: 5;
    }
    .order-md-6 {
        order: 6;
    }
    .order-md-7 {
        order: 7;
    }
    .order-md-8 {
        order: 8;
    }
    .order-md-9 {
        order: 9;
    }
    .order-md-10 {
        order: 10;
    }
    .order-md-11 {
        order: 11;
    }
    .order-md-12 {
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.33333%;
    }
    .offset-md-2 {
        margin-left: 16.66667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.33333%;
    }
    .offset-md-5 {
        margin-left: 41.66667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.33333%;
    }
    .offset-md-8 {
        margin-left: 66.66667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.33333%;
    }
    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-lg-first {
        order: -1;
    }
    .order-lg-last {
        order: 13;
    }
    .order-lg-0 {
        order: 0;
    }
    .order-lg-1 {
        order: 1;
    }
    .order-lg-2 {
        order: 2;
    }
    .order-lg-3 {
        order: 3;
    }
    .order-lg-4 {
        order: 4;
    }
    .order-lg-5 {
        order: 5;
    }
    .order-lg-6 {
        order: 6;
    }
    .order-lg-7 {
        order: 7;
    }
    .order-lg-8 {
        order: 8;
    }
    .order-lg-9 {
        order: 9;
    }
    .order-lg-10 {
        order: 10;
    }
    .order-lg-11 {
        order: 11;
    }
    .order-lg-12 {
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.33333%;
    }
    .offset-lg-2 {
        margin-left: 16.66667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.33333%;
    }
    .offset-lg-5 {
        margin-left: 41.66667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.33333%;
    }
    .offset-lg-8 {
        margin-left: 66.66667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.33333%;
    }
    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-xl-first {
        order: -1;
    }
    .order-xl-last {
        order: 13;
    }
    .order-xl-0 {
        order: 0;
    }
    .order-xl-1 {
        order: 1;
    }
    .order-xl-2 {
        order: 2;
    }
    .order-xl-3 {
        order: 3;
    }
    .order-xl-4 {
        order: 4;
    }
    .order-xl-5 {
        order: 5;
    }
    .order-xl-6 {
        order: 6;
    }
    .order-xl-7 {
        order: 7;
    }
    .order-xl-8 {
        order: 8;
    }
    .order-xl-9 {
        order: 9;
    }
    .order-xl-10 {
        order: 10;
    }
    .order-xl-11 {
        order: 11;
    }
    .order-xl-12 {
        order: 12;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.33333%;
    }
    .offset-xl-2 {
        margin-left: 16.66667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.33333%;
    }
    .offset-xl-5 {
        margin-left: 41.66667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.33333%;
    }
    .offset-xl-8 {
        margin-left: 66.66667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.33333%;
    }
    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1440) {
    .col-xxl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xxl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xxl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-xxl-first {
        order: -1;
    }
    .order-xxl-last {
        order: 13;
    }
    .order-xxl-0 {
        order: 0;
    }
    .order-xxl-1 {
        order: 1;
    }
    .order-xxl-2 {
        order: 2;
    }
    .order-xxl-3 {
        order: 3;
    }
    .order-xxl-4 {
        order: 4;
    }
    .order-xxl-5 {
        order: 5;
    }
    .order-xxl-6 {
        order: 6;
    }
    .order-xxl-7 {
        order: 7;
    }
    .order-xxl-8 {
        order: 8;
    }
    .order-xxl-9 {
        order: 9;
    }
    .order-xxl-10 {
        order: 10;
    }
    .order-xxl-11 {
        order: 11;
    }
    .order-xxl-12 {
        order: 12;
    }
    .offset-xxl-0 {
        margin-left: 0;
    }
    .offset-xxl-1 {
        margin-left: 8.33333%;
    }
    .offset-xxl-2 {
        margin-left: 16.66667%;
    }
    .offset-xxl-3 {
        margin-left: 25%;
    }
    .offset-xxl-4 {
        margin-left: 33.33333%;
    }
    .offset-xxl-5 {
        margin-left: 41.66667%;
    }
    .offset-xxl-6 {
        margin-left: 50%;
    }
    .offset-xxl-7 {
        margin-left: 58.33333%;
    }
    .offset-xxl-8 {
        margin-left: 66.66667%;
    }
    .offset-xxl-9 {
        margin-left: 75%;
    }
    .offset-xxl-10 {
        margin-left: 83.33333%;
    }
    .offset-xxl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #BDBDC7;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #EEEEEE;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #EEEEEE;
}

.table tbody+tbody {
    border-top: 2px solid #EEEEEE;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #EEEEEE;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #EEEEEE;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #BDBDC7;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #d1c8f5;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #a999ec;
}

.table-hover .table-primary:hover {
    background-color: #bfb2f1;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #bfb2f1;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d4c8eb;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b099da;
}

.table-hover .table-secondary:hover {
    background-color: #c5b5e4;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c5b5e4;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #dbf3c1;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #bce88c;
}

.table-hover .table-success:hover {
    background-color: #cfefac;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #cfefac;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #cef7ee;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #a4f0df;
}

.table-hover .table-info:hover {
    background-color: #b8f3e6;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #b8f3e6;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffe7be;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffd386;
}

.table-hover .table-warning:hover {
    background-color: #ffdea5;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffdea5;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #ffbebe;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ff8686;
}

.table-hover .table-danger:hover {
    background-color: #ffa5a5;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #ffa5a5;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #cbcbcb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #9e9e9e;
}

.table-hover .table-dark:hover {
    background-color: #bebebe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #bebebe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #EEEEEE;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1439.98) {
    .table-responsive-xxl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xxl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eaeaea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #b4a7ef;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76563rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #BDBDC7;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
    color: #666666;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #7ED321;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    color: #212529;
    background-color: rgba(126, 211, 33, 0.9);
    border-radius: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #7ED321;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%237ED321' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #7ED321;
    box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip {
    display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #7ED321;
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%237ED321' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #7ED321;
    box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #7ED321;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #7ED321;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    border-color: #7ED321;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    border-color: #97e245;
    background-color: #97e245;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #7ED321;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #7ED321;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    border-color: #7ED321;
    box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #FF1616;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(255, 22, 22, 0.9);
    border-radius: 0.25rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #FF1616;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF1616' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FF1616' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #FF1616;
    box-shadow: 0 0 0 0.2rem rgba(255, 22, 22, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #FF1616;
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF1616' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FF1616' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #FF1616;
    box-shadow: 0 0 0 0.2rem rgba(255, 22, 22, 0.25);
}

.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #FF1616;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #FF1616;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    border-color: #FF1616;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    border-color: #ff4949;
    background-color: #ff4949;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(255, 22, 22, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #FF1616;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #FF1616;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    border-color: #FF1616;
    box-shadow: 0 0 0 0.2rem rgba(255, 22, 22, 0.25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #BDBDC7;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #BDBDC7;
    text-decoration: none;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #593bdb;
    border-color: #593bdb;
}

.btn-primary:hover {
    color: #fff;
    background-color: #4425cb;
    border-color: #4123c0;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 88, 224, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #593bdb;
    border-color: #593bdb;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4123c0;
    border-color: #3d21b5;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 88, 224, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #673BB7;
    border-color: #673BB7;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #57329a;
    border-color: #512f90;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(126, 88, 194, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #673BB7;
    border-color: #673BB7;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #512f90;
    border-color: #4c2b87;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(126, 88, 194, 0.5);
}

.btn-success {
    color: #212529;
    background-color: #7ED321;
    border-color: #7ED321;
}

.btn-success:hover {
    color: #fff;
    background-color: #6ab21c;
    border-color: #64a71a;
}

.btn-success:focus,
.btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(112, 185, 34, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #212529;
    background-color: #7ED321;
    border-color: #7ED321;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #64a71a;
    border-color: #5d9c18;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(112, 185, 34, 0.5);
}

.btn-info {
    color: #212529;
    background-color: #50E3C2;
    border-color: #50E3C2;
}

.btn-info:hover {
    color: #212529;
    background-color: #2fdeb7;
    border-color: #24dcb3;
}

.btn-info:focus,
.btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 199, 171, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #212529;
    background-color: #50E3C2;
    border-color: #50E3C2;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #212529;
    background-color: #24dcb3;
    border-color: #22d2aa;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 199, 171, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #FFAA16;
    border-color: #FFAA16;
}

.btn-warning:hover {
    color: #212529;
    background-color: #ef9800;
    border-color: #e29000;
}

.btn-warning:focus,
.btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 150, 25, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #FFAA16;
    border-color: #FFAA16;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e29000;
    border-color: #d58700;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 150, 25, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #FF1616;
    border-color: #FF1616;
}

.btn-danger:hover {
    color: #fff;
    background-color: #ef0000;
    border-color: #e20000;
}

.btn-danger:focus,
.btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 57, 57, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #FF1616;
    border-color: #FF1616;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e20000;
    border-color: #d50000;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 57, 57, 0.5);
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #454545;
    border-color: #454545;
}

.btn-dark:hover {
    color: #fff;
    background-color: #323232;
    border-color: #2c2c2c;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 97, 97, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #454545;
    border-color: #454545;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #252525;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 97, 97, 0.5);
}

.btn-outline-primary {
    color: #593bdb;
    border-color: #593bdb;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #593bdb;
    border-color: #593bdb;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #593bdb;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #593bdb;
    border-color: #593bdb;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.5);
}

.btn-outline-secondary {
    color: #673BB7;
    border-color: #673BB7;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #673BB7;
    border-color: #673BB7;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 59, 183, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #673BB7;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #673BB7;
    border-color: #673BB7;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 59, 183, 0.5);
}

.btn-outline-success {
    color: #7ED321;
    border-color: #7ED321;
}

.btn-outline-success:hover {
    color: #212529;
    background-color: #7ED321;
    border-color: #7ED321;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #7ED321;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #7ED321;
    border-color: #7ED321;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.5);
}

.btn-outline-info {
    color: #50E3C2;
    border-color: #50E3C2;
}

.btn-outline-info:hover {
    color: #212529;
    background-color: #50E3C2;
    border-color: #50E3C2;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(80, 227, 194, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #50E3C2;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #50E3C2;
    border-color: #50E3C2;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(80, 227, 194, 0.5);
}

.btn-outline-warning {
    color: #FFAA16;
    border-color: #FFAA16;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #FFAA16;
    border-color: #FFAA16;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 170, 22, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #FFAA16;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #FFAA16;
    border-color: #FFAA16;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 170, 22, 0.5);
}

.btn-outline-danger {
    color: #FF1616;
    border-color: #FF1616;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #FF1616;
    border-color: #FF1616;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 22, 22, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #FF1616;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #FF1616;
    border-color: #FF1616;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 22, 22, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #454545;
    border-color: #454545;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #454545;
    border-color: #454545;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 69, 69, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #454545;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #454545;
    border-color: #454545;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 69, 69, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #593bdb;
    text-decoration: none;
}

.btn-link:hover {
    color: #391faa;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #BDBDC7;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1440) {
    .dropdown-menu-xxl-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xxl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #593bdb;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.76563rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
    z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
    z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #eaeaea;
    border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #593bdb;
    background-color: #593bdb;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #b4a7ef;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #d9d2f7;
    border-color: #d9d2f7;
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #593bdb;
    background-color: #593bdb;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(89, 59, 219, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(89, 59, 219, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(89, 59, 219, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(89, 59, 219, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select:focus {
    border-color: #b4a7ef;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.76563rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.09375rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #b4a7ef;
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 0.25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
    width: 100%;
    height: calc(1rem + 0.4rem);
    padding: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px rgba(88, 115, 254, 0.04), 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px rgba(88, 115, 254, 0.04), 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px rgba(88, 115, 254, 0.04), 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #593bdb;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #d9d2f7;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #593bdb;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #d9d2f7;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #593bdb;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #d9d2f7;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: rgba(88, 115, 254, 0.04);
    border-color: #dee2e6 #dee2e6 rgba(88, 115, 254, 0.04);
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #593bdb;
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1439.98) {
    .navbar-expand-xxl>.container,
    .navbar-expand-xxl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1440) {
    .navbar-expand-xxl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xxl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xxl>.container,
    .navbar-expand-xxl>.container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xxl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
    display: flex;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: flex;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }
    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card .card-header {
    margin-bottom: -1px;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #593bdb;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #391faa;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #593bdb;
    border-color: #593bdb;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #593bdb;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #4123c0;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(89, 59, 219, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: #673BB7;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #512f90;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(103, 59, 183, 0.5);
}

.badge-success {
    color: #212529;
    background-color: #7ED321;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #212529;
    background-color: #64a71a;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.5);
}

.badge-info {
    color: #212529;
    background-color: #50E3C2;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #212529;
    background-color: #24dcb3;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(80, 227, 194, 0.5);
}

.badge-warning {
    color: #212529;
    background-color: #FFAA16;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #212529;
    background-color: #e29000;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 170, 22, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #FF1616;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #e20000;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 22, 22, 0.5);
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #454545;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #2c2c2c;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(69, 69, 69, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3.8125rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #2e1f72;
    background-color: #ded8f8;
    border-color: #d1c8f5;
}

.alert-primary hr {
    border-top-color: #bfb2f1;
}

.alert-primary .alert-link {
    color: #1e144a;
}

.alert-secondary {
    color: #361f5f;
    background-color: #e1d8f1;
    border-color: #d4c8eb;
}

.alert-secondary hr {
    border-top-color: #c5b5e4;
}

.alert-secondary .alert-link {
    color: #201239;
}

.alert-success {
    color: #426e11;
    background-color: #e5f6d3;
    border-color: #dbf3c1;
}

.alert-success hr {
    border-top-color: #cfefac;
}

.alert-success .alert-link {
    color: #27420a;
}

.alert-info {
    color: #2a7665;
    background-color: #dcf9f3;
    border-color: #cef7ee;
}

.alert-info hr {
    border-top-color: #b8f3e6;
}

.alert-info .alert-link {
    color: #1d5045;
}

.alert-warning {
    color: #85580b;
    background-color: #ffeed0;
    border-color: #ffe7be;
}

.alert-warning hr {
    border-top-color: #ffdea5;
}

.alert-warning .alert-link {
    color: #563907;
}

.alert-danger {
    color: #850b0b;
    background-color: #ffd0d0;
    border-color: #ffbebe;
}

.alert-danger hr {
    border-top-color: #ffa5a5;
}

.alert-danger .alert-link {
    color: #560707;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #242424;
    background-color: #dadada;
    border-color: #cbcbcb;
}

.alert-dark hr {
    border-top-color: #bebebe;
}

.alert-dark .alert-link {
    color: #0b0b0b;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #593bdb;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #BDBDC7;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #593bdb;
    border-color: #593bdb;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 1440) {
    .list-group-horizontal-xxl {
        flex-direction: row;
    }
    .list-group-horizontal-xxl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.list-group-item-primary {
    color: #2e1f72;
    background-color: #d1c8f5;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #2e1f72;
    background-color: #bfb2f1;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2e1f72;
    border-color: #2e1f72;
}

.list-group-item-secondary {
    color: #361f5f;
    background-color: #d4c8eb;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #361f5f;
    background-color: #c5b5e4;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #361f5f;
    border-color: #361f5f;
}

.list-group-item-success {
    color: #426e11;
    background-color: #dbf3c1;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #426e11;
    background-color: #cfefac;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #426e11;
    border-color: #426e11;
}

.list-group-item-info {
    color: #2a7665;
    background-color: #cef7ee;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #2a7665;
    background-color: #b8f3e6;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #2a7665;
    border-color: #2a7665;
}

.list-group-item-warning {
    color: #85580b;
    background-color: #ffe7be;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #85580b;
    background-color: #ffdea5;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #85580b;
    border-color: #85580b;
}

.list-group-item-danger {
    color: #850b0b;
    background-color: #ffbebe;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #850b0b;
    background-color: #ffa5a5;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #850b0b;
    border-color: #850b0b;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #242424;
    background-color: #cbcbcb;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #242424;
    background-color: #bebebe;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #242424;
    border-color: #242424;
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #EEEEEE;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #EEEEEE;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.modal-footer> :not(:first-child) {
    margin-left: .25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: .25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #3d4465;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #BDBDC7;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #593bdb !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #4123c0 !important;
}

.bg-secondary {
    background-color: #673BB7 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #512f90 !important;
}

.bg-success {
    background-color: #7ED321 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #64a71a !important;
}

.bg-info {
    background-color: #50E3C2 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #24dcb3 !important;
}

.bg-warning {
    background-color: #FFAA16 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #e29000 !important;
}

.bg-danger {
    background-color: #FF1616 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #e20000 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #454545 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #2c2c2c !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #EEEEEE !important;
}

.border-top {
    border-top: 1px solid #EEEEEE !important;
}

.border-right {
    border-right: 1px solid #EEEEEE !important;
}

.border-bottom {
    border-bottom: 1px solid #EEEEEE !important;
}

.border-left {
    border-left: 1px solid #EEEEEE !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #593bdb !important;
}

.border-secondary {
    border-color: #673BB7 !important;
}

.border-success {
    border-color: #7ED321 !important;
}

.border-info {
    border-color: #50E3C2 !important;
}

.border-warning {
    border-color: #FFAA16 !important;
}

.border-danger {
    border-color: #FF1616 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #454545 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1440) {
    .d-xxl-none {
        display: none !important;
    }
    .d-xxl-inline {
        display: inline !important;
    }
    .d-xxl-inline-block {
        display: inline-block !important;
    }
    .d-xxl-block {
        display: block !important;
    }
    .d-xxl-table {
        display: table !important;
    }
    .d-xxl-table-row {
        display: table-row !important;
    }
    .d-xxl-table-cell {
        display: table-cell !important;
    }
    .d-xxl-flex {
        display: flex !important;
    }
    .d-xxl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1440) {
    .flex-xxl-row {
        flex-direction: row !important;
    }
    .flex-xxl-column {
        flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xxl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xxl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xxl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-xxl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
        justify-content: center !important;
    }
    .justify-content-xxl-between {
        justify-content: space-between !important;
    }
    .justify-content-xxl-around {
        justify-content: space-around !important;
    }
    .align-items-xxl-start {
        align-items: flex-start !important;
    }
    .align-items-xxl-end {
        align-items: flex-end !important;
    }
    .align-items-xxl-center {
        align-items: center !important;
    }
    .align-items-xxl-baseline {
        align-items: baseline !important;
    }
    .align-items-xxl-stretch {
        align-items: stretch !important;
    }
    .align-content-xxl-start {
        align-content: flex-start !important;
    }
    .align-content-xxl-end {
        align-content: flex-end !important;
    }
    .align-content-xxl-center {
        align-content: center !important;
    }
    .align-content-xxl-between {
        align-content: space-between !important;
    }
    .align-content-xxl-around {
        align-content: space-around !important;
    }
    .align-content-xxl-stretch {
        align-content: stretch !important;
    }
    .align-self-xxl-auto {
        align-self: auto !important;
    }
    .align-self-xxl-start {
        align-self: flex-start !important;
    }
    .align-self-xxl-end {
        align-self: flex-end !important;
    }
    .align-self-xxl-center {
        align-self: center !important;
    }
    .align-self-xxl-baseline {
        align-self: baseline !important;
    }
    .align-self-xxl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
}

@media (min-width: 1440) {
    .float-xxl-left {
        float: left !important;
    }
    .float-xxl-right {
        float: right !important;
    }
    .float-xxl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }
    .m-sm-n1 {
        margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
        margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
        margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }
    .m-sm-n4 {
        margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
        margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
    .m-md-n1 {
        margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }
    .m-md-n2 {
        margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }
    .m-md-n3 {
        margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }
    .m-md-n4 {
        margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }
    .m-md-n5 {
        margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }
    .m-lg-n1 {
        margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
        margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
        margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }
    .m-lg-n4 {
        margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
        margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }
    .m-xl-n1 {
        margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
        margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
        margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }
    .m-xl-n4 {
        margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
        margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1440) {
    .m-xxl-0 {
        margin: 0 !important;
    }
    .mt-xxl-0,
    .my-xxl-0 {
        margin-top: 0 !important;
    }
    .mr-xxl-0,
    .mx-xxl-0 {
        margin-right: 0 !important;
    }
    .mb-xxl-0,
    .my-xxl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xxl-0,
    .mx-xxl-0 {
        margin-left: 0 !important;
    }
    .m-xxl-1 {
        margin: 0.25rem !important;
    }
    .mt-xxl-1,
    .my-xxl-1 {
        margin-top: 0.25rem !important;
    }
    .mr-xxl-1,
    .mx-xxl-1 {
        margin-right: 0.25rem !important;
    }
    .mb-xxl-1,
    .my-xxl-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-xxl-1,
    .mx-xxl-1 {
        margin-left: 0.25rem !important;
    }
    .m-xxl-2 {
        margin: 0.5rem !important;
    }
    .mt-xxl-2,
    .my-xxl-2 {
        margin-top: 0.5rem !important;
    }
    .mr-xxl-2,
    .mx-xxl-2 {
        margin-right: 0.5rem !important;
    }
    .mb-xxl-2,
    .my-xxl-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-xxl-2,
    .mx-xxl-2 {
        margin-left: 0.5rem !important;
    }
    .m-xxl-3 {
        margin: 1rem !important;
    }
    .mt-xxl-3,
    .my-xxl-3 {
        margin-top: 1rem !important;
    }
    .mr-xxl-3,
    .mx-xxl-3 {
        margin-right: 1rem !important;
    }
    .mb-xxl-3,
    .my-xxl-3 {
        margin-bottom: 1rem !important;
    }
    .ml-xxl-3,
    .mx-xxl-3 {
        margin-left: 1rem !important;
    }
    .m-xxl-4 {
        margin: 1.5rem !important;
    }
    .mt-xxl-4,
    .my-xxl-4 {
        margin-top: 1.5rem !important;
    }
    .mr-xxl-4,
    .mx-xxl-4 {
        margin-right: 1.5rem !important;
    }
    .mb-xxl-4,
    .my-xxl-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xxl-4,
    .mx-xxl-4 {
        margin-left: 1.5rem !important;
    }
    .m-xxl-5 {
        margin: 3rem !important;
    }
    .mt-xxl-5,
    .my-xxl-5 {
        margin-top: 3rem !important;
    }
    .mr-xxl-5,
    .mx-xxl-5 {
        margin-right: 3rem !important;
    }
    .mb-xxl-5,
    .my-xxl-5 {
        margin-bottom: 3rem !important;
    }
    .ml-xxl-5,
    .mx-xxl-5 {
        margin-left: 3rem !important;
    }
    .p-xxl-0 {
        padding: 0 !important;
    }
    .pt-xxl-0,
    .py-xxl-0 {
        padding-top: 0 !important;
    }
    .pr-xxl-0,
    .px-xxl-0 {
        padding-right: 0 !important;
    }
    .pb-xxl-0,
    .py-xxl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xxl-0,
    .px-xxl-0 {
        padding-left: 0 !important;
    }
    .p-xxl-1 {
        padding: 0.25rem !important;
    }
    .pt-xxl-1,
    .py-xxl-1 {
        padding-top: 0.25rem !important;
    }
    .pr-xxl-1,
    .px-xxl-1 {
        padding-right: 0.25rem !important;
    }
    .pb-xxl-1,
    .py-xxl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-xxl-1,
    .px-xxl-1 {
        padding-left: 0.25rem !important;
    }
    .p-xxl-2 {
        padding: 0.5rem !important;
    }
    .pt-xxl-2,
    .py-xxl-2 {
        padding-top: 0.5rem !important;
    }
    .pr-xxl-2,
    .px-xxl-2 {
        padding-right: 0.5rem !important;
    }
    .pb-xxl-2,
    .py-xxl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-xxl-2,
    .px-xxl-2 {
        padding-left: 0.5rem !important;
    }
    .p-xxl-3 {
        padding: 1rem !important;
    }
    .pt-xxl-3,
    .py-xxl-3 {
        padding-top: 1rem !important;
    }
    .pr-xxl-3,
    .px-xxl-3 {
        padding-right: 1rem !important;
    }
    .pb-xxl-3,
    .py-xxl-3 {
        padding-bottom: 1rem !important;
    }
    .pl-xxl-3,
    .px-xxl-3 {
        padding-left: 1rem !important;
    }
    .p-xxl-4 {
        padding: 1.5rem !important;
    }
    .pt-xxl-4,
    .py-xxl-4 {
        padding-top: 1.5rem !important;
    }
    .pr-xxl-4,
    .px-xxl-4 {
        padding-right: 1.5rem !important;
    }
    .pb-xxl-4,
    .py-xxl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xxl-4,
    .px-xxl-4 {
        padding-left: 1.5rem !important;
    }
    .p-xxl-5 {
        padding: 3rem !important;
    }
    .pt-xxl-5,
    .py-xxl-5 {
        padding-top: 3rem !important;
    }
    .pr-xxl-5,
    .px-xxl-5 {
        padding-right: 3rem !important;
    }
    .pb-xxl-5,
    .py-xxl-5 {
        padding-bottom: 3rem !important;
    }
    .pl-xxl-5,
    .px-xxl-5 {
        padding-left: 3rem !important;
    }
    .m-xxl-n1 {
        margin: -0.25rem !important;
    }
    .mt-xxl-n1,
    .my-xxl-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-xxl-n1,
    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-xxl-n1,
    .my-xxl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-xxl-n1,
    .mx-xxl-n1 {
        margin-left: -0.25rem !important;
    }
    .m-xxl-n2 {
        margin: -0.5rem !important;
    }
    .mt-xxl-n2,
    .my-xxl-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-xxl-n2,
    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-xxl-n2,
    .my-xxl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-xxl-n2,
    .mx-xxl-n2 {
        margin-left: -0.5rem !important;
    }
    .m-xxl-n3 {
        margin: -1rem !important;
    }
    .mt-xxl-n3,
    .my-xxl-n3 {
        margin-top: -1rem !important;
    }
    .mr-xxl-n3,
    .mx-xxl-n3 {
        margin-right: -1rem !important;
    }
    .mb-xxl-n3,
    .my-xxl-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-xxl-n3,
    .mx-xxl-n3 {
        margin-left: -1rem !important;
    }
    .m-xxl-n4 {
        margin: -1.5rem !important;
    }
    .mt-xxl-n4,
    .my-xxl-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-xxl-n4,
    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-xxl-n4,
    .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-xxl-n4,
    .mx-xxl-n4 {
        margin-left: -1.5rem !important;
    }
    .m-xxl-n5 {
        margin: -3rem !important;
    }
    .mt-xxl-n5,
    .my-xxl-n5 {
        margin-top: -3rem !important;
    }
    .mr-xxl-n5,
    .mx-xxl-n5 {
        margin-right: -3rem !important;
    }
    .mb-xxl-n5,
    .my-xxl-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-xxl-n5,
    .mx-xxl-n5 {
        margin-left: -3rem !important;
    }
    .m-xxl-auto {
        margin: auto !important;
    }
    .mt-xxl-auto,
    .my-xxl-auto {
        margin-top: auto !important;
    }
    .mr-xxl-auto,
    .mx-xxl-auto {
        margin-right: auto !important;
    }
    .mb-xxl-auto,
    .my-xxl-auto {
        margin-bottom: auto !important;
    }
    .ml-xxl-auto,
    .mx-xxl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}

@media (min-width: 1440) {
    .text-xxl-left {
        text-align: left !important;
    }
    .text-xxl-right {
        text-align: right !important;
    }
    .text-xxl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #593bdb !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #391faa !important;
}

.text-secondary {
    color: #673BB7 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #46287d !important;
}

.text-success {
    color: #7ED321 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #569117 !important;
}

.text-info {
    color: #50E3C2 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #20c7a1 !important;
}

.text-warning {
    color: #FFAA16 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #c97f00 !important;
}

.text-danger {
    color: #FF1616 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #c90000 !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #cbd3da !important;
}

.text-dark {
    color: #454545 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1f1f1f !important;
}

.text-body {
    color: #BDBDC7 !important;
}

.text-muted {
    color: #666666 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }
    .table-dark {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #EEEEEE;
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #EEEEEE;
    }
}

.c-pointer {
    cursor: pointer;
}


/* Width percentage*/

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.w-65 {
    width: 65% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-85 {
    width: 85% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}

.w-100 {
    width: 100% !important;
}

* {
    outline: none;
    padding: 0;
}

*::after {
    margin: 0;
    padding: 0;
}

*::before {
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
    height: 100%;
    position: relative;
    max-width: 100%;
}

p {
    line-height: 1.8;
}

.box-shadow-none {
    box-shadow: none !important;
}

#main-wrapper {
    opacity: 0;
    transition: all 0.25s ease-in;
    overflow: hidden;
    position: relative;
}

#main-wrapper.show {
    opacity: 1;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    color: #BDBDC7;
}

a:hover,
a:focus,
a.active {
    color: #593bdb;
    text-decoration: none;
}

.content-body .container {
    margin-top: 30px;
}

.content-body .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
}

.content-heading {
    font-size: 16px;
    margin-bottom: 1.875rem;
    margin-top: 3.125rem;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
}

[direction="rtl"] .content-heading {
    text-align: right;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
}

.support-ticket {
    position: fixed;
    bottom: 30px;
    right: 15px;
    z-index: 999999;
}

.support-ticket-btn {
    width: 100px;
    background: #7CB442;
    animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
    border-radius: 50px;
    color: #fff;
    font-size: 8px;
    font-size: 16px;
    padding: 5px 10px 7px;
    text-align: center;
    display: inline-block;
    box-shadow: rgba(124, 180, 66, 0.7) 0px 8px 35px 0px;
}

.support-ticket-btn:hover,
.support-ticket-btn:focus {
    color: #fff;
}

@-webkit-keyframes crescendo {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes gXGDoR {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes crescendo {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes gXGDoR {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xxl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xxl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


/* Editable */

#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
}

.sk-three-bounce {
    margin: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: white;
}

.sk-three-bounce .sk-child {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #593bdb;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.footer {
    padding-left: 17.1875rem;
    background-color: rgba(88, 115, 254, 0.04);
}

.footer .copyright {
    padding: 0.9375rem;
}

.footer .copyright p {
    text-align: center;
    margin: 0;
}

.footer .copyright a {
    color: #593bdb;
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
    display: none;
}

@media only screen and (max-width: 767px) {
    [data-sidebar-style="overlay"] .nav-header .logo-abbr {
        display: block;
    }
}

[data-header-position="fixed"] .nav-header {
    position: fixed;
    left: auto;
}

.nav-header {
    height: 5rem;
    width: 17.1875rem;
    display: inline-block;
    text-align: left;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #343957;
    transition: all .2s ease;
    z-index: 4;
}

.nav-header .logo-abbr {
    max-width: 30px;
}

.nav-header .logo-compact {
    display: none;
}

@media only screen and (min-width: 1800px) {
    .nav-header {
        left: 3.125rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
    .nav-header {
        width: 14.375rem;
    }
}

.nav-header .brand-logo {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.125rem;
    color: #fff;
    text-decoration: none;
    padding-left: 1.25rem;
    font-weight: 700;
}

[data-sidebar-style="compact"] .nav-header .brand-logo,
[data-sidebar-style="mini"] .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .nav-header .brand-logo {
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
    }
}

.nav-header .brand-title {
    margin-left: 15px;
    max-width: 75px;
}

[data-theme-version="dark"] .nav-header .brand-title {
    background-position: 0 120%;
}

@media only screen and (max-width: 767px) {
    .nav-header {
        width: 3.75rem;
    }
    .nav-header .brand-title {
        display: none;
    }
}

.nav-control {
    cursor: pointer;
    position: absolute;
    right: -4.0625rem;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    font-size: 1.4rem;
    padding: 2px 0.5rem 0;
    border-radius: 2px;
}

@media only screen and (max-width: 767px) {
    .nav-control {
        right: -3rem;
    }
}

@media only screen and (max-width: 575px) {
    .nav-control {
        right: -2.5rem;
    }
}

.hamburger {
    display: inline-block;
    left: 0px;
    position: relative;
    top: 5px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 37px;
    z-index: 999;
}

.hamburger .line {
    background: #593bdb;
    display: block;
    height: 2px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
    width: 20px;
}

.hamburger .line:nth-child(2) {
    width: 26px;
}

.hamburger .line:nth-child(3) {
    width: 22px;
}

.hamburger:hover {
    cursor: pointer;
}

.hamburger:hover .line {
    width: 26px;
}


/*
    .hamburger.is-active {
      left: 60px;
    }
    */

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
    width: 10px;
    height: 2px;
}

.hamburger.is-active .line:nth-child(2) {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    width: 22px;
    height: 2px;
}

.hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(4px) rotate(45deg);
    transform: translateY(4px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-4px) rotate(-45deg);
    transform: translateY(-4px) rotate(-45deg);
}

@media (min-width: 767px) {
    [data-sidebar-style="compact"] .nav-control {
        display: none;
    }
    [data-sidebar-style="compact"] .nav-header {
        width: 9.375rem;
    }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
    display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .logo-abbr {
    display: block;
}

[data-sidebar-style="full"][data-layout="horizontal"] .nav-header .logo-abbr {
    display: none;
}

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
    display: none;
}

[data-sidebar-style="mini"] .nav-header .logo-abbr {
    display: block;
}

[data-sidebar-style="compact"] .nav-header .brand-title {
    display: none;
}

[data-sidebar-style="compact"] .nav-header .logo-compact {
    max-width: 75px;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
    width: 9.375rem;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
    display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
    display: block;
}

.header {
    height: 5rem;
    z-index: 1;
    position: relative;
    padding: 0rem;
    background-color: #fff;
    z-index: 3;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    padding-left: 17.1875rem;
    transition: all .2s ease;
}

.header .header-content {
    height: 100%;
    padding-left: 5.3125rem;
    padding-right: 1.875rem;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 575px) {
    .header .header-content {
        padding-left: 2.375rem;
    }
}

.header .navbar {
    padding: 0;
    height: 100%;
    width: 100%;
}

.header .navbar .navbar-collapse {
    height: 100%;
    width: 100%;
}


/* pulse in SVG */

svg.pulse-svg {
    overflow: visible;
}

svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    fill: #593bdb;
}

svg.pulse-svg .second-circle {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

svg.pulse-svg .third-circle {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}


/* pulse in CSS */

.pulse-css {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    border-radius: 3.5rem;
    height: .4rem;
    position: absolute;
    background: #593bdb;
    right: 5px;
    top: .6rem;
    width: .4rem;
}

.pulse-css:after,
.pulse-css:before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -.2rem;
    background-color: #593bdb;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
}

[direction="rtl"] .pulse-css:after,
[direction="rtl"] .pulse-css:before {
    left: auto;
    right: -.2rem;
}

@-webkit-keyframes pulse-me {
    0% {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    70% {
        opacity: 0.09;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}

@keyframes pulse-me {
    0% {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    70% {
        opacity: 0.09;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
    width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
    [data-sidebar-style="full"] .header,
    [data-sidebar-style="overlay"] .header {
        width: 100%;
        padding-left: 14.375rem;
    }
}

@media only screen and (max-width: 767px) {
    [data-sidebar-style="full"] .header,
    [data-sidebar-style="overlay"] .header {
        width: 100%;
        padding-left: 3.75rem;
    }
}

[data-sidebar-style="mini"] .header {
    width: 100%;
    padding-left: 3.75rem;
}

[data-sidebar-style="compact"] .header {
    width: 100%;
    padding-left: 9.375rem;
}

[data-sidebar-style="modern"] .header {
    width: 100%;
    padding-left: 9.375rem;
}

[data-header-position="fixed"] .header {
    position: fixed;
    top: 0;
    width: 100%;
}

[data-header-position="fixed"] .content-body {
    padding-top: 7rem;
}

[data-header-position="fixed"] .quixnav {
    margin-top: 0;
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 1199px;
}

[data-sidebar-style="modern"] .header .header-content {
    padding-left: 15px;
}

[data-sidebar-style="modern"] .header .header-content .navbar {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

.header-left {
    height: 100%;
    display: flex;
    align-items: center;
}

.header-left input {
    background: #fff !important;
    min-width: 300px;
    min-height: 44px;
    border-color: transparent;
    color: #454545 !important;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    box-shadow: none;
}

[direction="rtl"] .header-left input {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.header-left input:focus,
.header-left input:active,
.header-left input.active {
    border-color: transparent;
    box-shadow: none;
}

.header-left input::-webkit-input-placeholder {
    /* Edge */
    color: #454545;
}

.header-left input:-ms-input-placeholder {
    /* Internet Explorer */
    color: #454545;
}

.header-left input::placeholder {
    color: #454545;
}

.header-left .search_bar {
    display: flex;
    align-items: center;
    height: 100%;
}

.header-left .search_bar .dropdown-menu {
    box-shadow: none;
}

.header-left .search_bar .search_icon {
    background: #fff !important;
    height: 44px;
    padding: 5px 15px !important;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
}

.header-left .search_bar .search_icon i {
    font-size: 24px;
    color: #454545;
}

@media only screen and (max-width: 575px) {
    .header-left .search_bar {
        position: static;
    }
    .header-left .search_bar .dropdown-menu {
        width: 100vw;
        left: -98px;
    }
    [direction="rtl"] .header-left .search_bar .dropdown-menu {
        right: -98px;
    }
}

.header-left .search_bar .dropdown-menu,
.header-left .search_bar .dropdown-menu.show {
    border: 0px;
    background-color: transparent;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

@media only screen and (min-width: 768px) {
    .header-left .search_bar .dropdown-menu,
    .header-left .search_bar .dropdown-menu.show {
        left: 40px;
        top: -4px;
        transform: translateY(50%);
        display: block;
    }
}

[data-sidebar-style="compact"] .header-left {
    margin-left: 0;
}

.header-right {
    height: 100%;
}

.header-right .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
}

.header-right .nav-item .nav-link {
    color: #464a53;
    font-size: 18px;
}

.header-right>li:not(:first-child) {
    padding-left: 0.625rem;
}

@media only screen and (max-width: 767px) {
    .header-right .notification_dropdown {
        position: static;
    }
}

.header-right .notification_dropdown .nav-link {
    position: relative;
    color: #464a53;
}

.header-right .notification_dropdown .nav-link i {
    font-size: 18px;
    font-weight: 700;
}

.header-right .notification_dropdown .nav-link .badge {
    position: absolute;
    font-size: 0.625rem;
    border-radius: 50%;
    right: 0;
    top: 0;
    font-weight: normal;
    height: 17px;
    width: 17px;
    line-height: 6px;
    text-align: center;
    padding: 5px;
}

.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
    color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
    color: #454545;
}

.header-right .notification_dropdown .dropdown-item a:hover {
    text-decoration: none;
}

.header-right .dropdown-menu {
    border-color: #eaeaea;
    box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
}

[data-theme-version="dark"] .header-right .dropdown-menu {
    border-color: #424D63;
    box-shadow: none;
}

.header-right .header-profile>a i {
    font-weight: 700;
}

.header-right .header-profile .dropdown-menu {
    padding: 15px 0;
    min-width: 12.5rem;
}

.header-right .header-profile .dropdown-menu a:hover,
.header-right .header-profile .dropdown-menu a:focus,
.header-right .header-profile .dropdown-menu a.active {
    color: #593bdb;
    background: transparent;
}

.header-right .header-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.header-right .header-profile .dropdown-toggle i {
    font-size: 1.25rem;
}

@media only screen and (max-width: 575px) {
    .header-right .header-profile .dropdown-toggle span {
        display: none;
    }
}

.header-right .header-profile .profile_title {
    background: #593bdb;
    color: #fff;
    padding: 10px 20px;
}

.header-right .header-profile .profile_title h5 {
    color: #fff;
    margin-bottom: 3px;
}

.header-right .header-profile .dropdown-item {
    padding: 5px 24px;
}

.notification_dropdown .dropdown-menu-right {
    min-width: 275px;
    padding: 0rem 0 1rem;
    top: 60px;
}

.notification_dropdown .dropdown-menu-right .notification_title {
    background: #593bdb;
    color: #fff;
    padding: 10px 20px;
}

.notification_dropdown .dropdown-menu-right .notification_title h5 {
    color: #fff;
    margin-bottom: 3px;
}

.notification_dropdown .dropdown-menu-right .media {
    border-bottom: 1px solid #eaeaea;
    padding: 20px 25px;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .media {
    border-color: #424D63;
}

.notification_dropdown .dropdown-menu-right .media>span {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: inline-block;
    padding: 7px 9px;
    margin-right: 10px;
}

.notification_dropdown .dropdown-menu-right .media>span.success {
    background: #DEF2DB;
    color: #2F9B1B;
}

.notification_dropdown .dropdown-menu-right .media>span.primary {
    background: #D3E7FE;
    color: #176AD2;
}

.notification_dropdown .dropdown-menu-right .media>span.danger {
    background: #FFE0E0;
    color: #E24747;
}

.notification_dropdown .dropdown-menu-right .media .notify-time {
    width: 100% !important;
    margin-right: 0 !important;
    color: #828690;
}

.notification_dropdown .dropdown-menu-right .media p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    margin-bottom: 0;
    margin-top: 5px;
}

.notification_dropdown .dropdown-menu-right .all-notification {
    display: block;
    padding: 15px 30px 0;
    text-align: center;
}

.notification_dropdown .dropdown-menu-right .all-notification i {
    margin-left: 10px;
}

.rtl {
    text-align: right;
    direction: rtl;
}

.rtl .nav {
    padding-right: 0;
}

.rtl .navbar-nav .nav-item {
    float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
    margin-right: 1rem;
    margin-left: inherit;
}

.rtl th {
    text-align: right;
}

.rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem;
}

.rtl .dropdown-menu {
    right: 0;
    text-align: right;
}

.rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.25rem 0.25rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
    right: 0;
    left: inherit;
}

.rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat left 0.75rem center;
    background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
    border-radius: 0 0.25rem 0.25rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
    border-radius: 0 0.25rem 0.25rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
    border-radius: 0;
}

.rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem;
}

.rtl .custom-control-indicator {
    right: 0;
    left: inherit;
}

.rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit;
}

.rtl .list-group {
    padding-right: 0;
    padding-left: 40px;
}

.rtl .close {
    float: left;
}

.rtl .modal-header .close {
    margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
    margin-right: .25rem;
}

.rtl .alert-dismissible .close {
    right: inherit;
    left: 0;
}

.rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0;
}

.rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit;
}

.rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit;
}

.rtl .offset-1 {
    margin-right: 8.33333%;
    margin-left: 0;
}

.rtl .offset-2 {
    margin-right: 16.66667%;
    margin-left: 0;
}

.rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0;
}

.rtl .offset-4 {
    margin-right: 33.33333%;
    margin-left: 0;
}

.rtl .offset-5 {
    margin-right: 41.66667%;
    margin-left: 0;
}

.rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0;
}

.rtl .offset-7 {
    margin-right: 58.33333%;
    margin-left: 0;
}

.rtl .offset-8 {
    margin-right: 66.66667%;
    margin-left: 0;
}

.rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0;
}

.rtl .offset-10 {
    margin-right: 83.33333%;
    margin-left: 0;
}

.rtl .offset-11 {
    margin-right: 91.66667%;
    margin-left: 0;
}

@media (min-width: 576px) {
    .rtl .offset-sm-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-sm-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-sm-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-sm-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-sm-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-sm-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-sm-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-sm-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .rtl .offset-md-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-md-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-md-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-md-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-md-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-md-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-md-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-md-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .offset-lg-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-lg-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-lg-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-lg-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-lg-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-lg-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-lg-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-lg-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .offset-xl-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-xl-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-xl-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-xl-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-xl-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-xl-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-xl-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-xl-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 1440) {
    .rtl .offset-xxl-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-xxl-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-xxl-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-xxl-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-xxl-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-xxl-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-xxl-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-xxl-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

.rtl .mr-0,
.rtl .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
}

.rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-sm-1,
    .rtl .mx-sm-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-sm-1,
    .rtl .mx-sm-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-sm-2,
    .rtl .mx-sm-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-sm-2,
    .rtl .mx-sm-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-sm-3,
    .rtl .mx-sm-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-sm-3,
    .rtl .mx-sm-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-sm-4,
    .rtl .mx-sm-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-sm-4,
    .rtl .mx-sm-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-sm-1,
    .rtl .px-sm-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-sm-1,
    .rtl .px-sm-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-sm-2,
    .rtl .px-sm-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-sm-2,
    .rtl .px-sm-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-sm-3,
    .rtl .px-sm-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-sm-3,
    .rtl .px-sm-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-sm-4,
    .rtl .px-sm-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-sm-4,
    .rtl .px-sm-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-sm-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-sm-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-md-1,
    .rtl .mx-md-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-md-1,
    .rtl .mx-md-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-md-2,
    .rtl .mx-md-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-md-2,
    .rtl .mx-md-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-md-3,
    .rtl .mx-md-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-md-3,
    .rtl .mx-md-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-md-4,
    .rtl .mx-md-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-md-4,
    .rtl .mx-md-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-md-1,
    .rtl .px-md-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-md-1,
    .rtl .px-md-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-md-2,
    .rtl .px-md-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-md-2,
    .rtl .px-md-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-md-3,
    .rtl .px-md-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-md-3,
    .rtl .px-md-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-md-4,
    .rtl .px-md-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-md-4,
    .rtl .px-md-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-md-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-md-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-lg-1,
    .rtl .mx-lg-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-lg-1,
    .rtl .mx-lg-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-lg-2,
    .rtl .mx-lg-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-lg-2,
    .rtl .mx-lg-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-lg-3,
    .rtl .mx-lg-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-lg-3,
    .rtl .mx-lg-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-lg-4,
    .rtl .mx-lg-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-lg-4,
    .rtl .mx-lg-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-lg-1,
    .rtl .px-lg-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-lg-1,
    .rtl .px-lg-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-lg-2,
    .rtl .px-lg-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-lg-2,
    .rtl .px-lg-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-lg-3,
    .rtl .px-lg-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-lg-3,
    .rtl .px-lg-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-lg-4,
    .rtl .px-lg-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-lg-4,
    .rtl .px-lg-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-lg-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-lg-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-xl-1,
    .rtl .mx-xl-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-xl-1,
    .rtl .mx-xl-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-xl-2,
    .rtl .mx-xl-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-xl-2,
    .rtl .mx-xl-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-xl-3,
    .rtl .mx-xl-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-xl-3,
    .rtl .mx-xl-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-xl-4,
    .rtl .mx-xl-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-xl-4,
    .rtl .mx-xl-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-xl-1,
    .rtl .px-xl-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-xl-1,
    .rtl .px-xl-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-xl-2,
    .rtl .px-xl-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-xl-2,
    .rtl .px-xl-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-xl-3,
    .rtl .px-xl-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-xl-3,
    .rtl .px-xl-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-xl-4,
    .rtl .px-xl-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-xl-4,
    .rtl .px-xl-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-xl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-xl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1440) {
    .rtl .mr-xxl-0,
    .rtl .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-xxl-0,
    .rtl .mx-xxl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-xxl-1,
    .rtl .mx-xxl-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-xxl-1,
    .rtl .mx-xxl-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-xxl-2,
    .rtl .mx-xxl-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-xxl-2,
    .rtl .mx-xxl-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-xxl-3,
    .rtl .mx-xxl-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-xxl-3,
    .rtl .mx-xxl-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-xxl-4,
    .rtl .mx-xxl-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-xxl-4,
    .rtl .mx-xxl-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-xxl-5,
    .rtl .mx-xxl-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-xxl-5,
    .rtl .mx-xxl-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-xxl-0,
    .rtl .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-xxl-0,
    .rtl .px-xxl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-xxl-1,
    .rtl .px-xxl-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-xxl-1,
    .rtl .px-xxl-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-xxl-2,
    .rtl .px-xxl-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-xxl-2,
    .rtl .px-xxl-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-xxl-3,
    .rtl .px-xxl-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-xxl-3,
    .rtl .px-xxl-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-xxl-4,
    .rtl .px-xxl-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-xxl-4,
    .rtl .px-xxl-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-xxl-5,
    .rtl .px-xxl-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-xxl-5,
    .rtl .px-xxl-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-xxl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-xxl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

.rtl .text-right {
    text-align: left !important;
}

.rtl .text-left {
    text-align: right !important;
}

@media (min-width: 576px) {
    .rtl .text-sm-right {
        text-align: left !important;
    }
    .rtl .text-sm-left {
        text-align: right !important;
    }
}

@media (min-width: 768px) {
    .rtl .text-md-right {
        text-align: left !important;
    }
    .rtl .text-md-left {
        text-align: right !important;
    }
}

@media (min-width: 992px) {
    .rtl .text-lg-right {
        text-align: left !important;
    }
    .rtl .text-lg-left {
        text-align: right !important;
    }
}

@media (min-width: 1200px) {
    .rtl .text-xl-right {
        text-align: left !important;
    }
    .rtl .text-xl-left {
        text-align: right !important;
    }
}

@media (min-width: 1440) {
    .rtl .text-xxl-right {
        text-align: left !important;
    }
    .rtl .text-xxl-left {
        text-align: right !important;
    }
}

.rtl .mx-0 {
    margin-right: auto;
    margin-left: 0 !important;
}

.rtl .mx-0 {
    margin-left: auto;
    margin-right: 0 !important;
}

.rtl .mx-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
}

.rtl .mx-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
}

.rtl .mx-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
}

.rtl .mx-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
}

.rtl .mx-3 {
    margin-right: auto;
    margin-left: 1rem !important;
}

.rtl .mx-3 {
    margin-left: auto;
    margin-right: 1rem !important;
}

.rtl .mx-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
}

.rtl .mx-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
}

.rtl .mx-5 {
    margin-right: auto;
    margin-left: 3rem !important;
}

.rtl .mx-5 {
    margin-left: auto;
    margin-right: 3rem !important;
}

.rtl .px-0 {
    padding-right: auto;
    padding-left: 0 !important;
}

.rtl .px-0 {
    padding-left: auto;
    padding-right: 0 !important;
}

.rtl .px-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
}

.rtl .px-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
}

.rtl .px-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
}

.rtl .px-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
}

.rtl .px-3 {
    padding-right: auto;
    padding-left: 1rem !important;
}

.rtl .px-3 {
    padding-left: auto;
    padding-right: 1rem !important;
}

.rtl .px-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
}

.rtl .px-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
}

.rtl .px-5 {
    padding-right: auto;
    padding-left: 3rem !important;
}

.rtl .px-5 {
    padding-left: auto;
    padding-right: 3rem !important;
}

.rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .rtl .mx-sm-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-sm-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-sm-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-sm-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-sm-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-sm-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-sm-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-sm-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-sm-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-sm-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-sm-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-sm-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-sm-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-sm-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-sm-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-sm-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-sm-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-sm-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-sm-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-sm-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-sm-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-sm-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-sm-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-sm-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-sm-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-sm-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .rtl .mx-md-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-md-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-md-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-md-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-md-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-md-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-md-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-md-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-md-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-md-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-md-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-md-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-md-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-md-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-md-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-md-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-md-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-md-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-md-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-md-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-md-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-md-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-md-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-md-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-md-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-md-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .rtl .mx-lg-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-lg-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-lg-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-lg-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-lg-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-lg-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-lg-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-lg-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-lg-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-lg-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-lg-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-lg-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-lg-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-lg-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-lg-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-lg-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-lg-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-lg-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-lg-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-lg-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-lg-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-lg-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-lg-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-lg-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-lg-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-lg-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .rtl .mx-xl-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-xl-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-xl-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-xl-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-xl-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-xl-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-xl-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-xl-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-xl-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-xl-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-xl-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-xl-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-xl-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-xl-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-xl-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-xl-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-xl-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-xl-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-xl-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-xl-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-xl-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-xl-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-xl-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-xl-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-xl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-xl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1440) {
    .rtl .mx-xxl-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-xxl-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-xxl-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-xxl-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-xxl-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-xxl-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-xxl-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-xxl-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-xxl-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-xxl-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-xxl-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-xxl-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-xxl-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-xxl-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-xxl-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-xxl-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-xxl-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-xxl-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-xxl-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-xxl-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-xxl-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-xxl-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-xxl-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-xxl-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-xxl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-xxl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

.rtl .float-left {
    float: right !important;
}

.rtl .float-right {
    float: left !important;
}

.rtl .float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .rtl .float-sm-left {
        float: right !important;
    }
    .rtl .float-sm-right {
        float: left !important;
    }
    .rtl .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .rtl .float-md-left {
        float: right !important;
    }
    .rtl .float-md-right {
        float: left !important;
    }
    .rtl .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .rtl .float-lg-left {
        float: right !important;
    }
    .rtl .float-lg-right {
        float: left !important;
    }
    .rtl .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .rtl .float-xl-left {
        float: right !important;
    }
    .rtl .float-xl-right {
        float: left !important;
    }
    .rtl .float-xl-none {
        float: none !important;
    }
}

@media (min-width: 1440) {
    .rtl .float-xxl-left {
        float: right !important;
    }
    .rtl .float-xxl-right {
        float: left !important;
    }
    .rtl .float-xxl-none {
        float: none !important;
    }
}

[direction="rtl"][data-theme-version="dark"] .border,
[direction="rtl"][data-theme-version="dark"] .border-left,
[direction="rtl"][data-theme-version="dark"] .border-right {
    border-color: #424D63 !important;
}

[direction="rtl"] .text-right {
    text-align: left !important;
}

[direction="rtl"] .text-left {
    text-align: right !important;
}

[direction="rtl"] .border-right {
    border-left: 1px solid #eaeaea !important;
    border-right: 0 !important;
}

[direction="rtl"] .border-left {
    border-right: 1px solid #eaeaea !important;
    border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
    left: auto;
}

[direction="rtl"] .dropdown-menu-right {
    left: 0;
    right: auto;
}

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
    left: auto;
    right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
    left: auto;
    right: 0;
}

[direction="rtl"] .nav-header {
    text-align: right;
    right: auto;
}

[direction="rtl"] .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 2.5rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo,
[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
    padding-right: 0;
}

@media (max-width: 767.98px) {
    [direction="rtl"] .nav-header .brand-logo {
        padding-right: 0;
    }
}

[direction="rtl"] .nav-control {
    right: auto;
    left: -3.5rem;
}

@media (max-width: 767.98px) {
    [direction="rtl"] .nav-control {
        left: -3rem;
    }
}

@media (max-width: 575.98px) {
    [direction="rtl"] .nav-control {
        left: -2.5rem;
    }
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
    right: 0;
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line:nth-child(1) {
    transform: translateY(0.4rem) rotate(-45deg);
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-0.4rem) rotate(45deg);
}

[direction="rtl"] .header {
    padding: 0 0.9375rem;
    padding-right: 17.1875rem;
}

@media (max-width: 767.98px) {
    [direction="rtl"] .header {
        padding-right: 3.75rem;
        padding-left: 0;
    }
}

[direction="rtl"] .header .header-content {
    padding-left: 0;
    padding-right: 4.375rem;
}

@media only screen and (max-width: 575px) {
    [direction="rtl"] .header .header-content {
        padding-right: 2.375rem;
    }
}

[direction="rtl"] .header .nav-control {
    right: 0.4375rem;
    left: auto;
}

[direction="rtl"][data-layout="horizontal"] .header {
    padding: 0 0.9375rem;
    padding-right: 9.375rem;
}

[direction="rtl"][data-sidebar-style="mini"] .header {
    padding: 0 0.9375rem;
    padding-right: 3.75rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
    padding: 0 0.9375rem;
    padding-right: 9.375rem;
}

[direction="rtl"] .quixnav {
    text-align: right;
}

@media only screen and (min-width: 1800px) {
    [direction="rtl"] .quixnav {
        right: 3.125rem;
    }
}

[direction="rtl"] .quixnav .metismenu li.active>.has-arrow:after {
    transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .quixnav .metismenu .has-arrow:after {
    left: 2.5rem;
    right: auto;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li>ul li:hover ul {
    right: 11.8125rem;
    left: 0;
}

[direction="rtl"] .content-body {
    margin-right: 17.1875rem;
    margin-left: auto;
}

[direction="rtl"] .content-body .page-titles {
    text-align: right;
}

[direction="rtl"][data-layout="horizontal"] .content-body {
    margin-right: 0;
}

[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 3.75rem;
}

[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 9.375rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .content-body {
    margin-right: 0;
}

[direction="rtl"] .sidebar-right {
    left: -15.625rem;
    right: auto;
}

[direction="rtl"] .sidebar-right.show {
    left: 0;
    right: auto;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
    left: 100%;
    right: auto;
}

html[dir="rtl"] [direction="rtl"] .footer {
    padding-right: 17.1875rem;
    padding-left: 0;
}

@media (max-width: 767.98px) {
    html[dir="rtl"] [direction="rtl"] .footer {
        padding-right: 0;
    }
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
    padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
    padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
    padding-right: 0;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
    padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
    padding-right: 9.375rem;
}

.nav-label {
    margin: 0px 25px 0;
    padding: 1.5625rem 0 10px;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05rem;
}

.nav-badge {
    position: absolute;
    right: 2.8125rem;
    top: 0.625rem;
}

.content-body {
    margin-left: 17.1875rem;
    z-index: 0;
    transition: all .2s ease;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
    .content-body {
        margin-left: 14.375rem;
    }
}

.quixnav {
    width: 17.1875rem;
    padding-bottom: 112px;
    height: 100%;
    position: absolute;
    top: 5rem;
    padding-top: 0;
    z-index: 2;
    background-color: #343957;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    transition: all .2s ease;
}

.quixnav .quixnav-scroll {
    position: relative;
    height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
    .quixnav {
        width: 14.375rem;
    }
}

.quixnav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.quixnav .metismenu {
    display: flex;
    flex-direction: column;
}

.quixnav .metismenu.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
}

.quixnav .metismenu>li {
    display: flex;
    flex-direction: column;
}

.quixnav .metismenu>li a>i {
    font-size: 1.125rem;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.4375rem;
    font-weight: 700;
    position: relative;
    top: -2px;
}

.quixnav .metismenu>li>a {
    color: #bdbdc7;
}

.quixnav .metismenu>li:hover>a,
.quixnav .metismenu>li:focus>a,
.quixnav .metismenu>li.mm-active>a {
    background-color: #6b51df;
    color: #fff;
}

.quixnav .metismenu>li.mm-active ul {
    background-color: rgba(88, 115, 254, 0.04);
}

.quixnav .metismenu>li.mm-active ul ul {
    background-color: rgba(88, 115, 254, 0.04);
}

.quixnav .metismenu li {
    position: relative;
}

.quixnav .metismenu ul {
    background-color: #1c0f54;
    transition: all .2s ease-in-out;
}

.quixnav .metismenu ul a {
    padding-left: 54px;
    font-weight: 300;
}

.quixnav .metismenu ul a:hover,
.quixnav .metismenu ul a:focus,
.quixnav .metismenu ul a.mm-active {
    text-decoration: none;
    color: #fff;
}

[direction="rtl"] .quixnav .metismenu ul a {
    padding-right: 54px;
}

.quixnav .metismenu ul ul a {
    padding-left: 74px;
}

.quixnav .metismenu ul ul ul a {
    padding-left: 94px;
}

.quixnav .metismenu a {
    position: relative;
    display: block;
    padding: 0.8125rem 1.25rem;
    outline-width: 0;
    color: #bdbdc7;
    text-decoration: none;
}

.quixnav .metismenu .has-arrow:after {
    width: .35rem;
    height: .35rem;
    right: 1.5625rem;
    top: 48%;
    border-color: inherit;
    -webkit-transform: rotate(-225deg) translateY(-50%);
    transform: rotate(-225deg) translateY(-50%);
}

.quixnav .metismenu .has-arrow[aria-expanded=true]:after,
.quixnav .metismenu .mm-active>.has-arrow:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
}

@media (max-width: 767px) {
    .nav-header {
        width: 3.75rem;
    }
    .nav-header .brand-title {
        display: none;
    }
    .footer {
        padding-left: 0;
    }
    .quixnav {
        left: 0;
    }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu>ul.collapse:not(.in) {
    height: 252px !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu:hover>a {
    width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu:hover>ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 13.75rem;
    width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu:hover>ul ul a {
    width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-xl:hover>a {
    width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-xl:hover>ul {
    max-height: 21.875rem;
    width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-xl:hover>ul ul a {
    width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-lg:hover>a {
    width: calc(55vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-lg:hover>ul {
    max-height: 18.125rem;
    width: 55vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-lg:hover>ul ul a {
    width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-md:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-md:hover>a {
    width: calc(45vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-md:hover>ul {
    max-height: 18.75rem;
    width: 45vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-md:hover>ul ul a {
    width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-sm:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-sm:hover>a {
    width: calc(30vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-sm:hover>ul {
    max-height: 18.125rem;
    width: 30vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li.mega-menu-sm:hover>ul ul a {
    width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu:hover>a {
    width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu:hover>ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 25rem;
    width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu:hover>ul ul a {
    width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-xl:hover>a {
    width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-xl:hover>ul {
    max-height: 25.625rem;
    width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-lg:hover>a {
    width: calc(50vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-lg:hover>ul {
    max-height: 16.25rem;
    width: 50vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-md:hover>a {
    width: calc(40vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-md:hover>ul {
    max-height: 18.75rem;
    width: 40vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-sm:hover>a {
    width: calc(22vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-sm:hover>ul {
    max-height: 18.125rem;
    width: 22vw;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:not(:last-child) {
    position: static;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul {
    left: 0;
    right: 0;
    padding: 1.25rem 2.8125rem;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-right: 1px solid #eaeaea;
    padding: 0.1rem 1.25rem;
}

[data-theme-version="dark"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #424D63;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #040407;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #321b95;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #22134e;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #711717;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #121843;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #0b3564;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #013758;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #002629;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li {
    border-color: #263f15;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li a {
    transition: all .4s ease-in-out;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu ul li a:hover {
    border-radius: 0.25rem;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 13.75rem;
    width: 70vw;
    z-index: 99;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    background-color: #fff;
    right: 2.8125rem;
    top: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #31343b;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #161828;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #4123c0;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #331d77;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #9c1f1f;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #1d276b;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #104d92;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #01578b;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #00545c;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul::after {
    background-color: #3e6522;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu:hover>ul ul a {
    width: 101%;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu-xl:hover>ul {
    max-height: 25rem;
    width: 70vw;
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu-lg:hover>ul {
    max-height: 25rem;
    width: 60vw;
    height: 25rem !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    [data-layout="horizontal"] .quixnav .metismenu>li.mega-menu-lg:hover>ul {
        width: 80vw;
    }
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu-md:hover>ul {
    max-height: 20rem;
    width: 54vw;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    [data-layout="horizontal"] .quixnav .metismenu>li.mega-menu-md:hover>ul {
        width: 60vw;
    }
}

[data-layout="horizontal"] .quixnav .metismenu>li.mega-menu-sm:hover>ul {
    max-height: 20rem;
    width: 25vw;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    [data-layout="horizontal"] .quixnav .metismenu>li.mega-menu-sm:hover>ul {
        width: 35vw;
    }
}

[data-layout="horizontal"][data-container="boxed"] .quixnav .metismenu>li.mega-menu:hover>ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

[data-layout="horizontal"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-xl:hover>ul {
    max-height: 21.875rem;
    width: 100%;
}

[data-layout="horizontal"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-lg:hover>ul {
    max-height: 21.875rem;
    width: 55vw;
}

[data-layout="horizontal"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-md:hover>ul {
    max-height: 18.75rem;
    width: 45vw;
}

[data-layout="horizontal"][data-container="boxed"] .quixnav .metismenu>li.mega-menu-sm:hover>ul {
    max-height: 18.125rem;
    width: 50vw;
}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
    width: 3.75rem;
    z-index: 999;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
    background-color: #593bdb !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 3.75rem;
    width: 100%;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
    padding: 0 0.9375rem;
    padding-right: 3.75rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav {
    width: 3.75rem;
    overflow: visible;
    position: absolute;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .nav-text {
    display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .quixnav-scroll {
    overflow: visible !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li {
    position: relative;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li a {
    padding: 0.8125rem 0.9375rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li>ul {
    position: absolute;
    left: 3.75rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 1px;
    height: auto !important;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li>ul {
    left: auto;
    right: 3.75rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li>ul li:hover ul {
    left: 11.8125rem;
    top: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li:hover>ul {
    display: block;
    height: auto;
    overflow: visible;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li {
    transition: all 0.4s ease-in-out;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li>a.has-arrow:after {
    display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover:nth-last-child(-n+1)>ul {
    bottom: 2.9375rem;
    top: auto;
    box-shadow: 6px -6px 10px rgba(0, 0, 0, 0.15);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover:nth-last-child(-n+1)>ul {
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.15);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover>a {
    width: 15.625rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover>a .nav-text {
    display: inline-block;
    padding-left: 1.6875rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover>a .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover>ul {
    height: auto !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover>ul a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu>li:hover>ul ul a {
    padding: 0.625rem 0.9375rem 0.625rem 3rem;
    margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu .nav-label,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu .nav-badge {
    display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 3.75rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
    margin-right: 3.75rem;
    margin-left: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
    padding-left: 3.75rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
    padding-left: 0;
    padding-right: 3.75rem;
}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

@media only screen and (min-width: 768px) {
    [data-sidebar-style="mini"] .nav-header {
        width: 3.75rem;
    }
    [data-sidebar-style="mini"] .nav-header .nav-control {
        z-index: -1;
    }
    [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
        left: 3.75rem !important;
    }
    [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
        background-color: #454545 !important;
    }
    [data-sidebar-style="mini"] .nav-header .brand-title {
        display: none;
    }
    [data-sidebar-style="mini"] .nav-header .hamburger {
        display: none;
    }
    [data-sidebar-style="mini"] .quixnav {
        width: 3.75rem;
        overflow: visible;
        position: absolute;
    }
    [data-sidebar-style="mini"] .quixnav .nav-text {
        display: none;
    }
    [data-sidebar-style="mini"] .quixnav .slimScrollDiv,
    [data-sidebar-style="mini"] .quixnav .quixnav-scroll {
        overflow: visible !important;
    }
    [data-sidebar-style="mini"] .quixnav .nav-user {
        padding: 11px;
    }
    [data-sidebar-style="mini"] .quixnav .nav-user .media-body {
        display: none;
    }
    [data-sidebar-style="mini"] .quixnav .metismenu li a {
        padding: 0.8125rem 0.9375rem;
    }
    [data-sidebar-style="mini"] .quixnav .metismenu li>ul {
        position: absolute;
        left: 3.75rem;
        top: 2.9375rem;
        width: 11.875rem;
        z-index: 1001;
        display: none;
        padding-left: 1px;
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.15);
        height: auto !important;
    }
    [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .quixnav .metismenu li>ul {
        left: auto;
        right: 3.75rem;
        box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.15);
    }
    [data-sidebar-style="mini"] .quixnav .metismenu>li>a.has-arrow:after {
        display: none;
    }
    [data-sidebar-style="mini"] .quixnav .metismenu .nav-label,
    [data-sidebar-style="mini"] .quixnav .metismenu .nav-badge {
        display: none;
    }
    [data-sidebar-style="mini"] .content-body {
        margin-left: 3.75rem;
    }
    [data-sidebar-style="mini"] .footer {
        padding-left: 3.75rem;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu li:hover>ul {
        display: block;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:nth-last-child(-n+1)>ul {
        bottom: 2.93rem;
        top: auto;
        box-shadow: 6px -6px 10px rgba(0, 0, 0, 0.15);
    }
    [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:nth-last-child(-n+1)>ul {
        box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.15);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:nth-last-child(-n+1)>ul {
        bottom: 2.93rem;
        top: auto;
    }
}

@media only screen and (min-width: 768px) {
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li>ul {
        overflow: visible;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li>ul li:hover ul {
        left: 11.8125rem;
        top: 0;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:hover>a {
        width: 15.625rem;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:hover>a .nav-text {
        display: inline-block;
        padding-left: 1.6875rem;
    }
    [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:hover>a .nav-text {
        padding-left: auto;
        padding-right: 1.6875rem;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:hover>ul {
        height: auto !important;
        overflow: visible;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:hover>ul a {
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
        margin-left: -1.6px;
    }
    [data-sidebar-style="mini"][data-layout="vertical"] .quixnav .metismenu>li:hover>ul ul a {
        padding: 0.625rem 0.9375rem 0.625rem 3rem;
        margin-left: -1.6px;
    }
    [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
        width: 1199px;
    }
}

@media only screen and (max-width: 1199px) {
    [data-sidebar-style="mini"] .quixnav li.mm-active ul {
        height: auto !important;
    }
    [data-sidebar-style="mini"] .quixnav li a.has-arrow::after {
        transform: rotate(-45deg) translateY(-50%);
    }
}

@media (min-width: 1199px) {
    [data-layout="horizontal"] .nav-header {
        width: 9.0625rem;
    }
    [data-layout="horizontal"] .nav-header .nav-control {
        display: none;
    }
    [data-layout="horizontal"] .nav-header .brand-logo {
        padding-left: 0;
        justify-content: center;
    }
    [data-layout="horizontal"] .header {
        width: 100%;
        padding-left: 9.0625rem;
    }
    [data-layout="horizontal"] .quixnav {
        width: 100%;
        position: relative;
        height: auto;
        padding-bottom: 0;
        top: 0;
        z-index: 8;
    }
    [data-layout="horizontal"] .quixnav .slimScrollDiv {
        overflow: visible !important;
    }
    [data-layout="horizontal"] .quixnav .slimScrollDiv .quixnav-scroll {
        overflow: visible !important;
    }
    [data-layout="horizontal"] .quixnav .slimScrollBar {
        display: none !important;
    }
    [data-layout="horizontal"] .quixnav .nav-user,
    [data-layout="horizontal"] .quixnav .nav-label {
        display: none;
    }
    [data-layout="horizontal"] .quixnav .metismenu {
        flex-direction: row;
    }
    [data-layout="horizontal"] .quixnav .metismenu .collapse.in {
        display: none;
    }
    [data-layout="horizontal"] .quixnav .metismenu li {
        flex-direction: column;
        position: relative;
    }
    [data-layout="horizontal"] .quixnav .metismenu li:hover>ul {
        display: block;
    }
    [data-layout="horizontal"] .quixnav .metismenu li>ul {
        position: absolute;
        height: auto !important;
        top: 2.9375rem;
        width: 100%;
        min-width: 13.75rem;
        z-index: 999;
        left: auto;
        right: auto;
        padding: 1rem 0;
        display: none;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
    }
    [data-theme-version="dark"][data-layout="horizontal"] .quixnav .metismenu li>ul {
        box-shadow: 1px 1px 1px black;
    }
    [data-layout="horizontal"] .quixnav .metismenu li>ul li {
        padding: 0.1rem 1.25rem;
    }
    [data-layout="horizontal"] .quixnav .metismenu li>ul li a {
        transition: all .4s ease-in-out;
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
        margin-left: -.1rem;
    }
    [data-layout="horizontal"] .quixnav .metismenu li>ul li a:hover {
        border-radius: .4rem;
    }
    [data-layout="horizontal"] .quixnav .metismenu li>ul ul {
        left: 100%;
        top: 0;
    }
    [data-layout="horizontal"] .quixnav .metismenu li>ul ul a {
        padding: 0.625rem 0.9375rem 0.625rem 1.875rem;
        margin-left: -.1rem;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li {
        flex: 0 0 auto;
        position: relative;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li:not(:last-child) {
        border-right: 1px solid #eaeaea;
    }
    [data-theme-version="dark"][data-layout="horizontal"] .quixnav .metismenu>li:not(:last-child) {
        border-color: #424D63;
    }
    [direction="rtl"][data-layout="horizontal"] .quixnav .metismenu>li:not(:first-child) {
        border-right: #3c3e68;
    }
    [direction="rtl"][data-layout="horizontal"] .quixnav .metismenu>li:first-child {
        border-right: 0;
    }
    [data-theme-version="dark"][direction="rtl"][data-layout="horizontal"] .quixnav .metismenu>li {
        border-color: #424D63;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li>a {
        padding: 0.8125rem 1.75rem;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li>a i {
        padding: 0 0.4375rem 0 0;
    }
    [direction="rtl"][data-layout="horizontal"] .quixnav .metismenu>li>a i {
        padding: 0 0 0 0.4375rem;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li>a .nav-badge {
        display: none;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li>a:after {
        right: 8%;
        transform: rotate(-135deg) translateY(-50%);
    }
    [data-layout="horizontal"] .quixnav .metismenu>li:hover>ul {
        display: flex !important;
        flex-direction: column;
        flex-wrap: wrap;
        height: auto !important;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li>ul>li:hover ul.collapse {
        display: block !important;
        position: absolute;
        left: auto !important;
        right: -100% !important;
        top: 0 !important;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li:nth-last-child(-n+5)>ul {
        left: auto;
        right: 0;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li:nth-last-child(-n+5)>ul>li:hover ul.collapse {
        right: auto !important;
        left: -100% !important;
    }
    [data-layout="horizontal"] .quixnav .metismenu>li:last-child>ul ul {
        left: -100%;
    }
    [direction="rtl"][data-layout="horizontal"] .quixnav .metismenu>li:nth-last-child(-n+3)>ul {
        left: 0;
        right: auto;
    }
    [direction="rtl"][data-layout="horizontal"] .quixnav .metismenu>li:nth-last-child(-n+3)>ul>li:hover ul.collapse {
        right: -100% !important;
        left: auto !important;
    }
    [data-layout="horizontal"] .content-body {
        margin-left: 0;
    }
    [data-layout="horizontal"] .content-body .page-titles {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.875rem;
    }
    [data-layout="horizontal"] .footer {
        margin-left: 0;
        margin: 0 auto;
        padding-left: 0;
    }
    [data-layout="horizontal"] .sidebar-right {
        z-index: 1;
    }
    [data-header-position="fixed"][data-layout="horizontal"] .quixnav {
        top: 5rem;
    }
    [data-header-position="fixed"][data-sidebar-position="fixed"] .quixnav {
        position: fixed;
    }
    [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] .content-body {
        padding-top: 8.3rem;
    }
    [data-layout="horizontal"][data-container="boxed"] .footer {
        margin-left: 0;
        max-width: 1199px;
        margin: 0 auto;
    }
    [data-layout="horizontal"][data-container="wide"] .page-titles {
        margin-left: -30px;
        margin-right: -30px;
    }
    [data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
        margin-top: 0;
    }
    [data-layout="horizontal"][data-sidebar-style="compact"] .quixnav .metismenu>li>ul {
        top: 4.5625rem;
    }
    [data-layout="horizontal"][data-sidebar-style="compact"] .quixnav .metismenu>li>a {
        padding: 0.8125rem 2.1rem;
    }
    [data-layout="horizontal"][data-sidebar-style="compact"] .quixnav .metismenu>li>a::after {
        display: none;
    }
    [data-layout="horizontal"][data-sidebar-style="compact"] .quixnav .metismenu>li li {
        text-align: left;
    }
    [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
        width: 3.75rem;
    }
    [data-sidebar-style="mini"][data-layout="horizontal"] .header {
        width: 100%;
        padding-left: 3.75rem;
    }
    [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a {
        width: auto;
    }
    [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a .nav-text {
        display: none;
    }
    [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
        padding-top: 8.75rem;
    }
    [data-sidebar-position="fixed"][data-layout="horizontal"] .quixnav.fixed {
        position: fixed;
        left: 0;
        top: 0;
    }
}

@media (min-width: 767px) {
    [data-sidebar-style="compact"] .quixnav .nav-user {
        display: none;
    }
    [data-sidebar-style="compact"] .quixnav .metismenu li {
        text-align: center;
    }
    [data-sidebar-style="compact"] .quixnav .metismenu li a {
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    }
    [data-sidebar-style="compact"] .nav-text {
        display: block;
        margin-top: 0.3125rem;
    }
    [data-sidebar-style="compact"] .nav-label.first {
        display: none;
    }
    [data-sidebar-style="compact"] .nav-badge {
        display: none;
    }
    [data-sidebar-style="compact"] .footer {
        padding-left: 9.375rem;
    }
    [data-sidebar-style="compact"] .content-body {
        margin-left: 9.375rem;
    }
}

[data-layout="vertical"][data-sidebar-style="compact"] .quixnav {
    width: 9.375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .quixnav .metismenu ul ul a {
    padding: 0.625rem 0.9375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .quixnav .metismenu>li>a.has-arrow::after {
    top: 35%;
}

@media (min-width: 767px) {
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
        width: 4.38rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
        padding-left: 1.6rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
        display: block;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
        display: none;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
        display: none;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
        padding-left: 4.38rem;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
        padding-right: 4.38rem;
        padding-left: 0.9375rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
        padding-left: 1.375rem;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
        padding-right: 1.375rem;
        padding-left: 0;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav {
        overflow: visible;
        position: absolute;
        left: -12.8125rem;
    }
}

@media only screen and (min-width: 767px) and (min-width: 1200px) and (max-width: 1350px) {
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav {
        left: -10rem;
    }
}

@media (min-width: 767px) {
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav {
        left: auto;
        right: -12.8125rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .nav-label {
        display: none;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .metismenu>li>a {
        display: flex;
        justify-content: space-between;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .metismenu>li>a>i {
        order: 1;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .metismenu ul a {
        position: relative;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .metismenu ul a::before {
        position: absolute;
        content: "\f764";
        right: 1.75rem;
        font-family: "Material Design Icons";
        font-size: 13px;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .metismenu ul a::before {
        right: auto;
        left: 1.75rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .metismenu .has-arrow::after {
        right: 5rem;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .quixnav .metismenu .has-arrow::after {
        right: auto;
        left: 5rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header {
        width: 17.1875rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo {
        padding-left: 1.6rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo .logo-abbr {
        display: none;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo .brand-title {
        display: block;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
        padding-left: 4.38rem;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
        padding-right: 4.38rem;
        padding-left: 0.9375rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .quixnav {
        left: 0;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .quixnav {
        left: auto;
        right: 0;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
        margin-left: 4.375rem;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
        margin-left: 0;
        margin-right: 4.375rem;
    }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
        padding-left: 4.375rem;
    }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
        margin-left: 0;
        margin-right: 4.375rem;
    }
}

@media (min-width: 767px) {
    [data-sidebar-style="modern"] .nav-header {
        width: 9.375rem;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu>li {
        text-align: center;
        border-bottom: 1px solid #eaeaea;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu>li>a {
        padding: 20px 15px 20px 15px !important;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu>li>a::after {
        display: none;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu>li>a:hover>a,
    [data-sidebar-style="modern"] .quixnav .metismenu>li>a:focus>a,
    [data-sidebar-style="modern"] .quixnav .metismenu>li>a:active>a,
    [data-sidebar-style="modern"] .quixnav .metismenu>li>a.mm-active>a {
        background-color: white;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu>li li {
        text-align: left;
    }
    [direction="rtl"][data-sidebar-style="modern"] .quixnav .metismenu>li li {
        text-align: right;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu li a {
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu li>ul {
        height: auto !important;
    }
    [data-sidebar-style="modern"] .quixnav .metismenu .nav-label {
        display: none;
    }
    [data-sidebar-style="modern"] .quixnav .nav-label {
        display: none;
    }
    [data-sidebar-style="modern"] .quixnav .nav-text {
        display: block;
        margin-top: 0.3125rem;
    }
    [data-sidebar-style="modern"] .footer {
        padding-left: 9.375rem;
    }
    [data-sidebar-style="modern"] .content-body {
        margin-left: 9.375rem;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav {
        width: 9.375rem;
        left: 0;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .slimScrollDiv,
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .quixnav-scroll {
        overflow: visible !important;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu>li>a i {
        font-size: 20px;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu>li>ul {
        display: none;
        padding: 1.875rem 0.9375rem;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu li {
        position: static;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu li a {
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu li ul {
        position: absolute;
        left: 105%;
        top: 0;
        bottom: 0;
        background-color: #fff;
        border: 1px solid #eaeaea;
        width: 200px;
    }
    [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu li ul {
        left: auto;
        right: 105%;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu li:hover>ul {
        display: block;
        left: 100%;
        padding: 1.875rem 0;
    }
    [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .quixnav .metismenu li:hover>ul {
        left: auto;
        right: 100%;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .nav-label {
        display: none;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .quixnav .nav-text {
        display: block;
        margin-top: 0;
        margin-bottom: 0.3125rem;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .quixnav {
        left: -9.375rem;
    }
    [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .quixnav {
        left: auto;
        right: -9.375rem;
    }
    [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
        margin-left: 0;
    }
    [data-sidebar-style="modern"][data-layout="horizontal"] .quixnav .metismenu>li>a {
        padding: 0.8125rem 2.25rem;
    }
    [data-sidebar-style="modern"][data-layout="horizontal"] .quixnav .metismenu>li>ul {
        top: 4.5625rem;
    }
    [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .quixnav .metismenu>li>a {
        padding: 0.8125rem 1.25rem;
    }
}

[data-sidebar-style="overlay"] .quixnav {
    left: -100%;
}

[direction="rtl"][data-sidebar-style="overlay"] .quixnav {
    left: auto;
    right: -100%;
}

[data-sidebar-style="overlay"] .content-body {
    margin-left: 0;
}

[data-sidebar-style="overlay"] .nav-header {
    position: absolute;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
    left: 0;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
    background-color: #593bdb;
}

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
    position: absolute;
    left: auto;
}

[data-sidebar-style="overlay"] .menu-toggle .quixnav {
    left: 0;
}

[direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .quixnav {
    left: auto;
    right: 0;
}

[data-sidebar-style="overlay"] .footer {
    padding-left: 0;
}

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
    position: fixed;
}

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
    position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
    position: fixed;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .quixnav {
    position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .quixnav .quixnav-scroll {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .quixnav {
    position: absolute;
}

[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .quixnav {
    position: absolute;
}

.sidebar-right {
    right: -15.625rem;
    position: fixed;
    top: 0;
    width: 15.625rem;
    background-color: #fff;
    height: calc(100% - 7.0625rem);
    margin-top: 5rem;
    transition: all .5s ease-in-out;
    padding-bottom: 1.875rem;
    box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction="rtl"] .sidebar-right .slimScrollDiv {
    overflow: visible !important;
}

.sidebar-right .sidebar-right-trigger {
    position: absolute;
    z-index: 9;
    top: 4.75rem;
    right: 100%;
    background-color: #593bdb;
    color: #fff;
    display: inline-block;
    height: 2.125rem;
    width: 2.125rem;
    text-align: center;
    font-size: 1.3125rem;
    line-height: 2.2rem;
}

.sidebar-right .sidebar-right-trigger:hover {
    color: #fff;
}

.sidebar-right.show {
    right: 0;
    z-index: 999;
}

.sidebar-right .nav-tabs {
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs {
    background-color: #2A2C32;
}

.sidebar-right .nav-tabs .nav-item {
    margin-bottom: 0;
    flex: 1;
}

.sidebar-right .nav-tabs .nav-item .nav-link {
    border: 0;
    font-size: 1.125rem;
    position: relative;
    text-align: center;
    background-color: #fff;
}

.sidebar-right .nav-tabs .nav-item .nav-link::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    content: "";
    background: transparent;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-item .nav-link {
    background-color: #2A2C32;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-item .nav-link.active {
    border-right: none;
    border-left: none;
    border-top: none;
}

.sidebar-right .tab-content {
    padding: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
    margin-bottom: 0.3125rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
    display: none;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]+label {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 3px 8px;
    transition: all .1s ease;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label {
    position: relative;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
    height: 12px;
    width: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
    position: absolute;
    background-color: #fff;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label {
    background-color: #fff;
}

.sidebar-right #nav_header_color_2+label,
.sidebar-right #header_color_2+label,
.sidebar-right #sidebar_color_2+label {
    background-color: #292B48;
}

.sidebar-right #nav_header_color_3+label,
.sidebar-right #header_color_3+label,
.sidebar-right #sidebar_color_3+label {
    background-color: #593bdb;
}

.sidebar-right #nav_header_color_4+label,
.sidebar-right #header_color_4+label,
.sidebar-right #sidebar_color_4+label {
    background-color: #4527a0;
}

.sidebar-right #nav_header_color_5+label,
.sidebar-right #header_color_5+label,
.sidebar-right #sidebar_color_5+label {
    background-color: #c62828;
}

.sidebar-right #nav_header_color_6+label,
.sidebar-right #header_color_6+label,
.sidebar-right #sidebar_color_6+label {
    background-color: #283593;
}

.sidebar-right #nav_header_color_7+label,
.sidebar-right #header_color_7+label,
.sidebar-right #sidebar_color_7+label {
    background-color: #1565c0;
}

.sidebar-right #nav_header_color_8+label,
.sidebar-right #header_color_8+label,
.sidebar-right #sidebar_color_8+label {
    background-color: #0277bd;
}

.sidebar-right #nav_header_color_9+label,
.sidebar-right #header_color_9+label,
.sidebar-right #sidebar_color_9+label {
    background-color: #00838f;
}

.sidebar-right #nav_header_color_10+label,
.sidebar-right #header_color_10+label,
.sidebar-right #sidebar_color_10+label {
    background-color: #558b2f;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label {
    border: 1px solid #666666;
}

@keyframes bounce {
    0% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
    50% {
        transform: translateX(8%);
        -webkit-transform: translateX(8%);
    }
    100% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
    50% {
        transform: translateX(8%);
        -webkit-transform: translateX(8%);
    }
    100% {
        transform: translateY(-8%);
        -webkit-transform: translateY(-8%);
    }
}

.nav-user {
    background: #593bdb;
    margin-bottom: 10px;
    padding: 20px 25px 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-user {
        padding: 20px 15px 15px;
    }
}

.nav-user img {
    width: 35px;
    height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-user img {
        width: 35px;
        height: 35px;
        margin-bottom: 10px;
    }
}

.nav-user h5 {
    margin-left: 10px;
    margin-bottom: 3px;
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-user h5 {
        display: none;
    }
}

[data-sibebarbg="color_2"] .nav-user h5 {
    color: #fff;
}

.nav-user p {
    margin-left: 10px;
    margin-bottom: 8px;
    color: #afcff7;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-user p {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-user i {
        margin-top: 15px;
        display: block;
    }
}

.menu-toggle .nav-user {
    padding: 20px 15px 15px;
}

.menu-toggle .nav-user img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
}

.menu-toggle .nav-user h5,
.menu-toggle .nav-user p {
    display: none;
}

.menu-toggle .nav-user i {
    margin-top: 15px;
    display: block;
}

.menu-toggle .nav-user .dropdown-menu {
    left: 45px !important;
    top: 22px !important;
}

[data-nav-headerbg="color_2"] .nav-header {
    background-color: #292B48;
}

[data-nav-headerbg="color_2"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_2"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_3"] .nav-header {
    background-color: #593bdb;
}

[data-nav-headerbg="color_3"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_3"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_4"] .nav-header {
    background-color: #4527a0;
}

[data-nav-headerbg="color_4"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_4"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_5"] .nav-header {
    background-color: #c62828;
}

[data-nav-headerbg="color_5"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_5"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_6"] .nav-header {
    background-color: #283593;
}

[data-nav-headerbg="color_6"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_6"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_7"] .nav-header {
    background-color: #1565c0;
}

[data-nav-headerbg="color_7"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_7"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_8"] .nav-header {
    background-color: #0277bd;
}

[data-nav-headerbg="color_8"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_8"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_9"] .nav-header {
    background-color: #00838f;
}

[data-nav-headerbg="color_9"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_9"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-nav-headerbg="color_10"] .nav-header {
    background-color: #558b2f;
}

[data-nav-headerbg="color_10"] .nav-header .brand-logo {
    color: #fff;
}

[data-nav-headerbg="color_10"] .nav-header .brand-logo:hover {
    color: #fff;
}

[data-headerbg="color_2"] .header {
    background-color: #292B48;
    color: #fff;
}

[data-headerbg="color_2"] .header-left input {
    border-color: #3c3e68;
    background-color: #3c3e68;
    color: #fff;
}

[data-headerbg="color_2"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_2"] .header-left input:focus {
    border-color: #161828;
}

[data-headerbg="color_2"] .header-left .btn {
    background-color: #161828;
    border-color: #161828;
}

[data-headerbg="color_2"] .header-left .btn:hover,
[data-headerbg="color_2"] .header-left .btn:focus,
[data-headerbg="color_2"] .header-left .btn:active {
    background-color: #040407;
    border-color: #040407;
}

[data-headerbg="color_2"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_2"] .nav-control {
    background-color: #161828;
    color: #fff;
}

[data-headerbg="color_3"] .header {
    background-color: #593bdb;
    color: #fff;
}

[data-headerbg="color_3"] .header-left input {
    border-color: #7d66e3;
    background-color: #7d66e3;
    color: #fff;
}

[data-headerbg="color_3"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_3"] .header-left input:focus {
    border-color: #4123c0;
}

[data-headerbg="color_3"] .header-left .btn {
    background-color: #4123c0;
    border-color: #4123c0;
}

[data-headerbg="color_3"] .header-left .btn:hover,
[data-headerbg="color_3"] .header-left .btn:focus,
[data-headerbg="color_3"] .header-left .btn:active {
    background-color: #321b95;
    border-color: #321b95;
}

[data-headerbg="color_3"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_3"] .nav-control {
    background-color: #4123c0;
    color: #fff;
}

[data-headerbg="color_4"] .header {
    background-color: #4527a0;
    color: #fff;
}

[data-headerbg="color_4"] .header-left input {
    border-color: #5731c9;
    background-color: #5731c9;
    color: #fff;
}

[data-headerbg="color_4"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_4"] .header-left input:focus {
    border-color: #331d77;
}

[data-headerbg="color_4"] .header-left .btn {
    background-color: #331d77;
    border-color: #331d77;
}

[data-headerbg="color_4"] .header-left .btn:hover,
[data-headerbg="color_4"] .header-left .btn:focus,
[data-headerbg="color_4"] .header-left .btn:active {
    background-color: #22134e;
    border-color: #22134e;
}

[data-headerbg="color_4"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_4"] .nav-control {
    background-color: #331d77;
    color: #fff;
}

[data-headerbg="color_5"] .header {
    background-color: #c62828;
    color: #fff;
}

[data-headerbg="color_5"] .header-left input {
    border-color: #da4747;
    background-color: #da4747;
    color: #fff;
}

[data-headerbg="color_5"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_5"] .header-left input:focus {
    border-color: #9c1f1f;
}

[data-headerbg="color_5"] .header-left .btn {
    background-color: #9c1f1f;
    border-color: #9c1f1f;
}

[data-headerbg="color_5"] .header-left .btn:hover,
[data-headerbg="color_5"] .header-left .btn:focus,
[data-headerbg="color_5"] .header-left .btn:active {
    background-color: #711717;
    border-color: #711717;
}

[data-headerbg="color_5"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_5"] .nav-control {
    background-color: #9c1f1f;
    color: #fff;
}

[data-headerbg="color_6"] .header {
    background-color: #283593;
    color: #fff;
}

[data-headerbg="color_6"] .header-left input {
    border-color: #3343bb;
    background-color: #3343bb;
    color: #fff;
}

[data-headerbg="color_6"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_6"] .header-left input:focus {
    border-color: #1d276b;
}

[data-headerbg="color_6"] .header-left .btn {
    background-color: #1d276b;
    border-color: #1d276b;
}

[data-headerbg="color_6"] .header-left .btn:hover,
[data-headerbg="color_6"] .header-left .btn:focus,
[data-headerbg="color_6"] .header-left .btn:active {
    background-color: #121843;
    border-color: #121843;
}

[data-headerbg="color_6"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_6"] .nav-control {
    background-color: #1d276b;
    color: #fff;
}

[data-headerbg="color_7"] .header {
    background-color: #1565c0;
    color: #fff;
}

[data-headerbg="color_7"] .header-left input {
    border-color: #217ee7;
    background-color: #217ee7;
    color: #fff;
}

[data-headerbg="color_7"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_7"] .header-left input:focus {
    border-color: #104d92;
}

[data-headerbg="color_7"] .header-left .btn {
    background-color: #104d92;
    border-color: #104d92;
}

[data-headerbg="color_7"] .header-left .btn:hover,
[data-headerbg="color_7"] .header-left .btn:focus,
[data-headerbg="color_7"] .header-left .btn:active {
    background-color: #0b3564;
    border-color: #0b3564;
}

[data-headerbg="color_7"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_7"] .nav-control {
    background-color: #104d92;
    color: #fff;
}

[data-headerbg="color_8"] .header {
    background-color: #0277bd;
    color: #fff;
}

[data-headerbg="color_8"] .header-left input {
    border-color: #0397ef;
    background-color: #0397ef;
    color: #fff;
}

[data-headerbg="color_8"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_8"] .header-left input:focus {
    border-color: #01578b;
}

[data-headerbg="color_8"] .header-left .btn {
    background-color: #01578b;
    border-color: #01578b;
}

[data-headerbg="color_8"] .header-left .btn:hover,
[data-headerbg="color_8"] .header-left .btn:focus,
[data-headerbg="color_8"] .header-left .btn:active {
    background-color: #013758;
    border-color: #013758;
}

[data-headerbg="color_8"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_8"] .nav-control {
    background-color: #01578b;
    color: #fff;
}

[data-headerbg="color_9"] .header {
    background-color: #00838f;
    color: #fff;
}

[data-headerbg="color_9"] .header-left input {
    border-color: #00b2c2;
    background-color: #00b2c2;
    color: #fff;
}

[data-headerbg="color_9"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_9"] .header-left input:focus {
    border-color: #00545c;
}

[data-headerbg="color_9"] .header-left .btn {
    background-color: #00545c;
    border-color: #00545c;
}

[data-headerbg="color_9"] .header-left .btn:hover,
[data-headerbg="color_9"] .header-left .btn:focus,
[data-headerbg="color_9"] .header-left .btn:active {
    background-color: #002629;
    border-color: #002629;
}

[data-headerbg="color_9"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_9"] .nav-control {
    background-color: #00545c;
    color: #fff;
}

[data-headerbg="color_10"] .header {
    background-color: #558b2f;
    color: #fff;
}

[data-headerbg="color_10"] .header-left input {
    border-color: #6cb13c;
    background-color: #6cb13c;
    color: #fff;
}

[data-headerbg="color_10"] .header-left input::placeholder {
    color: #fff;
}

[data-headerbg="color_10"] .header-left input:focus {
    border-color: #3e6522;
}

[data-headerbg="color_10"] .header-left .btn {
    background-color: #3e6522;
    border-color: #3e6522;
}

[data-headerbg="color_10"] .header-left .btn:hover,
[data-headerbg="color_10"] .header-left .btn:focus,
[data-headerbg="color_10"] .header-left .btn:active {
    background-color: #263f15;
    border-color: #263f15;
}

[data-headerbg="color_10"] .header-right .dropdown>a {
    color: #fff;
}

[data-headerbg="color_10"] .nav-control {
    background-color: #3e6522;
    color: #fff;
}

[data-sibebarbg="color_2"] .quixnav {
    background-color: #292B48;
}

[data-sibebarbg="color_2"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_2"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_2"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_2"] .quixnav .metismenu>li.mm-active>a {
    background-color: #292B48;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_2"] .quixnav .metismenu>li {
    border-color: #3c3e68;
}

[data-sibebarbg="color_2"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_2"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_2"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_2"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_2"] .quixnav .metismenu ul {
    background-color: #161828;
}

[data-sibebarbg="color_3"] .quixnav {
    background-color: #593bdb;
}

[data-sibebarbg="color_3"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_3"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_3"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_3"] .quixnav .metismenu>li.mm-active>a {
    background-color: #593bdb;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_3"] .quixnav .metismenu>li {
    border-color: #7d66e3;
}

[data-sibebarbg="color_3"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_3"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_3"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_3"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_3"] .quixnav .metismenu ul {
    background-color: #4123c0;
}

[data-sibebarbg="color_4"] .quixnav {
    background-color: #4527a0;
}

[data-sibebarbg="color_4"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_4"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_4"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_4"] .quixnav .metismenu>li.mm-active>a {
    background-color: #4527a0;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_4"] .quixnav .metismenu>li {
    border-color: #5731c9;
}

[data-sibebarbg="color_4"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_4"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_4"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_4"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_4"] .quixnav .metismenu ul {
    background-color: #331d77;
}

[data-sibebarbg="color_5"] .quixnav {
    background-color: #c62828;
}

[data-sibebarbg="color_5"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_5"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_5"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_5"] .quixnav .metismenu>li.mm-active>a {
    background-color: #c62828;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_5"] .quixnav .metismenu>li {
    border-color: #da4747;
}

[data-sibebarbg="color_5"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_5"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_5"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_5"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_5"] .quixnav .metismenu ul {
    background-color: #9c1f1f;
}

[data-sibebarbg="color_6"] .quixnav {
    background-color: #283593;
}

[data-sibebarbg="color_6"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_6"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_6"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_6"] .quixnav .metismenu>li.mm-active>a {
    background-color: #283593;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_6"] .quixnav .metismenu>li {
    border-color: #3343bb;
}

[data-sibebarbg="color_6"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_6"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_6"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_6"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_6"] .quixnav .metismenu ul {
    background-color: #1d276b;
}

[data-sibebarbg="color_7"] .quixnav {
    background-color: #1565c0;
}

[data-sibebarbg="color_7"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_7"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_7"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_7"] .quixnav .metismenu>li.mm-active>a {
    background-color: #1565c0;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_7"] .quixnav .metismenu>li {
    border-color: #217ee7;
}

[data-sibebarbg="color_7"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_7"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_7"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_7"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_7"] .quixnav .metismenu ul {
    background-color: #104d92;
}

[data-sibebarbg="color_8"] .quixnav {
    background-color: #0277bd;
}

[data-sibebarbg="color_8"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_8"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_8"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_8"] .quixnav .metismenu>li.mm-active>a {
    background-color: #0277bd;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_8"] .quixnav .metismenu>li {
    border-color: #0397ef;
}

[data-sibebarbg="color_8"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_8"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_8"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_8"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_8"] .quixnav .metismenu ul {
    background-color: #01578b;
}

[data-sibebarbg="color_9"] .quixnav {
    background-color: #00838f;
}

[data-sibebarbg="color_9"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_9"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_9"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_9"] .quixnav .metismenu>li.mm-active>a {
    background-color: #00838f;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_9"] .quixnav .metismenu>li {
    border-color: #00b2c2;
}

[data-sibebarbg="color_9"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_9"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_9"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_9"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_9"] .quixnav .metismenu ul {
    background-color: #00545c;
}

[data-sibebarbg="color_10"] .quixnav {
    background-color: #558b2f;
}

[data-sibebarbg="color_10"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-sibebarbg="color_10"] .quixnav .metismenu>li:hover>a,
[data-sibebarbg="color_10"] .quixnav .metismenu>li:focus>a,
[data-sibebarbg="color_10"] .quixnav .metismenu>li.mm-active>a {
    background-color: #558b2f;
    color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_10"] .quixnav .metismenu>li {
    border-color: #6cb13c;
}

[data-sibebarbg="color_10"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-sibebarbg="color_10"] .quixnav .metismenu a:hover,
[data-sibebarbg="color_10"] .quixnav .metismenu a:focus,
[data-sibebarbg="color_10"] .quixnav .metismenu a.mm-active {
    color: #fff;
}

[data-sibebarbg="color_10"] .quixnav .metismenu ul {
    background-color: #3e6522;
}

[data-headerbg="transparent"] .header {
    box-shadow: none;
    background: transparent;
}

@media (min-width: 992px) {
    [data-container="boxed"] #main-wrapper {
        max-width: 1199px;
        margin: 0 auto;
    }
    [direction="rtl"][data-container="boxed"] #main-wrapper {
        text-align: right;
    }
}

@media only screen and (min-width: 1350px) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
        width: 1199px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1349px) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
        width: 1199px;
    }
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] .quixnav {
    max-width: 1199px;
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 1199px;
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
    width: 1199px;
}

[data-container="boxed"] .metismenu.fixed {
    left: auto;
    max-width: 1199px;
}

[data-container="boxed"] .page-titles {
    margin-bottom: 3rem;
    padding: 15px 15px;
}

[data-container="boxed"] .content-body .container-fluid {
    padding: 0.9375rem 0.9375rem 0 0.9375rem;
}

[data-container="boxed"][data-layout="vertical"] .page-titles {
    margin-left: 0;
    margin-right: 0;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
    position: absolute;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .quixnav {
    position: absolute;
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .quixnav.fixed {
    left: auto;
    max-width: 1199px;
}

@media (min-width: 992px) {
    [data-container="wide-boxed"] #main-wrapper {
        max-width: 1480px;
        margin: 0 auto;
    }
}

@media (min-width: 992px) {
    [data-container="wide-boxed"][data-sidebar-style="full"] .header {
        width: 100%;
    }
    [data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
        width: 100%;
    }
    [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .header {
        max-width: 1480px;
    }
    [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
        max-width: 1480px;
    }
}

@media (min-width: 992px) {
    [data-container="wide-boxed"][data-sidebar-style="mini"] .header {
        width: 100%;
    }
    [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="mini"] .header {
        max-width: 1480px;
    }
    [data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-style="mini"] .header {
        max-width: 1480px;
    }
}

@media (min-width: 992px) {
    [data-container="wide-boxed"][data-sidebar-style="compact"] .header {
        width: 100%;
    }
    [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="compact"] .header {
        max-width: 1480px;
    }
}

@media (min-width: 992px) {
    [data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
        width: 100%;
    }
    [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
        max-width: 1480px;
    }
}

[data-layout="horizontal"][data-container="wide-boxed"] .header {
    width: 100%;
}

[data-sidebar-style="full"][data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] .header {
    max-width: 1480px;
}

[data-sidebar-style="mini"][data-layout="horizontal"][data-container="wide-boxed"] .header {
    width: 100%;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"] .quixnav.fixed {
    max-width: 1480px;
    left: 5%;
    transition: none;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][direction="rtl"] .quixnav.fixed {
    right: 5%;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="fixed"] .quixnav {
    max-width: 1480px;
    left: 5%;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="fixed"][direction="rtl"] .quixnav {
    right: 5%;
}

[data-typography="opensans"] {
    font-family: 'Open Sans', sans-serif;
}

[data-typography="poppins"] {
    font-family: 'Roboto', sans-serif;
}

[data-typography="roboto"] {
    font-family: 'Roboto', sans-serif;
}

[data-typography="helvetica"] {
    font-family: 'HelveticaNeue';
}

[data-theme-version="dark"] {
    background: #2A2C32;
    color: #828690;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] h6 {
    color: #fff !important;
}

[data-theme-version="dark"] a.link {
    color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
    color: #50E3C2;
}

[data-theme-version="dark"] a {
    color: #fff;
}

[data-theme-version="dark"] a:hover {
    color: #828690;
}

[data-theme-version="dark"] .border-right {
    border-right: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border-left {
    border-left: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border-top {
    border-top: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border-bottom {
    border-bottom: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border {
    border: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .dropdown-menu {
    background-color: #323A4B;
}

[data-theme-version="dark"] .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-item:focus {
    background-color: #2A2C32;
    color: #fff;
}

[data-theme-version="dark"] .form-control {
    background-color: #2A2C32;
    border-color: #424D63;
    color: #fff;
}

[data-theme-version="dark"] .card {
    background-color: #323A4B;
}

[data-theme-version="dark"] .header {
    background-color: #593bdb;
}

[data-theme-version="dark"] .header-left input {
    border-color: transparent;
    color: #fff;
}

[data-theme-version="dark"] .header-left input:focus {
    box-shadow: none;
    border-color: #593bdb;
}

[data-theme-version="dark"] .header-left input::placeholder {
    color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
    color: #fff;
}

[data-theme-version="dark"] .header-right .notification_dropdown .dropdown-item a {
    color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
    background-color: #593bdb;
}

[data-theme-version="dark"] .nav-control {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
    color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
    background-color: #593bdb;
}

[data-theme-version="dark"] .nav-control {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
    color: #fff;
}

[data-theme-version="dark"] .quixnav {
    background-color: #2A2C32;
}

[data-theme-version="dark"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-theme-version="dark"] .quixnav .metismenu>li:hover>a,
[data-theme-version="dark"] .quixnav .metismenu>li:focus>a,
[data-theme-version="dark"] .quixnav .metismenu>li.mm-active>a {
    background-color: transparent;
    color: #593bdb;
}

[data-theme-version="dark"] .quixnav .metismenu>li:hover>a::after,
[data-theme-version="dark"] .quixnav .metismenu>li:focus>a::after,
[data-theme-version="dark"] .quixnav .metismenu>li.mm-active>a::after {
    border-color: transparent transparent #fff transparent;
}

[data-theme-version="dark"] .quixnav .metismenu>li.mm-active ul ul {
    background-color: transparent;
}

[data-theme-version="dark"] .quixnav .metismenu ul {
    background-color: #2A2C32;
}

[data-theme-version="dark"] .quixnav .metismenu ul a:hover,
[data-theme-version="dark"] .quixnav .metismenu ul a:focus,
[data-theme-version="dark"] .quixnav .metismenu ul a.mm-active {
    color: #fff;
}

[data-theme-version="dark"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-theme-version="dark"] .quixnav .metismenu .has-arrow:after {
    border-color: transparent transparent #b3b3b3 transparent;
}

[data-theme-version="dark"] .sidebar-right {
    background-color: #31343b;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs {
    background-color: #31343b;
    border-bottom: 1px solid #2A2C32;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-link.active {
    background-color: #2A2C32;
}

[data-theme-version="dark"] .footer {
    background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
    background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
    color: #fff;
}

[data-theme-version="transparent"] {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
}

[data-theme-version="transparent"] h1,
[data-theme-version="transparent"] h2,
[data-theme-version="transparent"] h3,
[data-theme-version="transparent"] h4,
[data-theme-version="transparent"] h5,
[data-theme-version="transparent"] h6 {
    color: #fff !important;
}

[data-theme-version="transparent"] a.link {
    color: #ddd;
}

[data-theme-version="transparent"] a.link:focus,
[data-theme-version="transparent"] a.link:hover {
    color: #50E3C2;
}

[data-theme-version="transparent"] a {
    color: #fff;
}

[data-theme-version="transparent"] a:hover {
    color: #828690;
}

[data-theme-version="transparent"] .border-right {
    border-right: 1px solid #424D63 !important;
}

[data-theme-version="transparent"] .border-left {
    border-left: 1px solid #424D63 !important;
}

[data-theme-version="transparent"] .border-top {
    border-top: 1px solid #424D63 !important;
}

[data-theme-version="transparent"] .border-bottom {
    border-bottom: 1px solid #424D63 !important;
}

[data-theme-version="transparent"] .border {
    border: 1px solid #424D63 !important;
}

[data-theme-version="transparent"] .dropdown-menu {
    background-color: #323A4B;
}

[data-theme-version="transparent"] .dropdown-item:hover,
[data-theme-version="transparent"] .dropdown-item:focus {
    background-color: #2A2C32;
    color: #fff;
}

[data-theme-version="transparent"] .form-control {
    background-color: #2A2C32;
    border-color: #424D63;
    color: #fff;
}

[data-theme-version="transparent"] .card {
    background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header {
    background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header-left input {
    border-color: #424D63;
    color: #fff;
}

[data-theme-version="transparent"] .header-left input:focus {
    box-shadow: none;
    border-color: #593bdb;
}

[data-theme-version="transparent"] .header-left input::placeholder {
    color: #fff;
}

[data-theme-version="transparent"] .header-right .dropdown .nav-link:hover {
    color: #fff;
}

[data-theme-version="transparent"] .header-right .notification_dropdown .dropdown-item a {
    color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
    color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
    color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
    color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
    color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
    color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
    color: #fff;
}

[data-theme-version="transparent"] .quixnav {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .quixnav .metismenu>li>a {
    color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .quixnav .metismenu>li:hover>a,
[data-theme-version="transparent"] .quixnav .metismenu>li:focus>a,
[data-theme-version="transparent"] .quixnav .metismenu>li.mm-active>a {
    background-color: rgba(0, 0, 0, 0.15) !important;
    color: #fff;
}

[data-theme-version="transparent"] .quixnav .metismenu>li:hover>a::after,
[data-theme-version="transparent"] .quixnav .metismenu>li:focus>a::after,
[data-theme-version="transparent"] .quixnav .metismenu>li.mm-active>a::after {
    border-color: transparent transparent #fff transparent;
}

[data-theme-version="transparent"] .quixnav .metismenu>li.mm-active ul ul {
    background-color: transparent;
}

[data-theme-version="transparent"] .quixnav .metismenu ul {
    background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .quixnav .metismenu ul a:hover,
[data-theme-version="transparent"] .quixnav .metismenu ul a:focus,
[data-theme-version="transparent"] .quixnav .metismenu ul a.mm-active {
    color: #fff;
}

[data-theme-version="transparent"] .quixnav .metismenu a {
    color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .quixnav .metismenu ul {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .quixnav .metismenu .has-arrow:after {
    border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
}


/* =============
   Calendar
============= */

.app-fullcalender button {
    border-radius: 0px;
    color: #454545;
}

.app-fullcalender td {
    border-color: #eaeaea;
}

.calendar {
    float: left;
    margin-bottom: 0;
}

.fc-view {
    margin-top: 1.875rem;
}

.none-border .modal-footer {
    border-top: none;
}

.fc-toolbar {
    margin-bottom: 0.3125rem;
    margin-top: 0.9375rem;
}

@media (max-width: 575.98px) {
    .fc-toolbar .fc-left {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.625rem;
        float: none;
    }
}

@media (max-width: 575.98px) {
    .fc-toolbar .fc-right {
        display: flex;
        justify-content: center;
        float: none;
        margin-bottom: 0.3125rem;
    }
}

@media (max-width: 575.98px) {
    .fc-toolbar .fc-center {
        display: flex;
        justify-content: center;
    }
    .fc-toolbar .fc-center * {
        float: none;
    }
}

.fc-toolbar h2 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
    z-index: 0;
    box-shadow: none;
}

.fc-widget-header {
    border: 1px solid #eaeaea;
    border-bottom: 0 !important;
}

.fc th.fc-widget-header {
    background: #eaeaea !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.625rem 0;
    text-transform: uppercase;
}

.fc-button {
    border: 1px solid #eaeaea;
    text-transform: capitalize;
}

.fc-button.active {
    box-shadow: none !important;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 1rem;
}

.fc-event {
    border-radius: 0.125rem;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 0.3125rem 0.4375rem;
    padding: 0.3125rem;
    text-align: center;
}

.external-event {
    cursor: move;
    margin: 0.625rem 0;
    padding: 0.125rem 0;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 0.3125rem;
}

.fc-basic-view td.fc-day-number {
    padding-right: 0.3125rem;
}

#drop-remove {
    margin-right: 8px;
    top: 0.1875rem;
}

#add-category .modal-header,
#event-modal .modal-header {
    border-bottom: 1px solid #eaeaea;
}

#add-category .modal-dialog,
#event-modal .modal-dialog {
    max-width: 37.5rem;
}

.fc-content {
    color: #fff;
}

.fc th.fc-widget-header {
    background: transparent !important;
}

.fc-button {
    background: #fff;
}

.fc-state-hover {
    background: #fff !important;
}

.fc-state-highlight {
    background: #F7FAFC !important;
}

[data-theme-version="dark"] .fc-state-highlight {
    color: #fff !important;
}

.fc-cell-overlay {
    background: #fff !important;
}

.fc-unthemed .fc-today {
    background: #F7FAFC !important;
}

.fc-day-top {
    color: #454545 !important;
}

[data-theme-version="dark"] .fc-day-top {
    color: #fff !important;
}

.external-event {
    color: #fff;
}

[data-theme-version="dark"] .external-event {
    color: #fff !important;
}

#add-category .modal-header,
#event-modal .modal-header {
    border-bottom: 1px solid #eaeaea;
}

#add-category .modal-content,
#event-modal .modal-content {
    background: #fff;
    border: 0;
    border-radius: 0px;
}

#add-category .modal-title,
#event-modal .modal-title {
    color: #454545 !important;
}

.fc-basic-view .fc-body .fc-row {
    min-height: 1rem;
}

.fc-scroller.fc-day-grid-container {
    height: 490px !important;
}

.fc-row.fc-week.fc-widget-content.fc-rigid {
    height: 81px !important;
}

@media only screen and (max-width: 1440px) {
    .email_left_pane {
        display: none;
    }
}

#external-events [data-class="bg-primary"] {
    background-color: transparent !important;
    color: #454545;
}

[data-theme-version="dark"] #external-events [data-class="bg-primary"] {
    color: #fff !important;
}

#external-events [data-class="bg-primary"]::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #593bdb;
    margin-right: .9rem;
    position: relative;
    top: 2px;
}

#external-events [data-class="bg-success"] {
    background-color: transparent !important;
    color: #454545;
}

#external-events [data-class="bg-success"]::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7ED321;
    margin-right: .9rem;
    position: relative;
    top: 2px;
}

#external-events [data-class="bg-warning"] {
    background-color: transparent !important;
    color: #454545;
}

#external-events [data-class="bg-warning"]::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FFAA16;
    margin-right: .9rem;
    position: relative;
    top: 2px;
}

#external-events [data-class="bg-dark"] {
    background-color: transparent !important;
    color: #454545;
}

#external-events [data-class="bg-dark"]::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #454545;
    margin-right: .9rem;
    position: relative;
    top: 2px;
}

#external-events [data-class="bg-danger"] {
    background-color: transparent !important;
    color: #454545;
}

#external-events [data-class="bg-danger"]::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FF1616;
    margin-right: .9rem;
    position: relative;
    top: 2px;
}

#external-events [data-class="bg-info"] {
    background-color: transparent !important;
    color: #454545;
}

#external-events [data-class="bg-info"]::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #50E3C2;
    margin-right: .9rem;
    position: relative;
    top: 2px;
}

#external-events [data-class="bg-pink"] {
    background-color: transparent !important;
    color: #454545;
}

#external-events [data-class="bg-pink"]::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #e83e8c;
    margin-right: .9rem;
    position: relative;
    top: 2px;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
    border-color: #eaeaea;
}

[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton table,
[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton td,
[data-theme-version="dark"] .fc .fc-row .fc-helper-skeleton td {
    border-color: #424D63;
}

[data-theme-version="dark"] .fc-unthemed .fc-content,
[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-list-view,
[data-theme-version="dark"] .fc-unthemed .fc-popover,
[data-theme-version="dark"] .fc-unthemed .fc-row,
[data-theme-version="dark"] .fc-unthemed tbody,
[data-theme-version="dark"] .fc-unthemed td,
[data-theme-version="dark"] .fc-unthemed th,
[data-theme-version="dark"] .fc-unthemed thead {
    border-color: #424D63;
}

.email-left-box {
    width: 15rem;
    float: left;
    padding: 0 1.25rem 1.25rem 1rem;
    border-top: 0;
    border-left: 0;
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .email-left-box {
        width: 12rem;
    }
}

@media (max-width: 575.98px) {
    .email-left-box {
        width: 100%;
        float: none;
        border: none;
    }
}

.email-left-box .intro-title {
    background: rgba(89, 59, 219, 0.1);
    padding: 1rem;
    margin: 1.875rem 0 1.25rem 0;
}

.email-left-box .intro-title h5 {
    margin-bottom: 0;
    color: #6a707e;
    font-size: 14px;
}

.email-left-box .intro-title h5 i {
    font-size: 0.75rem;
    position: relative;
    bottom: 1px;
}

.email-left-box .intro-title i {
    color: #593bdb;
}

.email-right-box {
    padding-left: 15rem;
    padding-right: 1rem;
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .email-right-box {
        padding-left: 12rem;
    }
}

@media (max-width: 575.98px) {
    .email-right-box {
        padding-left: 0;
        padding-right: 0;
    }
}

.email-right-box .right-box-border {
    border-right: 2px solid rgba(89, 59, 219, 0.1);
}

@media screen and (min-width: 649px) and (max-width: 1200px) {
    .email-right-box .right-box-padding {
        padding-left: 1.25rem;
    }
}

@media (min-width: 1700px) {
    .email-right-box .right-box-padding {
        padding-left: 0.9375rem;
    }
}

.toolbar .btn-group .btn {
    border: 0;
}

.toolbar .btn-group .dropdown-menu {
    background: #fff;
    border-radius: 0px;
}

.toolbar .btn-group .dropdown-menu a {
    color: #464a53;
}

.toolbar .btn-group input {
    position: relative;
    top: 2px;
}

.read-content textarea {
    background: rgba(0, 0, 0, 0.31);
    color: #828690;
    border-color: rgba(0, 0, 0, 0.1);
    padding: 0.625rem 1.25rem;
}

.read-content textarea::placeholder {
    color: #abafb3;
}

.read-content-email {
    font-size: 0.875rem;
}

.read-content h5 {
    color: #6a707e;
}

.read-content p strong {
    color: #6a707e;
}

.read-content-body p {
    margin-bottom: 1.875rem;
}

.read-content-attachment {
    padding: 1.5625rem 0;
}

.read-content-attachment h6 {
    font-size: 1.125rem;
    color: #6a707e;
}

.read-content-attachment h6 i {
    padding-right: 0.3125rem;
}

.read-content-attachment .attachment>div:not(:last-child) {
    border-right: 1px solid #DDDFE1;
}

.compose-content .wysihtml5-toolbar {
    border-color: #eaeaea;
}

.compose-content .dropzone {
    background: #F7FAFC !important;
}

.compose-content h5 {
    font-size: 1.0625rem;
    color: #6a707e;
}

.compose-content h5 i {
    font-size: 1.125rem;
    transform: rotate(90deg);
}

.compose-content .dropzone {
    border: 1px dashed #DDDFE1;
    min-height: 13.125rem;
    position: relative;
}

.compose-content .dropzone .dz-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.email-list {
    display: block;
    padding-left: 0;
}

.email-list .message {
    position: relative;
    display: block;
    height: 3.125rem;
    line-height: 3.125rem;
    cursor: default;
    transition-duration: 0.3s;
}

.email-list .message a {
    color: #828690;
}

.email-list .message-single .custom-checkbox {
    margin-top: 2px;
}

.email-list .message-single i {
    color: #666666;
    font-size: 1.125rem;
    padding-left: .4rem;
}

.email-list .message-single label::before {
    border: 1px solid #abafb3;
    width: 1.125rem;
    height: 1.125rem;
}

.email-list .message:hover {
    transition-duration: 0.05s;
    background: rgba(152, 166, 173, 0.15);
}

.email-list .message .col-mail {
    float: left;
    position: relative;
}

.email-list .message .col-mail-1 {
    width: 5.625rem;
}

.email-list .message .col-mail-1 .star-toggle {
    display: block;
    float: left;
    margin-top: 1.125rem;
    font-size: 1rem;
    margin-left: 0.3125rem;
}

.email-list .message .col-mail-1 .email-checkbox {
    display: block;
    float: left;
    margin: 0.9375rem 0.625rem 0 1.25rem;
}

.email-list .message .col-mail-1 .dot {
    display: block;
    float: left;
    border: .4rem solid transparent;
    border-radius: 6.25rem;
    margin: 1.375rem 1.625rem 0;
    height: 0;
    width: 0;
    line-height: 0;
    font-size: 0;
}

.email-list .message .col-mail-2 {
    position: absolute;
    top: 0;
    left: 5.625rem;
    right: 0;
    bottom: 0;
}

.email-list .message .col-mail-2 .subject {
    position: absolute;
    top: 0;
    left: 0;
    right: 5.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.email-list .message .col-mail-2 .date {
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
}

.email-checkbox {
    cursor: pointer;
    height: 0.9375rem;
    width: 0.9375rem;
    position: relative;
    display: inline-block;
    border-radius: .1rem;
    position: relative;
    top: 0.3125rem;
    box-shadow: inset 0 0 0 0.1rem #828690;
}

.email-checkbox input {
    opacity: 0;
    cursor: pointer;
}

.email-checkbox input:checked label {
    opacity: 1;
}

.email-checkbox label {
    position: absolute;
    top: .3rem;
    left: .3rem;
    right: .3rem;
    bottom: .3rem;
    cursor: pointer;
    opacity: 0;
    margin-bottom: 0 !important;
    transition-duration: 0.05s;
}

.mail-list a {
    vertical-align: middle;
    padding: 0.625rem 0.9375rem;
    display: block;
    background: transparent;
    color: #464a53;
    font-weight: 600;
}

.mail-list .list-group-item {
    border-radius: 3px;
    color: #6a707e;
    padding: 0.75rem 1.0625rem;
}

.mail-list .list-group-item i {
    font-size: 1rem;
    padding-right: 0.625rem;
    color: #cccccc;
}

.mail-list .list-group-item.active {
    color: #fff;
}

.mail-list .list-group-item.active i {
    color: #593bdb;
}

.chat-wrap {
    padding: 1.0625rem 1.875rem;
}

.chat-wrap .media .media-body h6 {
    font-size: 1.0625rem;
    color: #6a707e;
}

.chat-wrap .media .media-body p {
    font-size: 0.875rem;
}

@media (min-width: 648px) {
    .email-filter {
        padding-left: 1.25rem;
    }
}

@media (min-width: 1700px) {
    .email-filter {
        padding-left: 1.875rem;
    }
}

.email-filter .input-group-prepend i {
    font-size: 0.875rem;
    color: #666666;
}

.email-filter .input-group-prepend .input-group-text {
    border: 0;
    border-bottom: 1px solid #DDDFE1 !important;
    background: transparent;
}

.email-filter .input-group .form-control {
    padding: 0 0 0 0.3125rem;
    border: 0;
    font-size: 0.875rem;
    height: 1.875rem;
    color: #666666;
    border-bottom: 1px solid #DDDFE1;
}

.email-filter .input-group .form-control::placeholder {
    font-size: 0.875rem;
    color: #666666;
}

.email-filter .input-group>.form-control {
    min-height: 1.875rem;
}

.single-mail {
    display: block;
    padding: 1.5625rem 0;
}

.single-mail .media {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

@media (min-width: 1700px) {
    .single-mail .media {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
    }
}

.single-mail .media img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 0.9375rem;
}

@media (min-width: 1700px) {
    .single-mail .media img {
        margin-right: 1.875rem;
    }
}

.single-mail .media-body h6 {
    color: #abafb3;
}

.single-mail .media-body h4 {
    font-size: 1rem;
    color: #6a707e;
}

.single-mail .media-body h4 button i {
    font-size: 1.125rem;
    color: #abafb3;
    font-weight: bold;
    transform: rotate(90deg);
}

.single-mail .media-body p {
    font-size: 0.875rem;
    color: #abafb3;
}

.single-mail.active {
    background: #593bdb;
}

.single-mail.active h6,
.single-mail.active h4,
.single-mail.active p,
.single-mail.active i {
    color: #fff !important;
}

.photo-content {
    position: relative;
}

.photo-content .cover-photo {
    background: url(../images/profile/cover.jpg);
    background-size: cover;
    background-position: center;
    min-height: 250px;
    width: 100%;
}

.photo-content .profile-photo {
    bottom: -75px;
    left: 100px;
    max-width: 150px;
    position: absolute;
}

.profile-info {
    padding: 30px 20px;
    background: #F7FAFC;
}

.profile-info h4 {
    color: #464a53 !important;
}

.profile-info h4.text-primary {
    color: #593bdb !important;
}

.profile-info p {
    color: #828690;
}

.profile-info .prf-col {
    min-width: 250px;
    padding: 10px 50px 0;
}

.profile-interest .row {
    margin: 0 -.70px;
}

.profile-interest .row .int-col {
    padding: 0 .70px;
}

.profile-interest .row .int-col .interest-cat {
    margin-bottom: 1.40px;
    position: relative;
    display: block;
}

.profile-interest .row .int-col .interest-cat:after {
    background: #000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
    left: 0;
    margin: 0;
    z-index: 1;
    color: white;
    font-size: 1.20px;
}

.profile-tab .nav-item .nav-link {
    font-size: 18px;
    padding: 10px 0 .50px;
    margin-right: 30px;
    transition: all 0.5s ease-in-out;
    border: none;
    border-bottom: .20px solid transparent;
    color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
    border: 0;
    background: transparent;
    background: transparent;
    border-bottom: 0.2px solid #593bdb;
    color: #464a53;
}

.post-input {
    margin-bottom: 30px;
}

.post-input .form-control {
    height: 75px;
    font-weight: 400;
    margin: 15px 0;
}

.post-input a {
    display: inline-block;
    font-size: 22px;
}

.post-input a i {
    display: block;
    margin-right: 15px;
    padding: 7px 20px;
    border-radius: .50px;
    border: 1px solid #eaeaea;
    color: #828690;
}

.post-input .btn {
    padding: 7px 30px;
    font-size: 14px;
    margin-top: -10px;
}

.profile-uoloaded-post img {
    margin-bottom: 20px;
}

.profile-uoloaded-post a h4 {
    margin-bottom: 10px;
    color: #464a53;
}

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
    margin-bottom: 1.875rem;
    padding: 0;
}

.event-chat-ryt .chat-area .chat-reciver img,
.event-chat-ryt .chat-area .chat-sender img {
    border-radius: 30px;
}

.event-chat-ryt .chat-area .media {
    position: relative;
}

.event-chat-ryt .chat-area .media-body p {
    margin: 0;
    max-width: 100%;
    display: inline-block;
    position: relative;
}

.event-chat-ryt .chat-area .media-body p span {
    padding: 1rem;
    display: inline-block;
    top: 103%;
    position: relative;
    border: 1px solid #eaeaea;
}

.event-chat-ryt .chat-reciver {
    padding: .5rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.event-chat-ryt .chat-reciver .media {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.event-chat-ryt .chat-reciver .media .media-body {
    margin-right: 2rem;
    text-align: right;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body {
    text-align: left;
    margin-left: 2rem;
    margin-right: auto;
}

.event-chat-ryt .chat-reciver .media .media-body p {
    background: #fff;
    margin-bottom: 0;
    border-radius: 5px 5px 0 5px;
}

.event-chat-ryt .chat-reciver .media .media-body p span {
    text-align: left;
    border: 1px solid #eaeaea;
}

.event-chat-ryt .chat-reciver .media .media-body p span::after {
    content: "";
    width: 20px;
    height: 20px;
    border-bottom: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff;
    -webkit-transform: rotate(-45deg) translateX(15px);
    transform: rotate(-45deg) translateX(15px);
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p span::after {
    left: 0;
    right: auto;
    -webkit-transform: rotate(135deg) translateY(15px);
    transform: rotate(135deg) translateY(15px);
}

.event-chat-ryt .chat-reciver .media .media-body p .time {
    position: absolute;
    font-size: 12px;
    color: #BDBDC7;
    font-weight: 400;
    bottom: 0;
    left: -80px;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p .time {
    right: -5rem;
    left: auto;
}

.event-chat-ryt .chat-sender {
    text-align: left;
    padding: .5rem 1rem;
}

.event-chat-ryt .chat-sender .media .media-body {
    margin-left: 2rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body {
    text-align: right;
    margin-right: 2rem;
    margin-left: auto;
}

.event-chat-ryt .chat-sender .media .media-body p {
    background-color: #fff;
    margin-bottom: 0;
}

.event-chat-ryt .chat-sender .media .media-body p span::after {
    content: "";
    width: 20px;
    height: 20px;
    border-bottom: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    -webkit-transform: rotate(45deg) translateX(-15px);
    transform: rotate(45deg) translateX(-15px);
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p span::after {
    left: auto;
    right: 0;
    -webkit-transform: rotate(-135deg) translateY(15px);
    transform: rotate(-135deg) translateY(15px);
}

.event-chat-ryt .chat-sender .media .media-body p .time {
    position: absolute;
    font-size: 10px;
    color: #BDBDC7;
    font-weight: 400;
    bottom: 0;
    right: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p .time {
    left: -5rem;
    right: auto;
}

.char-type {
    padding-top: 30px;
    padding-bottom: 30px;
}

.char-type form .form-control {
    height: 45px;
    padding-left: 18px;
    background: rgba(88, 115, 254, 0.04);
    border-right: 0;
}

.char-type form .input-group-append i {
    color: #898989;
    font-size: 18px;
}

.char-type form .input-group-append .input-group-text {
    padding-left: .7rem;
    padding-right: .7rem;
    background: rgba(88, 115, 254, 0.04);
    border-color: #eaeaea;
    border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
    padding-right: 1.8rem;
}

.media-avatar {
    padding: 25px 0;
    border-bottom: 1px solid #eaeaea;
}

.media-avatar:last-child {
    border-bottom: 0px;
}

.media-avatar p {
    margin-bottom: 0;
}

.media-avatar .avatar-status {
    position: relative;
}

.media-avatar .avatar-status i {
    position: absolute;
    right: 0;
    bottom: 0;
}

.ct-golden-section:before {
    float: none;
}

.ct-chart {
    max-height: 15.7rem;
}

.ct-chart .ct-label {
    fill: #a3afb7;
    color: #a3afb7;
    font-size: 0.75rem;
    line-height: 1;
}

.ct-grid {
    stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
    color: #ffffff;
    fill: #ffffff;
    font-size: 0.625rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
    stroke: #44ecf5;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
    stroke: #c00a27;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
    stroke: #f9c70a;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
    stroke: #f25521;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
    stroke: #313a46;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
    stroke: #00A2FF;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
    stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
    fill: #44ecf5;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
    fill: #00A2FF;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
    fill: #ff9800;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
    fill: #ff9800;
}

.chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 0.625rem;
    padding: 2px 0.625rem;
    border-radius: 3px;
    background: #313a46;
    color: #ffffff;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
    opacity: 1;
}

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
    stroke: #3FC6D4;
}

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
    stroke: #333333;
}

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
    stroke: #F63465;
}

#visitor_graph {
    height: 253px !important;
}

#user_rating_graph {
    height: 280px !important;
}

#activity {
    height: 230px !important;
}

#visitorOnline {
    height: 72px !important;
}

#trendMeter {
    height: 72px !important;
}

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
    height: 117px !important;
}

#widget-profit1,
#widget-profit2,
#widget-profit3 {
    height: 160px !important;
}

#comparison-rate {
    height: 230px !important;
}

#session_day {
    height: 175px !important;
    width: auto !important;
    margin: 0 auto;
}

#walet-status {
    height: 140px !important;
}

#bar1 {
    height: 150px !important;
}

#sold-product {
    height: 230px !important;
}

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
    height: 150px !important;
}

#areaChart_3 {
    height: 295px !important;
}

.c3 {
    height: 250px;
}

.c3-legend-item {
    fill: #9fabb1;
}

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
    stroke: #fff;
}

.flot-chart {
    height: 15.7rem;
}

.tooltipflot {
    background-color: transparent;
    font-size: 1.4rem;
    padding: .5rem 1rem;
    color: rgba(255, 255, 255, 0.7);
    border-radius: .2rem;
}

.legendColorBox>div {
    border: 0 !important;
    padding: 0 !important;
}

.legendLabel {
    font-size: 0.825rem;
    padding-left: .5rem;
    color: #fff;
}

.flotTip {
    background: #000;
    border: 1px solid #000;
    color: #fff;
}

.legend>div {
    background: transparent !important;
}

#balance_graph {
    height: 260px;
}

.morris-hover {
    position: absolute;
    z-index: 1;
    color: #fff;
}

.morris-hover .morris-hover-point {
    color: #fff !important;
    margin: 3px 0;
    text-align: center;
    padding: 0 25px;
}

.morris-hover .morris-hover-row-label {
    background-color: #454545;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
}

.morris-hover.morris-default-style {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;
}

svg text {
    font-weight: 600 !important;
}

#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
    height: 240px !important;
}

#morris_line {
    height: 278px !important;
}

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
    height: 9.375rem;
}

#daily-sales,
#comparison-rate,
#usage-chart,
#walet-status {
    width: 100%;
    display: block;
}

#daily-sales canvas,
#comparison-rate canvas,
#usage-chart canvas,
#walet-status canvas {
    max-width: 100% !important;
    width: 100% !important;
}

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
    height: 100px !important;
}

#sparkline11 canvas {
    width: 100px !important;
}

.easy-pie-chart {
    position: relative;
    text-align: center;
}

.easy-pie-chart .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
}

.easy-pie-chart img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.easy-pie-chart canvas {
    display: block;
    margin: 0 auto;
}

#revenue-chart {
    height: 27.7rem;
}

#duration-value-axis {
    height: 27.6rem;
    width: 100%;
}

#combined-bullet {
    height: 28.125rem;
    width: 100%;
}

#zoomable-chart {
    height: 28.125rem;
    width: 100%;
}

#chartMap {
    height: 28.125rem;
    width: 100%;
}

#professional-candlesticks {
    width: 100%;
    height: 28.125rem;
}

#comparing-stock-indices {
    width: 100%;
    height: 28.125rem;
}

#multiple-panel-data {
    width: 100%;
    height: 28.125rem;
}

#depth-chart {
    width: 100%;
    height: 28.125rem;
}

.amcharts-export-menu {
    display: none;
}

.amcharts-data-set-selector-div {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 16.875rem;
    margin: 0 auto;
}

.amcharts-data-set-selector-div select {
    border: 0;
    margin-left: 0.625rem;
    background: #ddd;
    color: #000;
}

.amChartsInputField {
    border: 0;
    background: #593bdb;
    color: #fff;
    padding: 0.3125rem 0.9375rem;
    margin: 0 0.9375rem;
}

.amcharts-data-set-select {
    border: 0;
    background: #ddd;
    color: #000;
}

.amcharts-period-input,
.amcharts-period-input-selected {
    border: 0;
    margin-left: 0.625rem;
    background: #593bdb;
    color: #fff;
    padding: 0.3125rem 0.9375rem;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
    stroke-dasharray: 3px 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -1.9375rem;
    }
}

@keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -1.9375rem;
    }
}

.lastBullet {
    -webkit-animation: am-pulsating 1s ease-out infinite;
    animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
    0% {
        stroke-opacity: 1;
        stroke-width: 0px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 3.125rem;
    }
}

@keyframes am-pulsating {
    0% {
        stroke-opacity: 1;
        stroke-width: 0px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 3.125rem;
    }
}

.amcharts-graph-column-front {
    -webkit-transition: all .3s .3s ease-out;
    transition: all .3s .3s ease-out;
}

.amcharts-graph-column-front:hover {
    fill: #496375;
    stroke: #496375;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

@-webkit-keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

@keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

@media only screen and (max-width: 991px) {
    .amChartsPeriodSelector>fieldset>div {
        float: none !important;
        display: block !important;
        margin-bottom: 0.625rem;
    }
}

.highcharts-root text {
    font-weight: 300 !important;
}

.highcharts-credits {
    display: none;
}

#chart_employee_gender,
#chart_employee_status {
    width: auto;
    height: 350px;
}

.form-control {
    background: #fff;
    border: 1px solid #eaeaea;
    color: #454545;
}

.form-control:hover,
.form-control:focus,
.form-control.active {
    box-shadow: none;
    background: #fff;
    color: #454545;
}

.input-rounded {
    border-radius: 100px;
}

.input-group-text {
    background: #656c73;
    color: #fff;
    border: 1px solid transparent;
    min-width: 50px;
    display: flex;
    justify-content: center;
}

.input-group-text i {
    color: #fff;
    font-size: 22px;
}

.input-group-prepend .btn,
.input-group-append .btn {
    z-index: 0;
}

.custom-select {
    background: none;
    border-color: #eaeaea;
    color: #454545;
}

.custom-select:focus {
    box-shadow: none;
    border-color: #593bdb;
    color: #593bdb;
}

.custom-file-label:after {
    background: #656C73;
    border: 1px solid #eaeaea;
    color: #fff;
}

.custom_file_input .custom-file-label::after {
    height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
    background: #fff;
    opacity: 1;
}

.custom-file-label {
    background: #fff;
    border-color: #eaeaea;
}

input[type="checkbox"]:after {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin-top: 0px;
    margin-left: -1px;
    border: 1px solid transparent;
    border-radius: 3px;
    background: #d4d7da;
    line-height: 1.3;
}

input[type="checkbox"]:checked:after {
    width: 1rem;
    height: 1rem;
    display: block;
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #fff;
    font-weight: 100;
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    background: #2082FB;
}

.form-check-label {
    margin-left: 5px;
    margin-top: 3px;
}

.form-check-inline .form-check-input {
    margin-right: 0.625rem;
}

.js-switch+.switchery {
    border-radius: 50px;
    margin-right: 4rem;
}

@media (max-width: 767.98px) {
    .js-switch+.switchery {
        margin-right: 1rem;
    }
}

.js-switch+.switchery>small {
    top: 2px;
}

.js-switch.js-switch-lg+.switchery {
    height: 2rem;
    width: 4.5rem;
}

.js-switch.js-switch-lg+.switchery>small {
    width: 1.75rem;
    height: 1.75rem;
}

.js-switch.js-switch-md+.switchery {
    height: 1.5rem;
    width: 3.5rem;
}

.js-switch.js-switch-md+.switchery>small {
    width: 1.25rem;
    height: 1.25rem;
}

.js-switch.js-switch-sm+.switchery {
    height: 1rem;
    width: 2.2rem;
}

.js-switch.js-switch-sm+.switchery>small {
    width: 0.875rem;
    height: 0.875rem;
    top: 1px;
}

.js-switch-square+.switchery {
    border-radius: 0;
}

.js-switch-square+.switchery>small {
    border-radius: 0;
    top: 2px;
}

.js-switch-square.js-switch-lg+.switchery {
    height: 2rem;
    width: 4.5rem;
}

.js-switch-square.js-switch-lg+.switchery>small {
    width: 1.75rem;
    height: 1.75rem;
}

.js-switch-square.js-switch-md+.switchery {
    height: 1.5rem;
    width: 3.5rem;
}

.js-switch-square.js-switch-md+.switchery>small {
    width: 1.25rem;
    height: 1.25rem;
}

.js-switch-square.js-switch-sm+.switchery {
    height: 1rem;
    width: 2.2rem;
}

.js-switch-square.js-switch-sm+.switchery>small {
    width: 0.875rem;
    height: 0.875rem;
    top: 1px;
}

.form-control.is-valid {
    border-color: #7ED321 !important;
    border-right: 0px !important;
}

.form-control.is-valid:focus {
    box-shadow: none;
}

.form-control.is-warning {
    border-color: #FFAA16 !important;
    border-right: 0px !important;
}

.form-control.is-warning:focus {
    box-shadow: none;
}

.form-control.is-invalid {
    border-color: #FF1616 !important;
    border-right: 0px !important;
}

.form-control.is-invalid:focus {
    box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
    color: #7ED321;
}

.is-invalid .input-group-prepend .input-group-text i {
    color: #907ce7;
}

.asColorPicker-dropdown {
    max-width: 26rem;
}

.asColorPicker-trigger {
    border: 0 none;
    height: 100%;
    position: absolute;
    left: 99%;
    top: 0;
    width: 2.1875rem;
}

.asColorPicker-clear {
    display: none;
    position: absolute;
    right: 1rem;
    text-decoration: none;
    top: .5rem;
}

.daterangepicker td.active {
    background-color: #593bdb;
}

.daterangepicker td.active:hover {
    background-color: #593bdb;
}

.daterangepicker button.applyBtn {
    background-color: #593bdb;
    border-color: #593bdb;
}

.datepicker.datepicker-dropdown {
    background: #F7FAFC;
    border-radius: 1px;
    border: 1px solid #EEEEEE;
}

.datepicker.datepicker-dropdown td.day,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
    height: 30px;
    width: 30px !important;
    padding: 0;
    text-align: center;
    font-weight: 300;
    border-radius: 50px;
}

.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #fff;
}

.datepicker.datepicker-dropdown th.datepicker-switch,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
    font-weight: 300;
    color: #333;
}

.datepicker.datepicker-dropdown th.dow {
    font-weight: 300;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    border: 0;
}

.datepicker table tr td.today {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.datepicker table tr td.today:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.datepicker table tr td.today.disabled {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.datepicker table tr td.today.disabled:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.picker__select--month,
.picker__select--year {
    height: 2.5em;
}

.picker__input {
    background-color: transparent !important;
}

[data-theme-version="dark"] .picker__input {
    background-color: transparent !important;
    border: 1px solid #424D63;
}

#image {
    max-width: 100%;
}

.docs-options .dropdown-menu {
    padding: 1.5rem;
}

.docs-preview {
    margin-bottom: 3rem;
}

.docs-preview .img-preview {
    float: left;
    margin-right: .5rem;
    margin-bottom: .5rem;
    overflow: hidden;
    max-width: 100% !important;
}

.docs-preview .img-preview>img {
    max-width: 100% !important;
}

.docs-preview .img-preview.preview-lg {
    width: 16rem;
    height: 9rem;
}

.docs-preview .img-preview.preview-md {
    width: 8rem;
    height: 4.5rem;
}

.docs-preview .img-preview.preview-sm {
    width: 4rem;
    height: 2.25rem;
}

.docs-preview .img-preview.preview-xs {
    width: 2rem;
    height: 1.125rem;
    margin-right: 0;
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    border-radius: 0;
    border: 1px solid #eaeaea;
    height: calc(1.95rem + 2px);
    background: #fff;
}

.select2-container--default .select2-selection--single:hover,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single.active {
    box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 32px;
}

.select2-container--default .select2-selection--multiple {
    border-color: #eaeaea;
    border-radius: 0;
}

.select2-dropdown {
    border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #593bdb;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #eaeaea;
    background: #fff;
}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

.wizard>.content>.body input.error {
    position: absolute;
    top: 100% !important;
}

.wizard {
    position: relative;
}

.wizard>.steps {
    width: 100%;
}

@media (min-width: 1200px) {
    .wizard>.steps {
        width: 100%;
    }
}

.wizard>.steps li.current a {
    background-color: #593bdb;
    color: #fff;
}

.wizard>.steps li.disabled a,
.wizard>.steps li.done a {
    background-color: #fff;
    color: #333;
}

.wizard>.steps li.disabled a:hover,
.wizard>.steps li.done a:hover {
    margin: 0;
    background-color: #fff;
    border-radius: 0;
    color: #333;
}

.wizard>.steps li a {
    text-align: center;
    margin: 0;
    border-radius: 0;
    background-color: #fff;
}

.wizard>.steps li a:hover {
    margin: 0;
    background-color: #fff;
    border-radius: 0;
    color: #333;
}

@media only screen and (max-width: 767px) {
    .wizard>.steps li a {
        padding: 1em 4px;
    }
}

.wizard>.steps li a .number {
    display: none;
}

.wizard .content {
    min-height: 35rem;
    margin: 0;
}

@media (max-width: 991.98px) {
    .wizard .content {
        min-height: 41rem;
    }
}

.wizard .content>.body {
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 2rem;
}

@media only screen and (max-width: 575px) {
    .wizard .content>.body {
        padding: 0;
    }
}

.wizard .content>.body .form-control {
    border: 1px solid #eaeaea;
}

.wizard .content>.body input.error {
    position: relative;
}

.wizard .content>.body label.error {
    position: absolute;
    top: 100%;
    margin-left: 0;
}

.wizard .emial-setup label.mailclinet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: #EEF5F9;
}

@media only screen and (max-width: 575px) {
    .wizard .emial-setup label.mailclinet {
        width: 7rem;
        height: 7rem;
    }
}

.wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 3rem;
    display: inline-block;
    line-height: 1;
    margin-top: -1rem;
}

@media only screen and (max-width: 575px) {
    .wizard .emial-setup label.mailclinet .mail-icon {
        font-size: 2rem;
    }
}

.wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    text-align: center;
    margin-top: .5rem;
}

@media only screen and (max-width: 575px) {
    .wizard .emial-setup label.mailclinet .mail-text {
        font-size: 1.2rem;
    }
}

.wizard .emial-setup label.mailclinet input[type="radio"] {
    display: none;
}

.wizard .skip-email {
    margin-top: 6rem;
}

.wizard .skip-email p {
    margin-bottom: 1rem;
}

.wizard .skip-email a {
    color: #593bdb;
    text-decoration: underline;
}

.wizard>.actions {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem;
}

.wizard>.actions li:not(.disabled) a {
    background-color: #593bdb;
    color: #fff;
}

.wizard .actions ul li.disabled a {
    color: #333333 !important;
    background: #F7FAFC !important;
    border: 1px solid #EEEEEE;
}

[data-theme-version="dark"] .wizard .actions ul li.disabled a {
    background: #2A2C32 !important;
    border-color: #424D63;
}

.wizard .actions ul li a {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    padding: 0.55em 2em;
}

.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active {
    padding: 0.55em 2em;
}

.wizard>.content {
    background: transparent;
}

.wizard,
.tabcontrol {
    overflow: visible;
}

.step-form-horizontal .wizard {
    padding-top: 3.5rem;
}

.step-form-horizontal .wizard .steps li {
    position: relative;
}

.step-form-horizontal .wizard .steps li.done a {
    text-align: center;
    background-color: #fff;
    color: #454545;
}

.step-form-horizontal .wizard .steps li.done a:hover {
    text-align: center;
    background-color: #fff;
    color: #454545;
}

.step-form-horizontal .wizard .steps li.done a .number {
    color: #fff;
    background: #593bdb;
    border-color: transparent;
}

.step-form-horizontal .wizard .steps li.current a,
.step-form-horizontal .wizard .steps li.disabled a {
    background-color: transparent;
    text-align: center;
    color: #fff;
}

.step-form-horizontal .wizard .steps li.current a:hover,
.step-form-horizontal .wizard .steps li.current a:active,
.step-form-horizontal .wizard .steps li.disabled a:hover,
.step-form-horizontal .wizard .steps li.disabled a:active {
    background-color: transparent;
    color: #fff;
}

.step-form-horizontal .wizard .steps li.current a .number {
    color: #593bdb;
    background: #fff;
    border: 2px solid #593bdb;
}

.step-form-horizontal .wizard .steps li.disabled a .number {
    color: #593bdb;
}

.step-form-horizontal .wizard .steps li a {
    position: relative;
    z-index: 1;
}

@media (max-width: 767.98px) {
    .step-form-horizontal .wizard .steps li a {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }
}

@media (max-width: 575.98px) {
    .step-form-horizontal .wizard .steps li a {
        font-size: 0.5rem;
        padding-top: 0.9375rem;
    }
}

.step-form-horizontal .wizard .steps li a .number {
    position: absolute;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    border-radius: 50px;
    width: 2rem;
}

.step-form-horizontal .wizard .steps li:not(:last-child) {
    z-index: 1;
}

.step-form-horizontal .wizard .steps li:not(:last-child)::after {
    position: absolute;
    width: 100%;
    height: 0.375rem;
    content: "";
    left: 50%;
    top: -2.1rem;
    background-color: #593bdb;
    z-index: -1;
}

@media (max-width: 575.98px) {
    .step-form-horizontal .wizard .steps li:not(:last-child)::after {
        height: 0.375rem;
        top: -1.3125rem;
    }
}

.step-form-horizontal .wizard .steps li:not(:last-child).current::after {
    background-color: #F3F3F3;
}

.step-form-horizontal .wizard .steps li:not(:last-child).current~li::after {
    background-color: #F3F3F3;
}

.step-form-horizontal .wizard .actions {
    padding: 0;
}

@media only screen and (max-width: 575px) {
    .step-form-horizontal .wizard .actions {
        padding: 0;
    }
}

.note-icon-caret,
.note-popover {
    display: none;
}

.note-editor.note-frame .panel-heading {
    padding: .6rem 1rem 1rem;
}

.note-editor.note-frame {
    border-color: #eaeaea;
}

.note-editor.note-frame .note-editing-area .note-editable {
    background-color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
    background-color: #2A2C32;
}

.note-editor.note-frame .note-statusbar {
    background-color: #fff;
}

.ql-container {
    height: 25rem;
}

#world-datamap {
    padding-bottom: 46% !important;
}

.datamaps-hoverover {
    background: #fff;
    padding: 0.3125rem;
    border-radius: 0.3125rem;
    font-family: 'Roboto' !important;
    color: #593bdb;
    border: 1px solid rgba(89, 59, 219, 0.3);
}

@media only screen and (max-width: 1440px) {
    .world_map_card ul.list-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 35px;
    }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    height: 20px;
    width: 20px;
    line-height: 14px;
    background-color: #593bdb;
    color: #fff;
}

.jqvmap-zoomout {
    top: 35px;
}

#world-map {
    height: 400px;
}

.blockUI.blockMsg.blockPage {
    border: 0 !important;
}

#loginForm {
    cursor: auto;
}

.blockMsg {
    border: 0px !important;
    width: 20% !important;
}

.blockMsg h1 {
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 0;
}

.bootstrap-select {
    margin-bottom: 1rem;
}

.bootstrap-select .btn-light {
    background: #454545;
    border-color: #454545;
    color: #fff !important;
}

.bootstrap-select .btn-light:active,
.bootstrap-select .btn-light:focus,
.bootstrap-select .btn-light:hover {
    background: #2c2c2c;
    color: #fff;
    border-color: #2c2c2c;
    outline: none !important;
}

[data-theme-version="dark"] .bootstrap-select .btn-light:active,
[data-theme-version="dark"] .bootstrap-select .btn-light:focus,
[data-theme-version="dark"] .bootstrap-select .btn-light:hover {
    background: #2A2C32;
}

[data-theme-version="dark"] .bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
[data-theme-version="dark"] .bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
[data-theme-version="dark"] .bootstrap-select .show>.btn-light.dropdown-toggle {
    background: #2A2C32;
}

.bootstrap-select.show .btn {
    background: transparent;
    border-color: #DDDFE1;
    color: #454545;
}

.bootstrap-select .text-muted {
    background: transparent;
    border-color: #DDDFE1;
}

.bootstrap-select .text-muted.dropdown-toggle::after {
    color: #454545;
    font-size: 1.5rem;
}

.clipboard-btn {
    transition: all .1s ease-in-out;
}

.clipboard-btn:hover {
    background-color: #593bdb;
    color: #fff;
}

.crypto-ticker {
    background: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 3px;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

[data-theme-version="dark"] .crypto-ticker {
    background: #323A4B;
}

#webticker-big {
    font: inherit !important;
    font-size: inherit !important;
    font-weight: normal !important;
}

#webticker-big li i {
    font-size: 18px;
    margin-right: 7px;
}

#webticker-big li p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 700;
}

.twitter-typeahead {
    width: 100%;
}

.twitter-typeahead .tt-dataset.tt-dataset-states {
    border: 1px solid #eaeaea;
}

.twitter-typeahead .tt-menu {
    width: 100%;
    background-color: #fff;
}

.twitter-typeahead .tt-menu .tt-suggestion {
    padding: 0.625rem;
    cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
    background-color: #593bdb;
    color: #fff;
}

.weather-one i {
    font-size: 8rem;
    position: relative;
    top: .5rem;
}

.weather-one h2 {
    display: inline-block;
    float: right;
    font-size: 4.8rem;
}

.weather-one .city {
    position: relative;
    text-align: right;
    top: -2.5rem;
}

.weather-one .currently {
    font-size: 1.6rem;
    font-weight: 400;
    position: relative;
    top: 2.5rem;
}

.weather-one .celcious {
    text-align: right;
    font-size: 2rem;
}

.noUi-target {
    border-color: transparent;
    border-radius: 0;
}

.noUi-connect {
    background-color: #593bdb;
}

.noUi-connects {
    background-color: #D2D6DE;
}

.noUi-connect.c-1-color {
    background-color: #7ED321;
}

.noUi-connect.c-2-color {
    background-color: #50E3C2;
}

.noUi-connect.c-3-color {
    background-color: #593bdb;
}

.noUi-connect.c-4-color {
    background-color: #FFAA16;
}

.noUi-vertical {
    width: 0.75rem;
}

.noUi-horizontal {
    height: 0.75rem;
    margin-bottom: 10px;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    box-shadow: none;
    border: none;
    background-color: #593bdb;
}

.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before,
.noUi-vertical .noUi-handle::after,
.noUi-vertical .noUi-handle::before {
    display: none;
}

.noUi-horizontal .noUi-handle {
    top: -5px;
}

#slider-toggle {
    height: 50px;
}

#slider-toggle.off .noUi-handle {
    border-color: #593bdb;
}

.colorpicker-slider .sliders.noUi-target#red,
.colorpicker-slider .sliders.noUi-target#green,
.colorpicker-slider .sliders.noUi-target#blue {
    margin: 10px;
    display: inline-block;
    height: 200px;
}

.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
    background: #c0392b;
}

.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
    background: #27ae60;
}

.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
    background: #2980b9;
}

.colorpicker-slider #result {
    margin: 60px 26px;
    height: 100px;
    width: 100px;
    display: inline-block;
    vertical-align: top;
    color: #7f7f7f;
    background: #7f7f7f;
    border: 1px solid #fff;
    box-shadow: 0 0 10px;
}

.slider-vertical {
    height: 18rem;
}

.nestable-cart {
    overflow: hidden;
}

.dd-handle {
    color: #fff;
    background: #593bdb;
    border-radius: 0;
    border: 1px solid #eaeaea;
}

.dd-handle:hover {
    color: #fff;
    background: #593bdb;
}

.dd3-content:hover {
    color: #fff;
    background: #593bdb;
}

.dd3-content {
    color: #fff;
}

.pignose-calendar {
    box-shadow: none;
    width: 100%;
    max-width: none;
    border-color: #593bdb;
}

.pignose-calendar .pignose-calendar-top-date {
    background-color: #fff;
}

.pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
    color: #fff;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
    background-color: #593bdb;
    box-shadow: none;
    border-radius: 5px;
}

.pignose-calendar .pignose-calendar-top {
    box-shadow: none;
    border-bottom: 0;
}

.pignose-calendar.pignose-calendar-blue {
    background-color: transparent;
}

.cd-h-timeline {
    opacity: 0;
    transition: opacity 0.2s;
}

.cd-h-timeline--loaded {
    opacity: 1;
}

.cd-h-timeline__container {
    position: relative;
    height: 100px;
    max-width: 800px;
}

.cd-h-timeline__dates {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}

.cd-h-timeline__dates::after,
.cd-h-timeline__dates::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}

.cd-h-timeline__dates::before {
    left: 0;
    background: #593bdb;
}

.cd-h-timeline__dates::after {
    right: 0;
    background: #593bdb;
}

.cd-h-timeline__line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    background-color: #593bdb;
    transition: transform 0.4s;
}

.cd-h-timeline__filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #7ED321;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.3s;
}

.cd-h-timeline__date {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 0.8em;
    padding-bottom: var(--space-sm);
    color: var(--cd-color-1);
    user-select: none;
    text-decoration: none;
}

.cd-h-timeline__date::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #5c3fdc;
    background-color: #593bdb;
    transition: background-color 0.3s, border-color .3s;
}

.cd-h-timeline__date:hover::after {
    background-color: #7ED321;
    border-color: #7ED321;
}

.cd-h-timeline__date--selected {
    pointer-events: none;
}

.cd-h-timeline__date--selected::after {
    background-color: #7ED321;
    border-color: #7ED321;
}

.cd-h-timeline__date--older-event::after {
    border-color: #7ED321;
}

.cd-h-timeline__navigation {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #5c3fdc;
    transition: border-color 0.3s;
}

.cd-h-timeline__navigation::after {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: url(../images/svg/arrow_up.svg) no-repeat 0 0;
}

.cd-h-timeline__navigation:hover {
    border-color: #7ED321;
}

.cd-h-timeline__navigation--prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
}

.cd-h-timeline__navigation--next {
    right: 0;
}

.cd-h-timeline__navigation--inactive {
    cursor: not-allowed;
}

.cd-h-timeline__navigation--inactive::after {
    background-position: 0 -16px;
}

.cd-h-timeline__navigation--inactive:hover {
    border-color: #5c3fdc;
}

.cd-h-timeline__events {
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: height .4s;
}

.cd-h-timeline__event {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    padding: 1px 5%;
    opacity: 0;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
}

.cd-h-timeline__event--selected {
    position: relative;
    z-index: 2;
    opacity: 1;
    transform: translateX(0);
}

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
    animation-name: cd-enter-right;
}

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
    animation-name: cd-enter-left;
}

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
    animation-direction: reverse;
}

.cd-h-timeline__event-content {
    max-width: 800px;
}

.cd-h-timeline__event-title {
    color: var(--cd-color-1);
    font-family: var(--font-secondary);
    font-weight: 700;
    font-size: var(--text-xxxl);
}

.cd-h-timeline__event-date {
    display: block;
    font-style: italic;
    margin: var(--space-xs) auto;
}

.cd-h-timeline__event-date::before {
    content: '- ';
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes cd-enter-left {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.toast-success {
    background-color: #593bdb;
}

.toast-info {
    background-color: #50E3C2;
}

.toast-warning {
    background-color: #FFAA16;
}

.toast-error {
    background-color: #FF1616;
}

#toast-container>div {
    box-shadow: none;
    border-radius: 0;
    width: auto;
    max-width: 250px;
    opacity: 1;
}

[direction="rtl"] #toast-container>div {
    padding: 15px 50px 15px 15px;
    background-position: calc(100% - 15px);
    text-align: right;
}

#toast-container>div:hover {
    box-shadow: none;
}

#toast-container .toast-title {
    margin-bottom: 5px;
    font-weight: 600;
}

#toast-container .toast-message {
    font-size: 12px;
}

#toast-container .toast-close-button {
    opacity: 1;
    font-size: 8px;
    font-weight: normal;
    text-shadow: none;
}

[direction="rtl"] .toast-top-right.demo_rtl_class {
    left: 12px;
    right: auto;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    position: absolute;
    left: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #000000;
    padding: 3px;
    color: white;
    width: 17px;
    height: 17px;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
}

.jqvmap-zoomin {
    top: 10px;
}

.jqvmap-zoomout {
    top: 30px;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}

.ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}

.total-average {
    position: relative;
    height: 300px;
}

.widget-chat {
    position: relative;
    height: 250px;
}

.widget-todo {
    position: relative;
    height: 210px;
}

.widget-team {
    position: relative;
    height: 285px;
}

.widget-timeline {
    position: relative;
    height: 290px;
}

.widget-comments {
    position: relative;
    height: 400px;
}

.sidebar-right-inner {
    position: relative;
    height: 100%;
}

.widget-team .ps .ps__rail-x:hover,
.widget-team .ps .ps__rail-y:hover,
.widget-team .ps .ps__rail-x:focus,
.widget-team .ps .ps__rail-y:focus,
.widget-team .ps .ps__rail-x.ps--clicking,
.widget-team .ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
    opacity: 0.9;
}

.accordion__item {
    margin-bottom: 1.25rem;
}

.accordion__header {
    padding: 0.9375rem;
    border: 1px solid #eaeaea;
    cursor: pointer;
    position: relative;
    color: #333;
    font-weight: 500;
}

[data-theme-version="dark"] .accordion__header {
    color: #fff;
    border-color: #424D63;
}

.accordion__header--indicator {
    font-family: 'themify';
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translateY(-50%);
}

.accordion__header--indicator.indicator_bordered {
    display: inline-block;
    width: 25px;
    text-align: center;
    height: 25px;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    line-height: 25px;
}

.accordion__header:not(.collapsed) .accordion__header--indicator::before {
    content: "\e622";
}

.accordion__header:not(.collapsed) .accordion__header--indicator.style_two::before {
    content: "\e648";
}

.accordion__header.collapsed .accordion__header--indicator::before {
    content: "\e61a";
}

.accordion__header.collapsed .accordion__header--indicator.style_two::before {
    content: "\e64b";
}

.accordion__body--text {
    padding: 0.9375rem;
}

.accordion-bordered .accordion__body {
    border: 1px solid #eaeaea;
    border-top: none;
}

[data-theme-version="dark"] .accordion-bordered .accordion__body {
    border-color: #424D63;
}

.accordion-no-gutter .accordion__item {
    margin-bottom: 0;
}

.accordion-no-gutter .accordion__item .accordion__header.collapsed {
    border-bottom: none;
}

.accordion-no-gutter .accordion__item:last-child .accordion__header {
    border-bottom: 1px solid #eaeaea;
}

[data-theme-version="dark"] .accordion-no-gutter .accordion__item:last-child .accordion__header {
    border-color: #424D63;
}

.accordion-no-gutter.accordion__bordered .accordion__item:not(:last-child) .accordion__body {
    border-bottom: none;
}

.accordion-left-indicator .accordion__header--text {
    padding-left: 2.5rem;
}

.accordion-left-indicator .accordion__header--indicator {
    right: auto;
    left: 1.5625rem;
}

.accordion-with-icon .accordion__header--text {
    padding-left: 2.5rem;
}

.accordion-with-icon .accordion__header--icon {
    position: absolute;
    right: auto;
    left: 1.5625rem;
    font-family: 'themify';
}

.accordion-with-icon .accordion__header--icon::before {
    content: "\e645";
}

.accordion-header-bg .accordion__header {
    background-color: #f2f2f2;
}

[data-theme-version="dark"] .accordion-header-bg .accordion__header {
    background-color: #2A2C32;
    color: #fff;
}

.accordion-header-bg .accordion__header--primary {
    background-color: #593bdb;
    color: #fff;
}

.accordion-header-bg .accordion__header--info {
    background-color: #50E3C2;
    color: #fff;
}

.accordion-header-bg .accordion__header--success {
    background-color: #7ED321;
    color: #fff;
}

.accordion-header-bg.accordion-no-gutter .accordion__header {
    border-color: transparent;
}

.accordion-solid-bg .accordion__header {
    border-color: transparent;
    background-color: #f2f2f2;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion__header {
    background-color: #2A2C32;
}

.accordion-solid-bg .accordion__body {
    border-color: transparent;
    background-color: #f2f2f2;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion__body {
    background-color: #2A2C32;
}

.accordion-active-header .accordion__header:not(.collapsed) {
    background-color: #50E3C2;
    color: #fff;
}

.accordion-header-shadow .accordion__header {
    border: none;
    box-shadow: 0 0 0.9375rem -3px rgba(0, 0, 0, 0.3);
}

.accordion-rounded-stylish .accordion__header {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
}

.accordion-rounded-stylish .accordion__body {
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

.accordion-rounded .accordion__header {
    border-radius: 0.3125rem;
}

.accordion-gradient .accordion__header {
    color: #fff;
    background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
    border-color: transparent;
}

.accordion-gradient .accordion__body {
    color: #fff;
    background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
    border-color: transparent;
}

.alert-square {
    border-radius: 0px;
}

.alert-rounded {
    border-radius: 30px;
}

.alert-primary {
    background: #b4a7ef;
    border-color: #b4a7ef;
    color: #593bdb;
}

.alert-secondary {
    background: #ae95dd;
    border-color: #ae95dd;
    color: #673BB7;
}

.alert-success {
    background: #cdf1a6;
    border-color: #cdf1a6;
    color: #7ED321;
}

.alert-warning {
    background: #ffd996;
    border-color: #ffd996;
    color: #FFAA16;
}

.alert-danger {
    background: #ff9696;
    border-color: #ff9696;
    color: #FF1616;
}

.alert-info {
    background: #bef5e8;
    border-color: #bef5e8;
    color: #50E3C2;
}

.alert-dark {
    background: #dedede;
    border-color: #dedede;
    color: #454545;
}

.alert-light {
    background: #f8f9fa;
    border-color: #f8f9fa;
    color: #454545;
}

.alert-alt.alert-primary {
    border-left: 4px solid #593bdb;
}

.alert-alt.alert-secondary {
    border-left: 4px solid #673BB7;
}

.alert-alt.alert-success {
    border-left: 4px solid #7ED321;
}

.alert-alt.alert-warning {
    border-left: 4px solid #FFAA16;
}

.alert-alt.alert-danger {
    border-left: 4px solid #FF1616;
}

.alert-alt.alert-info {
    border-left: 4px solid #50E3C2;
}

.alert-alt.alert-dark {
    border-left: 4px solid #454545;
}

.alert-alt.alert-light {
    border-left: 4px solid #cbd3da;
}

.alert-alt.alert-primary.solid {
    border-left: 4px solid #2b177f !important;
}

.alert-alt.alert-secondary.solid {
    border-left: 4px solid #311c57 !important;
}

.alert-alt.alert-success.solid {
    border-left: 4px solid #3c6510 !important;
}

.alert-alt.alert-warning.solid {
    border-left: 4px solid #965f00 !important;
}

.alert-alt.alert-danger.solid {
    border-left: 4px solid #960000 !important;
}

.alert-alt.alert-info.solid {
    border-left: 4px solid #199b7e !important;
}

.alert-alt.alert-dark.solid {
    border-left: 4px solid #050505 !important;
}

.alert-alt.alert-light.solid {
    border-left: 4px solid #aeb9c5 !important;
}

.alert-dismissible.solid .close:hover {
    color: #fff;
    opacity: 1;
}

.alert.alert-primary.solid {
    background: #593bdb;
    color: #fff;
    border-color: #593bdb;
}

.alert.alert-secondary.solid {
    background: #673BB7;
    color: #fff;
    border-color: #673BB7;
}

.alert.alert-success.solid {
    background: #7ED321;
    color: #fff;
    border-color: #7ED321;
}

.alert.alert-warning.solid {
    background: #FFAA16;
    color: #fff;
    border-color: #FFAA16;
}

.alert.alert-danger.solid {
    background: #FF1616;
    color: #fff;
    border-color: #FF1616;
}

.alert.alert-info.solid {
    background: #50E3C2;
    color: #fff;
    border-color: #50E3C2;
}

.alert.alert-dark.solid {
    background: #454545;
    color: #fff;
    border-color: #454545;
}

.alert.alert-light.solid {
    background: #f8f9fa;
    color: #454545;
    border-color: #f8f9fa;
}

.alert-right-icon>span i {
    font-size: 18px;
    margin-right: 5px;
}

.alert-right-icon .close i {
    font-size: 16px;
}

.alert.alert-outline-primary {
    background: transparent;
    color: #593bdb;
    border-color: #593bdb;
}

.alert.alert-outline-secondary {
    background: transparent;
    color: #673BB7;
    border-color: #673BB7;
}

.alert.alert-outline-success {
    background: transparent;
    color: #7ED321;
    border-color: #7ED321;
}

.alert.alert-outline-info {
    background: transparent;
    color: #50E3C2;
    border-color: #50E3C2;
}

.alert.alert-outline-warning {
    background: transparent;
    color: #FFAA16;
    border-color: #FFAA16;
}

.alert.alert-outline-danger {
    background: transparent;
    color: #FF1616;
    border-color: #FF1616;
}

.alert.alert-outline-dark {
    background: transparent;
    color: #454545;
    border-color: #454545;
}

.alert.alert-outline-light {
    background: transparent;
    color: #454545;
    border-color: #f8f9fa;
}

.alert-social {
    color: #fff;
}

.alert-social .alert-social-icon {
    align-self: center;
    margin-right: 0.9375rem;
}

.alert-social .alert-social-icon i {
    font-size: 42px;
}

.alert-social.facebook {
    background-color: #3b5998;
}

.alert-social.twitter {
    background-color: #1da1f2;
}

.alert-social.linkedin {
    background-color: #007bb6;
}

.alert-social.google-plus {
    background-color: #db4439;
}

.alert-social .close:hover {
    opacity: 1 !important;
    color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
    align-self: center;
    margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
    font-size: 42px;
}

.badge {
    line-height: 1.5;
    padding: 5px 10px;
}

.badge-rounded {
    border-radius: 20px;
    padding: 5px 13px;
}

.badge-circle {
    border-radius: 100px;
    height: 25px;
    width: 25px;
    padding: 5px 8px;
}

.badge-outline-primary {
    border: 1px solid #593bdb;
    color: #593bdb;
}

.badge-outline-secondary {
    border: 1px solid #673BB7;
    color: #673BB7;
}

.badge-outline-success {
    border: 1px solid #7ED321;
    color: #7ED321;
}

.badge-outline-info {
    border: 1px solid #50E3C2;
    color: #50E3C2;
}

.badge-outline-warning {
    border: 1px solid #FFAA16;
    color: #FFAA16;
}

.badge-outline-danger {
    border: 1px solid #FF1616;
    color: #FF1616;
}

.badge-outline-light {
    border: 1px solid #eaeaea;
    color: #454545;
}

.badge-outline-dark {
    border: 1px solid #454545;
    color: #454545;
}

.badge-default {
    background: #ADB6C7;
}

.badge-success {
    background-color: #7ED321;
}

.badge-info {
    background-color: #50E3C2;
}

.badge-primary {
    background-color: #593bdb;
}

.badge-warning {
    background-color: #FFAA16;
}

.badge-danger {
    background-color: #FF1616;
}

.badge-dark {
    background-color: #454545;
}

.badge-light {
    background-color: #f8f9fa;
}

.bootstrap-label .label {
    display: inline-block;
    margin-right: 1rem;
}

.bootstrap-label .label:last-child {
    margin-right: 0;
}

.badge-demo .badge {
    margin-right: 5px;
    margin-bottom: 5px;
}

.badge-demo .badge:last-child {
    margin-right: 0;
}

.bootstrap-badge-buttons button {
    margin-right: .2rem;
    margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
    margin-right: 0;
}

.page-titles {
    padding: 0.9375rem 1.25rem;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 5px;
}

[data-theme-version="dark"] .page-titles {
    background: #323A4B;
}

@media only screen and (max-width: 767px) {
    .page-titles {
        margin-left: -15px;
        margin-right: -15px;
        padding: 15px 0;
    }
}

.page-titles .justify-content-sm-end {
    align-items: center;
}

.page-titles h4 {
    margin-bottom: 0;
    margin-top: 0;
    color: #593bdb;
    font-size: 1.25rem;
}

.page-titles h4 span {
    font-size: 0.875rem;
    font-weight: 400;
}

.page-titles .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
}

.page-titles .breadcrumb li {
    margin-top: 0;
    margin-bottom: 0;
}

.page-titles .breadcrumb li a {
    color: #828690;
}

.page-titles .breadcrumb li.active {
    color: #555555;
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
    content: "\e606";
    font-family: 'simple-line-icons';
    font-size: 10px;
}

.page-titles .breadcrumb-datepicker {
    font-size: 0.75rem;
    color: #666666;
}

.page-titles .breadcrumb-datepicker__icon {
    font-size: 0.875rem;
}

.page-titles .breadcrumb-widget .border-dark {
    border-color: #dee2e6 !important;
}

.page-titles .breadcrumb-widget h4 {
    color: #646c9a;
    font-weight: 600;
}

@media only screen and (max-width: 575px) {
    .page-titles .breadcrumb-widget {
        text-align: left !important;
        margin-bottom: 0.9375rem;
    }
}

button {
    cursor: pointer;
}

button:focus {
    outline: 0;
    box-shadow: none;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
    outline: 0 !important;
}

.btn-transparent {
    background-color: transparent;
}

.btn-xs {
    font-size: 0.75rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
}

.btn-sm,
.btn-group-sm>.btn {
    font-size: 0.875rem !important;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.58rem 1rem;
    font-size: 0.875rem !important;
}

.btn-xl {
    padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
    font-weight: 600;
}

.btn-square {
    border-radius: 0;
}

.btn-rounded {
    border-radius: 30px !important;
}

.btn-icon-right {
    border-left: 1px solid white;
    display: inline-block;
    margin: -.8rem 0 -.8rem 1.3rem;
    padding: 0.4375rem 0 0.4375rem 1.3rem;
}

.btn-icon-left {
    background: #fff;
    border-radius: 10rem;
    display: inline-block;
    margin: -.5rem 1.3rem -.5rem -1.5rem;
    padding: .5rem 1rem .6rem;
}

.toggle-dropdown::after {
    margin-left: 0.755em;
}

.social-btn-icon .btn {
    min-width: 7.5rem;
    margin-bottom: 1.5rem;
}

.social-icon .btn {
    padding: .7rem 1.4rem;
}

.btn-circle {
    height: 5rem;
    width: 5rem;
    border-radius: 50% !important;
}

.btn-circle-sm {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 1.8rem;
}

.btn-circle-md {
    width: 6rem;
    height: 6rem;
    font-size: 2.5rem;
}

.btn-circle-md i {
    font-size: 2.4rem;
}

.btn-circle-lg {
    width: 8rem;
    height: 8rem;
    font-size: 3.2rem;
}

.btn-circle-lg i {
    font-size: 3.1rem;
}

.btn-page .btn {
    min-width: 110px;
    margin-right: 4px;
    margin-bottom: 8px;
}

.size-1 {
    min-width: 160px !important;
    font-size: 24px;
    padding: 0.68rem 0.75rem;
}

.size-2 {
    font-size: 20px;
    min-width: 130px !important;
    padding: 0.57rem 0.75rem;
}

.size-3 {
    font-size: 14px;
    min-width: 110px !important;
    padding: 0.536rem 0.75rem;
}

.size-4 {
    font-size: 14px;
    min-width: 100px !important;
}

.size-5 {
    font-size: 14px;
    min-width: 90px !important;
    padding: .22rem 0.75rem;
}

.size-6 {
    font-size: 13px;
    min-width: 80px !important;
    padding: 0.097rem 0.75rem;
}

.size-7 {
    font-size: 12px;
    min-width: 60px !important;
    padding: 0.001rem 0.75rem;
}

.btn-light {
    background: #f8f9fa;
    border-color: #eaeaea;
    color: #454545;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
    background: white;
    color: #454545;
    border-color: white;
}

.btn-outline-light {
    color: #454545;
}

.btn-dark {
    background: #454545;
    border-color: #454545;
    color: #fff;
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
    background: #2c2c2c;
    color: #fff;
    border-color: #2c2c2c;
}

.btn-group.btn-rounded .btn:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.btn-group.btn-rounded .btn:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.btn-facebook {
    background: #3b5998;
    border-color: #3b5998;
    color: #fff;
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
    background: #2d4373;
    color: #fff;
    border-color: #2d4373;
}

.btn-twitter {
    background: #1da1f2;
    border-color: #1da1f2;
    color: #fff;
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
    background: #0c85d0;
    color: #fff;
    border-color: #0c85d0;
}

.btn-youtube {
    background: #FF0000;
    border-color: #FF0000;
    color: #fff;
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
    background: #cc0000;
    color: #fff;
    border-color: #cc0000;
}

.btn-instagram {
    background: #c32aa3;
    border-color: #c32aa3;
    color: #fff;
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
    background: #992180;
    color: #fff;
    border-color: #992180;
}

.btn-pinterest {
    background: #bd081c;
    border-color: #bd081c;
    color: #fff;
}

.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
    background: #8c0615;
    color: #fff;
    border-color: #8c0615;
}

.btn-linkedin {
    background: #007bb6;
    border-color: #007bb6;
    color: #fff;
}

.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
    background: #005983;
    color: #fff;
    border-color: #005983;
}

.btn-google-plus {
    background: #db4439;
    border-color: #db4439;
    color: #fff;
}

.btn-google-plus:active,
.btn-google-plus:focus,
.btn-google-plus:hover {
    background: #be2d23;
    color: #fff;
    border-color: #be2d23;
}

.btn-google {
    background: #4285f4;
    border-color: #4285f4;
    color: #fff;
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
    background: #1266f1;
    color: #fff;
    border-color: #1266f1;
}

.btn-snapchat {
    background: #fffc00;
    border-color: #fffc00;
    color: #000;
}

.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
    background: #ccca00;
    color: #000;
    border-color: #ccca00;
}

.btn-whatsapp {
    background: #25d366;
    border-color: #25d366;
    color: #fff;
}

.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
    background: #1da851;
    color: #fff;
    border-color: #1da851;
}

.btn-tumblr {
    background: #35465d;
    border-color: #35465d;
    color: #fff;
}

.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
    background: #222e3d;
    color: #fff;
    border-color: #222e3d;
}

.btn-reddit {
    background: #ff4500;
    border-color: #ff4500;
    color: #fff;
}

.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
    background: #cc3700;
    color: #fff;
    border-color: #cc3700;
}

.btn-spotify {
    background: #1ed760;
    border-color: #1ed760;
    color: #fff;
}

.btn-spotify:active,
.btn-spotify:focus,
.btn-spotify:hover {
    background: #18aa4c;
    color: #fff;
    border-color: #18aa4c;
}

.btn-yahoo {
    background: #430297;
    border-color: #430297;
    color: #fff;
}

.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover {
    background: #2d0165;
    color: #fff;
    border-color: #2d0165;
}

.btn-dribbble {
    background: #ea4c89;
    border-color: #ea4c89;
    color: #fff;
}

.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
    background: #e51e6b;
    color: #fff;
    border-color: #e51e6b;
}

.btn-skype {
    background: #00aff0;
    border-color: #00aff0;
    color: #fff;
}

.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
    background: #008abd;
    color: #fff;
    border-color: #008abd;
}

.btn-quora {
    background: #aa2200;
    border-color: #aa2200;
    color: #fff;
}

.btn-quora:active,
.btn-quora:focus,
.btn-quora:hover {
    background: #771800;
    color: #fff;
    border-color: #771800;
}

.btn-vimeo {
    background: #1ab7ea;
    border-color: #1ab7ea;
    color: #fff;
}

.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
    background: #1295bf;
    color: #fff;
    border-color: #1295bf;
}

.card {
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 0.25rem;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
}

.card-body {
    padding: 1.25rem;
}

.card-title {
    font-size: 16px;
    font-weight: 500;
    color: #454545;
    text-transform: capitalize;
    letter-spacing: 0.05em;
}

.card-title--large {
    font-size: 1.5rem;
}

.card-title--medium {
    font-size: 1rem;
}

.card-title--small {
    font-size: 0.875rem;
}

.card-header {
    border-color: #eaeaea;
    position: relative;
    background: transparent;
    padding: 1.25rem 1.25rem 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0px;
}

[data-theme-version="dark"] .card-header {
    border-color: #424D63;
}

.card-header .card-title {
    margin-bottom: 0px;
}

.card-header .subtitle {
    padding-top: 5px;
    font-size: 13px;
}

.card-footer {
    border-color: #eaeaea;
    background: transparent;
    padding: 1rem;
}

[data-theme-version="dark"] .card-footer {
    border-color: #424D63;
}

.transparent-card.card {
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;
}

.card-action>a {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    border-color: transparent;
    text-align: center;
    background: black;
    color: #fff;
    margin-right: 8px;
}

[data-theme-version="dark"] .card-action>a {
    background: #2A2C32;
}

.card-action>a:last-child {
    margin-right: 0;
}

.card-action>a:hover,
.card-action>a:focus {
    background: black;
}

[data-theme-version="dark"] .card-action>a:hover,
[data-theme-version="dark"] .card-action>a:focus {
    background: #2A2C32;
}

.card-action>a i,
.card-action>a span {
    font-size: 1rem;
}

.card-action .dropdown {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border-color: transparent;
    text-align: center;
    margin-right: 8px;
    top: -2px;
    position: relative;
    display: inline-block;
    background: black;
    color: #593bdb;
}

[data-theme-version="dark"] .card-action .dropdown {
    background: #2A2C32;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
    background: black;
}

[data-theme-version="dark"] .card-action .dropdown:hover,
[data-theme-version="dark"] .card-action .dropdown:focus {
    background: #2A2C32;
}

.card-action .dropdown .btn {
    padding: 0;
    line-height: 27px;
    color: #fff;
}

.card-action .dropdown .btn:focus {
    box-shadow: none;
}

.card-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    overflow: auto;
}

.card-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.card-loader i {
    margin: 0 auto;
    background: #4526cd;
    padding: 10px;
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
}

.rotate-refresh {
    -webkit-animation: mymove 0.8s infinite linear;
    animation: mymove 0.8s infinite linear;
    display: inline-block;
}

.card-header .date_picker {
    display: inline-block;
    padding: 8px;
    border: 1px solid #eaeaea;
    cursor: pointer;
    border-radius: .375rem;
}

@-webkit-keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.carousel-custom-prev,
.carousel-custom-next {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    text-align: center;
}

.carousel-custom-prev i,
.carousel-custom-next i {
    font-size: 1rem;
}

.carousel-custom-prev:hover,
.carousel-custom-next:hover {
    background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
    color: #fff;
}

.carousel-custom-next {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
}

.carousel-custom-prev {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
}

.carousel-caption {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
}

.carousel-caption h5 {
    color: #fff;
    font-size: 1.8rem;
}

.carousel-caption p {
    margin-bottom: 0;
}

.dropdown-toggle:focus {
    box-shadow: none !important;
}

.dropdown-outline {
    border: 0.1rem solid #593bdb;
}

.dropdown-menu {
    font-size: inherit;
}

[direction="rtl"] .dropdown-menu {
    right: auto !important;
}

.custom-dropdown {
    display: inline-block;
    margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
    border: 0px;
    min-width: 160px;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    border: 1px solid #eaeaea;
}

.custom-dropdown .dropdown-menu .dropdown-item {
    padding: 0.5rem 2rem;
    color: #828690;
    line-height: 22px;
}

.custom-dropdown .dropdown-menu .dropdown-item:hover {
    background: transparent;
    color: #593bdb;
}

.card-action .custom-dropdown {
    margin: 0px;
    background: #c6bcf3;
}

.card-action .custom-dropdown.show,
.card-action .custom-dropdown:focus,
.card-action .custom-dropdown:hover {
    background: #593bdb;
    color: #fff;
}

.card-action .custom-dropdown i {
    display: inline-block;
    padding-top: 9px;
}

.grid-col {
    padding: 1rem !important;
    background: rgba(255, 255, 255, 0.8);
}

.row.grid {
    margin-bottom: 1.5rem;
    text-align: center;
}

.row.grid .grid-col:first-child {
    text-align: left;
}

.label {
    display: inline-block;
    text-align: center;
    font-size: 0.75rem;
    padding: .2rem .8rem;
}

.label-fixed {
    width: 7.5rem;
    padding: .6rem 0;
}

.label-fixed-lg {
    width: 9.5rem;
    padding: .6rem 0;
}

.label-big {
    width: 16.8rem;
    font-size: 1.4rem;
    padding: 1.1rem 0;
}

.label-xl {
    width: 10.5rem;
    padding: 1.1rem 0;
    font-size: 1.5rem;
}

.label-lg {
    width: 9.5rem;
    padding: 1.1rem 0;
}

.label-md {
    width: 8.5rem;
    padding: 1.1rem 0;
}

.label-sm {
    width: 7.5rem;
    padding: 1.1rem 0;
}

.label-default {
    background: #ADB6C7;
}

.label-primary {
    background: #593bdb;
    color: #fff;
}

.label-secondary {
    background: #673BB7;
    color: #fff;
}

.label-info {
    background: #50E3C2;
    color: #fff;
}

.label-success {
    background: #7ED321;
    color: #fff;
}

.label-warning {
    background: #FFAA16;
    color: #fff;
}

.label-danger {
    background: #FF1616;
    color: #fff;
}

.label-light {
    background: #f8f9fa;
    color: #000;
}

.label-dark {
    background: #454545;
    color: #fff;
}

.heading-labels {
    color: #333333;
}

.heading-labels>* {
    margin-bottom: .8rem;
}

.heading-labels h1 .label {
    font-size: 18px;
    font-weight: normal;
    padding: .4rem .9rem;
}

.heading-labels h2 .label {
    font-size: 16px;
    font-weight: normal;
    padding: 0.3rem .9rem;
}

.heading-labels h3 .label {
    font-size: 14px;
    font-weight: normal;
}

.heading-labels h4 .label {
    font-size: 12px;
    font-weight: normal;
}

.heading-labels h5 .label {
    font-size: 12px;
    font-weight: normal;
}

.heading-labels h6 .label {
    font-size: 12px;
    font-weight: normal;
}

.list-group-item {
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid #eaeaea;
}

[data-theme-version="dark"] .list-group-item {
    border-color: #424D63;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #fff;
    background-color: #777;
}

.media img {
    border-radius: 3px;
}

.vertical-card__menu:hover {
    box-shadow: none;
}

.vertical-card__menu--image {
    text-align: center;
}

.vertical-card__menu--image img {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
    background: #FF1616;
}

.vertical-card__menu--status.closed:after {
    border-top: 10px solid #FF1616;
}

.vertical-card__menu--status.closed .ribbon-curve {
    border-top: 11px solid #FF1616;
    border-bottom: 10px solid #FF1616;
}

.vertical-card__menu--desc p {
    margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
    font-size: 1.25rem;
    margin: 0;
    font-weight: 700;
}

.vertical-card__menu--rating {
    font-size: 0.75rem;
}

.vertical-card__menu--title {
    font-weight: 700;
}

.vertical-card__menu--button button {
    font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
    padding: 15px 20px;
    background-color: #eaeaea;
    border-top: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .vertical-card__menu .card-footer {
        padding: 15px;
    }
}

.vertical-card__menu .card-footer span {
    color: #454545;
    margin-right: 0.3125rem;
}

.horizontal-card__menu {
    box-shadow: 0 0 7px rgba(173, 173, 173, 0.32);
    display: flex;
    flex-direction: row;
}

@media (max-width: 575.98px) {
    .horizontal-card__menu {
        display: block;
    }
}

.horizontal-card__menu .card-body {
    padding: 0;
    padding: 20px 30px;
}

.horizontal-card__menu--image {
    flex-basis: 30%;
    max-width: 30%;
}

.horizontal-card__menu--image img {
    height: 100%;
    width: 100%;
}

@media (max-width: 575.98px) {
    .horizontal-card__menu--image {
        max-width: unset;
        flex-basis: 100%;
    }
}

.horizontal-card__menu--title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
    margin-right: 0.5rem;
}

.horizontal-card__menu--price {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
}

.horizontal-card__menu--rating {
    font-size: 0.625rem;
}

.horizontal-card__menu--footer {
    margin-top: 10px;
}

.prev_price {
    text-decoration: line-through;
    font-size: 80%;
    opacity: 0.5;
}

.modal-backdrop {
    z-index: 10 !important;
}

.pagination {
    margin-bottom: 20px;
}

.pagination .page-item.page-indicator .page-link {
    padding: .65rem .8rem;
    font-size: 14px;
    border-radius: 0;
}

.pagination .page-item.page-indicator:hover .page-link {
    color: #454545;
}

.pagination .page-item .page-link {
    border-radius: 0;
    text-align: center;
    padding: 0.55rem 1rem;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.15);
    color: #454545;
    border: 1px solid #eaeaea;
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
    color: #fff;
}

.pagination .page-item .page-link:focus {
    outline: 0;
    box-shadow: none;
}

.pagination .page-item .page-link:hover {
    background: #593bdb;
    color: #fff;
    border-color: #593bdb;
}

.pagination .page-item.active .page-link {
    background-color: #593bdb;
    border-color: #593bdb;
    color: #fff;
}

.pagination .page-item .page-link {
    color: #454545;
}

.pagination .page-item:last-child .page-link {
    margin-right: 0;
}

[direction="rtl"] .pagination .page-item:first-child .page-link {
    margin-right: 0;
}

.pagination-gutter .page-item {
    margin-right: 7px;
}

.pagination-gutter .page-item .page-link {
    border-radius: 3px !important;
}

.pagination-circle .page-item {
    margin-right: 7px;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item.page-indicator .page-link {
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    border-radius: 50% !important;
    padding: 0;
}

.pagination.pagination-md .page-item .page-link {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
    font-size: 12px;
}

.pagination.pagination-sm .page-item .page-link {
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
    font-size: 10px;
}

.pagination.pagination-xs .page-item .page-link {
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
}

.popover {
    border-radius: 0;
    border: 2px solid #333333;
    max-width: 210px;
}

.popover-header {
    font-size: 0.75rem;
    background: #333333;
    color: #fff;
    border-radius: 0;
    font-weight: 300;
}

.popover-header::before {
    border-bottom: 0 !important;
}

.popover-body {
    font-size: 0.75rem;
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
    margin-right: 8px;
}

.bootstrap-popover-wrapper .bootstrap-popover {
    margin-bottom: .5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover,
.bootstrap-popover-wrapper .bootstrap-popover button:focus {
    background: #593bdb;
    color: #fff;
    box-shadow: none;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 0px;
    border-top-color: #333333;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 0px;
    border-left-color: #333333;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 0px;
    border-right-color: #333333;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 0px;
    border-bottom-color: #333333;
}

.note-popover {
    display: none;
}

.progress-bar {
    background-color: #593bdb;
}

.progress-bar-primary {
    background-color: #593bdb;
}

.progress-bar-success {
    background-color: #7ED321;
}

.progress-bar-info {
    background-color: #50E3C2;
}

.progress-bar-danger {
    background-color: #FF1616;
}

.progress-bar-warning {
    background-color: #FFAA16;
}

.progress-bar-pink {
    background-color: #e83e8c;
}

.progress {
    height: 6px;
    overflow: hidden;
}

.progress-bar {
    border-radius: 4px;
}

.progress-vertical {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 2rem;
    min-height: 17rem;
    position: relative;
}

@media (max-width: 991.98px) {
    .progress-vertical {
        margin-right: 1rem;
    }
}

.progress-vertical-bottom {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 2rem;
    min-height: 17rem;
    position: relative;
    transform: rotate(180deg);
}

@media (max-width: 991.98px) {
    .progress-vertical-bottom {
        margin-right: 1rem;
    }
}

.progress-animated {
    animation-duration: 5s;
    animation-name: myanimation;
    transition: all 5s ease 0s;
}

@keyframes myanimation {
    0% {
        width: 0;
    }
}

@keyframes myanimation {
    0% {
        width: 0;
    }
}

.ribbon {
    position: absolute;
    z-index: 1;
    text-transform: uppercase;
}

.ribbon__one {
    top: 15px;
    left: -11px;
    min-height: 20px;
    min-width: 52px;
    text-align: center;
    padding: 3px 10px;
    background: #3AB54B;
    color: #fff;
    font-size: 0.625rem;
}

.ribbon__one::after {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #239132;
    border-left: 11px solid transparent;
    left: 0;
    content: "";
    bottom: -10px;
}

.ribbon__two {
    width: 50px;
    height: 50px;
    display: inline-block;
    background: #FFAA16;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    right: 15px;
    top: 15px;
    border-radius: 3px;
}

.ribbon__three {
    left: -1.875rem;
    top: 0.875rem;
    width: 6.875rem;
    height: 1.5625rem;
    background-color: #FF1616;
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.ribbon__four {
    left: 8px;
    top: -8px;
    width: 110px;
    height: 50px;
    background-color: #593bdb;
    z-index: auto;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ribbon__four:after {
    right: -5px;
}

.ribbon__four:before {
    left: -5px;
}

.ribbon__four:after,
.ribbon__four:before {
    z-index: -1;
    background-color: #a291eb;
    top: 3px;
    transform: rotate(45deg);
    content: "";
    height: 10px;
    width: 10px;
    position: absolute;
}

.ribbon__five {
    left: -1.875rem;
    top: 0.625rem;
    width: 6.875rem;
    height: 1.875rem;
    background-color: #593bdb;
    transform: rotate(-45deg);
    font-size: 0.75rem;
    color: #fff;
    padding-bottom: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.ribbon__five::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    border-color: transparent transparent #593bdb transparent;
    left: 0.25rem;
    top: -2.8125rem;
}

.ribbon__six {
    left: 0;
    top: 1.125rem;
    width: 6.875rem;
    height: 2.375rem;
    background-color: #593bdb;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    display: flex;
    font-size: 1.25rem;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.ribbon-curve {
    position: absolute;
    top: 0;
    right: -6px;
    width: 10px;
    height: 11px;
    border-top: 11px solid #3AB54B;
    border-bottom: 10px solid #3AB54B;
    border-right: 5px solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
    background-color: #F5F5F5;
    width: 8px;
    height: 8px;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #BDBDC7;
}

.multi-steps>li.is-active:before,
.multi-steps>li.is-active~li:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 400;
}

.multi-steps>li.is-active:after,
.multi-steps>li.is-active~li:after {
    background-color: #eaeaea;
}

.multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.multi-steps>li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #593bdb;
}

@media (max-width: 575.98px) {
    .multi-steps>li {
        font-size: 0.75rem;
    }
}

.multi-steps>li:before {
    content: '\2713';
    display: block;
    margin: 0 auto 4px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    line-height: 22px;
    text-align: center;
    font-weight: bold;
    position: relative;
    z-index: 1;
    border-width: 2px;
    border-style: solid;
    border-color: #593bdb;
    border-radius: 5px;
}

@media (max-width: 575.98px) {
    .multi-steps>li:before {
        width: 25px;
        height: 25px;
        line-height: 21px;
    }
}

.multi-steps>li:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #593bdb;
    position: absolute;
    top: 12px;
    left: 50%;
}

[direction="rtl"] .multi-steps>li:after {
    left: auto;
    right: 50%;
}

@media (max-width: 575.98px) {
    .multi-steps>li:after {
        top: 12px;
    }
}

.multi-steps>li:last-child:after {
    display: none;
}

.multi-steps>li.is-active:before {
    background-color: #fff;
    border-color: #593bdb;
}

.multi-steps>li.is-active~li {
    color: #BDBDC7;
}

.multi-steps>li.is-active~li:before {
    background-color: #eaeaea;
    border-color: #eaeaea;
}

.nav-pills .nav-link {
    border-radius: 0px;
}

.default-tab .nav-link {
    background: transparent;
    border-radius: 0px;
    font-weight: 500;
}

.custom-tab-1 .nav-link {
    background: transparent;
    border-radius: 0px;
    font-weight: 500;
}

.tooltip-wrapper button:not(:last-child) {
    margin-right: 8px;
}

.tooltip-wrapper button:hover {
    background: #593bdb;
    color: #fff;
}

.tooltip-wrapper button {
    margin-bottom: .5rem;
}

.tooltip-wrapper button:focus {
    box-shadow: none;
}

.tooltip-inner {
    border-radius: 0;
    background: #333333;
    font-size: 12px;
    font-weight: 300;
    padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #333333;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #333333;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #333333;
}

.widget-timeline .timeline {
    list-style: none;
    padding: 8px 0 8px;
    position: relative;
}

.widget-timeline .timeline:before {
    top: 20px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    left: 25px;
    margin-right: -1.5px;
    background: #f5f5f5;
}

[direction="rtl"] .widget-timeline .timeline:before {
    left: auto;
    right: 25px;
    margin-right: auto;
    margin-left: -1.5px;
}

.widget-timeline .timeline>li {
    margin-bottom: 3px;
    position: relative;
}

.widget-timeline .timeline>li:before {
    content: " ";
    display: table;
}

.widget-timeline .timeline>li:after {
    content: " ";
    display: table;
    clear: both;
}

.widget-timeline .timeline>li>.timeline-panel {
    width: calc(100% - 35px);
    float: right;
    border-radius: 2px;
    padding: 5px 20px;
    position: relative;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel {
    float: left;
}

.widget-timeline .timeline>li>.timeline-badge {
    border-radius: 50%;
    height: 15px;
    left: 19px;
    position: absolute;
    top: 10px;
    width: 15px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
    left: auto;
    right: 19px;
}

.widget-timeline .timeline-body>p {
    font-size: 12px;
}

.widget-timeline .timeline-badge.primary {
    background-color: #593bdb !important;
}

.widget-timeline .timeline-badge.success {
    background-color: #7ED321 !important;
}

.widget-timeline .timeline-badge.warning {
    background-color: #FFAA16 !important;
}

.widget-timeline .timeline-badge.danger {
    background-color: #FF1616 !important;
}

.widget-timeline .timeline-badge.info {
    background-color: #50E3C2 !important;
}

.widget-timeline .timeline-badge.dark {
    background-color: #454545 !important;
}

#chart_widget_4 {
    height: 255px !important;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
    stroke: #fff;
}

#chart_widget_5 .ct-line {
    stroke-width: 2px;
}

#chart_widget_5 .ct-point {
    stroke-width: 5px;
}

#chart_widget_5 .ct-series-a .ct-area {
    fill: #fff;
}

#chart_widget_5 .ct-area {
    fill-opacity: .8;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
    stroke: #00abc5;
}

#chart_widget_6 .ct-line {
    stroke-width: 2px;
}

#chart_widget_6 .ct-point {
    stroke-width: 5px;
}

#chart_widget_6 .ct-series-a .ct-area {
    fill: #00abc5;
}

#chart_widget_6 .ct-area {
    fill-opacity: .5;
}

#chart_widget_8 {
    height: 255px;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
    stroke: #00abc5;
}

#chart_widget_8 .ct-line {
    stroke-width: 2px;
}

#chart_widget_8 .ct-point {
    stroke-width: 5px;
}

#chart_widget_8 .ct-series-a .ct-area {
    fill: #00abc5;
}

#chart_widget_8 .ct-area {
    fill-opacity: .5;
}

#chart_widget_9,
#chart_widget_10 {
    height: 250px !important;
}

#chart_widget_11 .ct-slice-donut,
#chart_widget_12 .ct-slice-donut,
#chart_widget_13 .ct-slice-donut {
    stroke-width: 25px !important;
}

#chart_widget_11 {
    height: 270px !important;
}

#chart_widget_17 {
    height: 150px !important;
}

.chart_widget_tab_one .nav-link {
    border: 1px solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
    background-color: #593bdb;
    border: 1px solid #593bdb;
    color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #593bdb;
}

.chart_widget_tab_one .nav-link:hover {
    border: 1px solid #ddd;
}

[data-theme-version="dark"] .ccc-widget>div {
    background: #323A4B !important;
    border-color: #424D63 !important;
}

.social-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    background: rgba(88, 115, 254, 0.04);
    margin-bottom: 0.5rem;
    font-size: 20px;
}

.social-icon i {
    color: #fff;
}

.social-icon.youtube {
    background: #FF0000;
}

.social-icon.facebook {
    background: #3b5998;
}

.social-icon.twitter {
    background: #1da1f2;
}

.social-icon2 a {
    border: 0.1rem solid #593bdb;
    display: block;
    padding: 1rem;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
    border-radius: .3rem;
    font-size: 1.6rem;
}

.social-icon2 i {
    font-size: 12px;
    color: #593bdb;
}

.social-icon3 ul li {
    display: inline-block;
}

.social-icon3 ul li a {
    display: block;
}

.social-icon3 ul li a i {
    font-size: 1.4rem;
    padding: 1rem .7rem;
    color: #464a53;
}

.social-icon3 ul li a:hover i {
    color: #593bdb;
}

.social-icons-muted ul li a i {
    color: #666666;
}

.social-links a {
    padding: .5rem;
}

.widget-stat .media {
    padding: 0px 0;
    align-items: center;
}

.widget-stat .media>span {
    background: #F5F7FB;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    padding: 10px 12px;
    border: 1px solid #E6E8ED;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464a53;
}

.widget-stat .media .media-body p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
}

[data-theme-version="dark"] .widget-stat .media .media-body p {
    color: #c4c9d5;
}

.widget-stat .media .media-body h4 {
    font-size: 24px;
    color: #593bdb !important;
    display: inline-block;
}

.widget-stat .media .media-body span {
    margin-left: 10px;
    border-radius: 30px;
    font-weight: 400;
    font-size: 12px;
    padding: 3px 10px;
}

.widget-stat .media .media-body span.badge-success {
    background: #96FFAD;
    color: #107125;
}

.widget-stat .media .media-body span.badge-danger {
    background: #FFE1E1;
    color: #c52424;
}

.widget-trend-meter .card {
    background: #FFE7DB;
}

.widget-trend-meter .card .card-body p {
    color: #f87533;
}

.widget-content h2 {
    color: #593bdb !important;
}


/*    Widget One
---------------------------*/

.stat-widget-one .stat-icon {
    vertical-align: top;
}

.stat-widget-one .stat-icon i {
    font-size: 30px;
    border-width: 3px;
    border-style: solid;
    border-radius: 100px;
    padding: 15px;
    font-weight: 900;
    display: inline-block;
}

.stat-widget-one .stat-content {
    margin-left: 30px;
    margin-top: 7px;
}

.stat-widget-one .stat-text {
    font-size: 14px;
    color: #868e96;
}

.stat-widget-one .stat-digit {
    font-size: 24px;
    color: #373757;
}

.stat-widget-two {
    text-align: center;
}

.stat-widget-two .stat-digit {
    font-size: 1.75rem;
    font-weight: 500;
    color: #373757;
}

.stat-widget-two .stat-digit i {
    font-size: 18px;
    margin-right: 5px;
}

.stat-widget-two .stat-text {
    font-size: 16px;
    margin-bottom: 5px;
    color: #868e96;
}

.stat-widget-two .progress {
    height: 8px;
    margin-bottom: 0;
    margin-top: 20px;
    box-shadow: none;
}

.stat-widget-two .progress-bar {
    box-shadow: none;
}

.widget-card-circle {
    position: relative;
    display: inline-block;
}

.widget-card-circle i {
    font-size: 30px;
    left: 0;
    line-height: 97px;
    right: 0;
    text-align: center;
    position: absolute;
}

.widget-line-list li {
    display: inline-block;
    font-size: 1.2em;
    line-height: 27px;
    padding: 5px 20px 0 15px;
}

.widget-line-list li span {
    font-size: 14px;
}

.tdl-holder {
    margin: 0 auto;
}

.tdl-holder ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tdl-holder li {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    list-style: none none;
    margin: 0;
    padding: 0;
}

.tdl-holder li span {
    margin-left: 35px;
    font-size: 1rem;
    vertical-align: middle;
    transition: all 0.2s linear;
}

[direction="rtl"] .tdl-holder li span {
    margin-left: auto;
    margin-right: 35px;
}

.tdl-holder label {
    cursor: pointer;
    display: block;
    line-height: 50px;
    padding-left: 1.5rem;
    position: relative;
    margin: 0 !important;
}

[direction="rtl"] .tdl-holder label {
    padding-left: 0;
    padding-right: 1.5rem;
}

.tdl-holder label:hover {
    background-color: #eef1f6;
    color: #454545;
}

.tdl-holder label:hover a {
    color: #FF1616;
}

.tdl-holder label a {
    color: #fff;
    display: inline-block;
    line-height: normal;
    height: 100%;
    text-align: center;
    text-decoration: none;
    width: 50px;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all 0.2s linear;
    padding: 18px 0px;
    font-size: 18px;
    position: absolute;
    right: 0;
}

[direction="rtl"] .tdl-holder label a {
    right: auto;
    left: 0;
}

.tdl-holder input[type=checkbox] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.tdl-holder input[type=checkbox]+i {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    display: block;
    height: 20px;
    position: absolute;
    top: 15px;
    width: 20px;
    z-index: 1;
    border-radius: 00px;
}

.tdl-holder input[type=checkbox]:checked+i {
    background: #593bdb;
    border-color: transparent;
}

.tdl-holder input[type=checkbox]:checked+i::after {
    content: "\f00c";
    font-family: 'fontAwesome';
    display: block;
    left: 1px;
    position: absolute;
    top: -15px;
    z-index: 2;
    color: #fff;
}

.tdl-holder input[type=checkbox]:checked~span {
    text-decoration: line-through;
    position: relative;
}

.tdl-holder input[type=text] {
    background-color: #fff;
    height: 50px;
    margin-top: 29px;
    border-radius: 3px;
}

.tdl-holder input[type=text]::placeholder {
    color: #454545;
}

.table th,
.table td {
    border-color: #eaeaea;
}

[data-theme-version="dark"] .table th,
[data-theme-version="dark"] .table td {
    border-color: #424D63;
}

.table thead th {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #eaeaea;
}

[data-theme-version="dark"] .table thead th {
    border-color: #424D63;
}

.table tbody tr td {
    vertical-align: middle;
    border-color: #eaeaea;
}

[data-theme-version="dark"] .table tbody tr td {
    border-color: #424D63;
}

.table:not(.table-bordered) thead th {
    border-top: none;
}

.table .thead-primary th {
    background-color: #593bdb;
    color: #fff;
}

.table.primary-table-bordered {
    border: 1px solid #989ca0;
}

.table.primary-table-bg-hover thead th {
    background-color: #4827d5;
    color: #fff;
    border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
    background-color: #593bdb;
    color: #fff;
    transition: all .1s ease;
}

.table.primary-table-bg-hover tbody tr:hover {
    background-color: #6b51df;
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td,
.table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #4827d5;
}

.table-responsive-tiny {
    min-width: 18.75rem;
}

.table-responsive-sm {
    min-width: 30rem !important;
}

.table-responsive-md {
    min-width: 50.9375rem !important;
}

.table-responsive-lg {
    min-width: 60.9375rem !important;
}

.table-responsive-xl {
    min-width: 70.9375rem !important;
}

.bootgrid-header {
    padding: 0 !important;
    margin: 0;
}

@media only screen and (max-width: 575px) {
    .bootgrid-header .actionBar {
        padding: 0;
    }
    .bootgrid-header .search {
        margin: 0 0 10px 0;
    }
}

table#example {
    padding: 3rem 0 4rem 0;
}

#example2_wrapper .dataTables_scrollBody {
    max-height: 33.25rem !important;
}

#employees,
#custommers {
    padding: .5rem 0 1rem 0;
}

table.dataTable thead th,
table.dataTable thead td {
    border-bottom: 0;
    border-top: 0;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
    border-top: 0;
}

table.dataTable tbody tr,
table.dataTable tbody td {
    background: transparent !important;
}

table.dataTable thead th {
    color: #454545;
    font-weight: 600;
}

[data-theme-version="dark"] table.dataTable thead th {
    color: #fff;
}

table.dataTable tbody td {
    padding: 15px 18px;
}

table.dataTable tr.selected {
    color: #593bdb;
}

table.dataTable tfoot th {
    color: #454545;
    font-weight: 600;
}

[data-theme-version="dark"] table.dataTable tfoot th {
    color: #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    border-radius: 50%;
    color: #593bdb !important;
    background: rgba(89, 59, 219, 0.1);
    border: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border-radius: 50%;
    color: #593bdb !important;
    background: rgba(89, 59, 219, 0.1);
    border: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    border: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover {
    background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
    background: transparent;
}

.dataTables_wrapper input[type="search"],
.dataTables_wrapper input[type="text"],
.dataTables_wrapper select {
    border: 1px solid #e2e2e2;
    padding: .3rem 0.5rem;
    color: #715d5d;
    border-radius: 5px;
}

[data-theme-version="dark"] .dataTables_wrapper input[type="search"],
[data-theme-version="dark"] .dataTables_wrapper input[type="text"],
[data-theme-version="dark"] .dataTables_wrapper select {
    background: #2A2C32;
    border-color: #424D63;
    color: #fff;
}

table.dataTable.no-footer {
    border-bottom: 0;
}

.dataTables_scroll {
    padding: 2.5rem 0;
}

.dataTables_scrollFoot {
    padding-top: 1rem;
}

@media (max-width: 991.98px) {
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter {
        text-align: left;
    }
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
    border-color: #eaeaea;
}

[data-theme-version="dark"] table.dataTable.row-border tbody th,
[data-theme-version="dark"] table.dataTable.row-border tbody td,
[data-theme-version="dark"] table.dataTable.display tbody th,
[data-theme-version="dark"] table.dataTable.display tbody td {
    border-color: #424D63;
}

.fooicon {
    font-size: 1.25rem;
    color: #454545;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
    color: #454545;
}

.jsgrid-table>tbody>tr>td {
    padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input,
.jsgrid-table .jsgrid-insert-row select,
.jsgrid-table .jsgrid-edit-row input,
.jsgrid-table .jsgrid-edit-row select {
    border: 1px solid #DDDFE1;
}

.jsgrid .jsgrid-button {
    border: 0 !important;
    margin-left: 10px;
}

.authincation-content {
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
}

[data-theme-version="dark"] .authincation-content {
    background: #323A4B;
    box-shadow: none;
}

.welcome-content {
    background: url("../images/1.jpg");
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 75px 50px;
    position: relative;
    z-index: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.welcome-content::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #593bdb;
    opacity: 0.75;
    z-index: -1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.welcome-content .welcome-title {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

.welcome-content p {
    color: #fff;
}

.welcome-content .brand-logo a {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: 700;
    color: #fff;
    font-size: 20px;
}

.welcome-content .brand-logo a img {
    width: 100px;
}

.welcome-content .intro-social {
    position: absolute;
    bottom: 75px;
}

.welcome-content .intro-social ul {
    margin-bottom: 0;
}

.welcome-content .intro-social ul li {
    display: inline-block;
}

.welcome-content .intro-social ul li a {
    color: #fff;
    font-size: 14px;
    padding: 0px 7px;
}

.auth-form {
    padding: 50px 50px;
}

.auth-form .btn {
    height: 50px;
    font-weight: 700;
}

.auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px;
}

.pricing_table_content {
    background: #fff;
    text-align: center;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    padding: 25px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.pricing_table_content .package {
    font-weight: 700;
    font-size: 18px;
}

.pricing_table_content .price {
    font-weight: 700;
    font-size: 50px;
    line-height: 100px;
    color: #454545;
}

.pricing_table_content hr {
    margin: 0;
}

.pricing_table_content .price_list {
    padding: 30px 0;
    text-align: left;
    max-width: 175px;
    margin: 0 auto;
}

.pricing_table_content .price_list li {
    color: #909093;
    font-size: 14px;
    line-height: 25px;
    padding: 7px 0;
}

.pricing_table_content .price_list li i {
    margin-right: 15px;
}

.pricing_table_content .price-btn {
    padding: 15px 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 25px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.pricing_table_content:focus,
.pricing_table_content:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
}

.pricing_table_content:focus .price-btn,
.pricing_table_content:hover .price-btn {
    color: #FF1616;
}

.page-timeline .timeline {
    list-style: none;
    padding: 40px 0;
    position: relative;
}

.page-timeline .timeline-workplan.page-timeline .timeline {
    padding-bottom: 0;
    margin-bottom: 0;
}

.page-timeline .timeline-workplan.page-timeline .timeline.timeline>li>.timeline-badge {
    top: 4.5rem;
}

.page-timeline .timeline-workplan.page-timeline .timeline>li {
    margin-bottom: 2.6rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
    .page-timeline .timeline-workplan.page-timeline .timeline li>p {
        max-width: 8rem;
    }
}

@media only screen and (max-width: 1199px) {
    .page-timeline .timeline-workplan.page-timeline .timeline li>p {
        max-width: 7rem;
    }
}

@media only screen and (max-width: 991px) {
    .page-timeline .timeline-workplan.page-timeline .timeline li>p {
        max-width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .page-timeline .timeline-workplan.page-timeline .timeline li>p {
        max-width: 7rem;
    }
}

.page-timeline .timeline-workplan.page-timeline .timeline::before {
    left: 20%;
    top: 6rem;
}

[direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline::before {
    right: 22%;
    left: auto;
}

@media only screen and (max-width: 575px) {
    .page-timeline .timeline-workplan.page-timeline .timeline::before {
        left: 22%;
    }
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.4%;
    height: 0.9rem;
    width: 0.9rem;
    background-color: #593bdb;
}

[direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    right: 19.2%;
    left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
    .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 22.4%;
    }
}

@media only screen and (max-width: 1199px) {
    .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 22.4%;
    }
}

@media only screen and (max-width: 991px) {
    .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 21.7%;
    }
}

@media only screen and (max-width: 767px) {
    .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 19.5%;
    }
}

@media only screen and (max-width: 575px) {
    .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 21.4%;
    }
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge::after {
    position: absolute;
    width: 1.9rem;
    height: 1.9rem;
    background-color: rgba(89, 59, 219, 0.4);
    content: "";
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-panel {
    width: 70%;
}

.page-timeline .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background-color: #ECEFF2;
    left: 50%;
    margin-left: -1.5px;
}

.page-timeline .timeline>li {
    margin-bottom: 20px;
    position: relative;
}

.page-timeline .timeline>li:after,
.page-timeline .timeline>li:before {
    content: " ";
    display: table;
}

.page-timeline .timeline>li:after {
    clear: both;
}

.page-timeline .timeline>li:after,
.page-timeline .timeline>li:before {
    content: " ";
    display: table;
}

.page-timeline .timeline>li:after {
    clear: both;
}

.page-timeline .timeline>li>.timeline-panel {
    width: 46%;
    float: left;
    border-radius: 2px;
    position: relative;
}

.page-timeline .timeline>li>.timeline-badge {
    background-color: #F7FAFC;
    border: 1px solid #DDDFE1;
    border-radius: 50%;
    color: #454545;
    height: 40px;
    left: 50%;
    line-height: 40px;
    margin-left: -13px;
    position: absolute;
    text-align: center;
    top: 30px;
    width: 40px;
    z-index: 1;
    transform: translate(-25%, -3rem);
}

@media (min-width: 576px) {
    .page-timeline .timeline>li>.timeline-badge {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.page-timeline .timeline-title {
    margin-top: 0;
    color: inherit;
}

.page-timeline .event_time {
    font-size: 14px;
    font-weight: 600;
}

.page-timeline .event_vanue {
    font-size: 14px;
    margin: 5px 0;
    font-weight: 600;
}

.page-timeline .timeline_img {
    height: 100px;
    width: 100px;
}

.page-timeline .timeline-body>p,
.page-timeline .timeline-body>ul {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .page-timeline ul.timeline:before {
        left: 40px;
    }
    .page-timeline ul.timeline>li>.timeline-panel {
        width: calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }
    .page-timeline ul.timeline>li>.timeline-badge {
        left: 28px;
        margin-left: 0;
        top: 16px;
    }
    .page-timeline ul.timeline>li>.timeline-panel {
        float: right;
    }
    .page-timeline ul.timeline>li>.timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }
    .page-timeline ul.timeline>li>.timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
    .page-timeline .timeline_img {
        height: 30%;
        width: 30%;
    }
}

.page-timeline .timeline-timestamp {
    text-align: center;
}

.page-timeline .timeline-timestamp .badge {
    padding: .8rem 2rem;
    border-radius: 50px;
    font-size: 0.8125rem;
}

.current-progressbar {
    margin-top: 3px;
}

.current-progressbar .progress {
    height: 15px;
    margin: 0;
    box-shadow: none;
}

.current-progressbar .progress-bar {
    box-shadow: 0;
    line-height: 14px;
    font-size: 11px;
    box-shadow: none;
}

.testimonial-widget-one .testimonial-content {
    text-align: right;
}

.testimonial-widget-one .testimonial-text {
    margin-bottom: 15px;
}

.testimonial-widget-one .testimonial-author-img {
    height: 50px !important;
    width: 50px !important;
}

.vmap {
    width: 100%;
    height: 400px;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
    opacity: 0.9;
}

.cpu-load {
    width: 100%;
    height: 150px;
    font-size: 14px;
    line-height: 1.2em;
}

span#counter {
    font-size: 30px;
    font-weight: 700;
    color: #593bdb;
}

.social-graph-wrapper {
    text-align: center;
    padding: 20px;
    position: relative;
    color: #fff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
    background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
    background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
    background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
    background-color: #db4439;
}

.social-graph-wrapper .s-icon {
    font-size: 24px;
    position: relative;
    padding: 0 10px;
}

.ct-bar-chart {
    height: 250px;
}

.ct-pie-chart {
    height: 328px;
}

.bg-facebook {
    background: #3b5998;
    fill: #3b5998;
}

.bg-twitter {
    background: #1da1f2;
    fill: #1da1f2;
}

.bg-youtube {
    background: #cd201f;
    fill: #cd201f;
}

.bg-google-plus {
    background: #dd4b39;
    fill: #dd4b39;
}

.bg-linkedin {
    background: #007bb6;
}

.recent-comment .media {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
    padding-top: 10px;
}

.recent-comment .media:last-child {
    border: 0px;
}

.recent-comment .media-left {
    padding-right: 25px;
}

.recent-comment .media-left img {
    border-radius: 100px;
    width: 40px;
}

.recent-comment .media-body {
    position: relative;
}

.recent-comment .media-body h4 {
    font-size: 16px;
    margin-bottom: 5px;
}

.recent-comment .media-body p {
    margin-bottom: 5px;
    line-height: 16px;
    color: #868e96;
}

.recent-comment .comment-date {
    position: absolute;
    right: 0;
    top: 0;
    color: #373757;
    font-size: 12px;
}

.comment-action {
    float: left;
}

.comment-action .badge {
    text-transform: uppercase;
}

.comment-action i {
    padding: 0 5px;
}


/*
===================================
    Product detail
===================================*/

.produtct-detail-tag {
    display: inline-block;
}

.produtct-detail-tag a {
    font-style: 13px;
    color: #454545;
}

.product-detail-content .item-tag {
    background: #828690;
    border-radius: 6px;
    display: inline-block;
    font-size: 12px;
    margin-right: 4px;
    padding: 2px 12px;
    color: #fff;
}

.filtaring-area h4 {
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 400;
    text-transform: lowercase;
}

.plus-minus-input .input-icon {
    font-size: 13px;
    color: #454545;
}

.plus-minus-input {
    display: flex;
    width: 100px;
}

.plus-minus-input .custom-btn {
    border-radius: 0;
    height: 40px;
    padding: 12px 8px;
    background: #fff;
    border: 1px solid #eaeaea;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
    border: 1px solid #eaeaea;
}

.share-view {
    display: inline-block;
}

.share-view ul li {
    display: inline-block;
}

.share-view .share-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 40px;
    font-style: 16px;
    color: #eaeaea;
    margin-right: 8px;
}


/*--------tab----------*/

.veritical-line {
    padding: 20px 30px;
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.veritical-line:before {
    background: #eaeaea;
    bottom: 0;
    content: "";
    height: 100%;
    left: -1px;
    max-height: 40%;
    position: absolute;
    width: 1px;
}

.tab-content-text p {
    color: #454545;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 25px;
}

.tab-item-list li a {
    background: #fff;
    border-top: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    color: #454545;
    display: block;
    font-size: 16px;
    padding: 16px;
    text-transform: uppercase;
}

.tab-item-list li a:hover,
.tab-item-list li a:focus {
    background: #fff;
    color: #454545;
    border-right: 0px;
}

.tab-item-list li:last-child {
    border-bottom: 1px solid #eaeaea;
}

.tab-list li {
    margin-bottom: 7px;
    font-size: 13px;
}

.tab-list li i {
    font-size: 13px;
    margin-right: 14px;
}

.slide-item-list {
    text-align: center;
}

.slide-item-list li {
    display: inline-block;
}

.slide-item-list li a {
    display: inline-block;
    padding-left: 0;
    padding-right: 10px;
    padding-top: 10px;
    background: transparent;
    padding-bottom: 0px;
}

.slide-item-list li a:hover,
.slide-item-list li a:focus {
    background: transparent;
}

.slide-item-list li a img {
    width: 60px;
}

.product-detail-text {
    padding-top: 28px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 70px;
}

.star-rating .product-review {
    font-style: 13px;
    color: #454545;
    font-weight: 400;
    text-decoration: underline !important;
}

.product-detail .tab-content img {
    display: inline-block;
    width: 100%;
}

.popular-tag ul {
    margin: 0px;
    padding: 0px;
}

.popular-tag ul li {
    display: inline-block;
    padding: 8px 15px;
    background: #f8f8f8;
    font-size: 13px;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 10px;
}

.size-filter ul li {
    display: inline-block;
    border: 1px solid #c8c8c8;
    height: 35px;
    line-height: 33px;
    text-align: center;
    width: 35px;
    margin-right: 7px;
    margin-bottom: 10px;
    color: #c8c8c8;
    text-transform: uppercase;
}

.intro {
    border: 1px solid red;
    color: #1d1d1d;
}

#listResults .slider {
    margin: 25px 0;
}

#listResults .slider-box {
    width: 90%;
    margin: 25px auto;
}

#listResults input {
    width: 10%;
}

#listResults label {
    border: none;
    display: inline-block;
    margin-right: -4px;
    vertical-align: top;
    width: 30%;
}

.plus-minus-input .input-icon {
    font-size: 13px;
    color: #aaaaaa;
}

.plus-minus-input .custom-btn {
    border-radius: 0;
    height: 40px;
    padding: 12px 8px;
    background: #ffffff;
    border: 1px solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover,
.plus-minus-input .custom-btn:focus,
.plus-minus-input .custom-btn.active {
    box-shadow: none;
    outline: none;
}

.plus-minus-input .form-control {
    height: 40px;
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    border-left: 0px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    border-left: 0px solid #c8c8c8;
    border-right: 0px solid #c8c8c8;
}


/*
===================================
    list view
===================================*/

.new-arrival-content .item {
    font-size: 12px;
    color: #454545;
}

.new-arrival-content h4 {
    font-size: 16px;
    color: #828690;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
}

.new-arrival-content .price {
    font-weight: 700;
    color: #593bdb;
    font-size: 22px;
    margin-bottom: 20px;
}

.new-arrival-content p {
    font-size: 14px;
    color: #828690;
    margin-bottom: 6px;
    line-height: 24px;
}

.new-arrival-content .text-content {
    margin-top: 18px;
}

.success-icon {
    color: #7ED321;
    font-size: 16px;
}

.comment-review {
    position: absolute;
    right: 0;
    top: 25px;
}

.comment-review .client-review {
    color: #828690;
    padding-right: 20px;
    text-decoration: underline !important;
    font-size: 14px;
}

.comment-review .span {
    color: #828690;
    font-size: 14px;
}

.star-rating li {
    display: inline-block;
}

.star-rating li i {
    color: gold;
}

.rtl {
    text-align: right;
    direction: rtl;
}

.rtl .nav {
    padding-right: 0;
}

.rtl .navbar-nav .nav-item {
    float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
    margin-right: 1rem;
    margin-left: inherit;
}

.rtl th {
    text-align: right;
}

.rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem;
}

.rtl .dropdown-menu {
    right: 0;
    text-align: right;
}

.rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.25rem 0.25rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
    right: 0;
    left: inherit;
}

.rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat left 0.75rem center;
    background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
    border-radius: 0 0.25rem 0.25rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
    border-radius: 0 0.25rem 0.25rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
    border-radius: 0;
}

.rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem;
}

.rtl .custom-control-indicator {
    right: 0;
    left: inherit;
}

.rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit;
}

.rtl .list-group {
    padding-right: 0;
    padding-left: 40px;
}

.rtl .close {
    float: left;
}

.rtl .modal-header .close {
    margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
    margin-right: .25rem;
}

.rtl .alert-dismissible .close {
    right: inherit;
    left: 0;
}

.rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0;
}

.rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit;
}

.rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit;
}

.rtl .offset-1 {
    margin-right: 8.33333%;
    margin-left: 0;
}

.rtl .offset-2 {
    margin-right: 16.66667%;
    margin-left: 0;
}

.rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0;
}

.rtl .offset-4 {
    margin-right: 33.33333%;
    margin-left: 0;
}

.rtl .offset-5 {
    margin-right: 41.66667%;
    margin-left: 0;
}

.rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0;
}

.rtl .offset-7 {
    margin-right: 58.33333%;
    margin-left: 0;
}

.rtl .offset-8 {
    margin-right: 66.66667%;
    margin-left: 0;
}

.rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0;
}

.rtl .offset-10 {
    margin-right: 83.33333%;
    margin-left: 0;
}

.rtl .offset-11 {
    margin-right: 91.66667%;
    margin-left: 0;
}

@media (min-width: 576px) {
    .rtl .offset-sm-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-sm-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-sm-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-sm-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-sm-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-sm-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-sm-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-sm-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-sm-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .rtl .offset-md-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-md-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-md-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-md-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-md-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-md-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-md-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-md-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-md-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .offset-lg-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-lg-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-lg-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-lg-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-lg-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-lg-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-lg-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-lg-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-lg-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .offset-xl-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-xl-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-xl-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-xl-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-xl-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-xl-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-xl-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-xl-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-xl-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

@media (min-width: 1440) {
    .rtl .offset-xxl-0 {
        margin-right: 0;
        margin-left: 0;
    }
    .rtl .offset-xxl-1 {
        margin-right: 8.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-2 {
        margin-right: 16.66667%;
        margin-left: 0;
    }
    .rtl .offset-xxl-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .rtl .offset-xxl-4 {
        margin-right: 33.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-5 {
        margin-right: 41.66667%;
        margin-left: 0;
    }
    .rtl .offset-xxl-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .rtl .offset-xxl-7 {
        margin-right: 58.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-8 {
        margin-right: 66.66667%;
        margin-left: 0;
    }
    .rtl .offset-xxl-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .rtl .offset-xxl-10 {
        margin-right: 83.33333%;
        margin-left: 0;
    }
    .rtl .offset-xxl-11 {
        margin-right: 91.66667%;
        margin-left: 0;
    }
}

.rtl .mr-0,
.rtl .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
}

.rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-sm-1,
    .rtl .mx-sm-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-sm-1,
    .rtl .mx-sm-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-sm-2,
    .rtl .mx-sm-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-sm-2,
    .rtl .mx-sm-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-sm-3,
    .rtl .mx-sm-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-sm-3,
    .rtl .mx-sm-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-sm-4,
    .rtl .mx-sm-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-sm-4,
    .rtl .mx-sm-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-sm-1,
    .rtl .px-sm-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-sm-1,
    .rtl .px-sm-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-sm-2,
    .rtl .px-sm-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-sm-2,
    .rtl .px-sm-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-sm-3,
    .rtl .px-sm-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-sm-3,
    .rtl .px-sm-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-sm-4,
    .rtl .px-sm-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-sm-4,
    .rtl .px-sm-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-sm-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-sm-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-md-1,
    .rtl .mx-md-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-md-1,
    .rtl .mx-md-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-md-2,
    .rtl .mx-md-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-md-2,
    .rtl .mx-md-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-md-3,
    .rtl .mx-md-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-md-3,
    .rtl .mx-md-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-md-4,
    .rtl .mx-md-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-md-4,
    .rtl .mx-md-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-md-1,
    .rtl .px-md-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-md-1,
    .rtl .px-md-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-md-2,
    .rtl .px-md-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-md-2,
    .rtl .px-md-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-md-3,
    .rtl .px-md-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-md-3,
    .rtl .px-md-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-md-4,
    .rtl .px-md-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-md-4,
    .rtl .px-md-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-md-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-md-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-lg-1,
    .rtl .mx-lg-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-lg-1,
    .rtl .mx-lg-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-lg-2,
    .rtl .mx-lg-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-lg-2,
    .rtl .mx-lg-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-lg-3,
    .rtl .mx-lg-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-lg-3,
    .rtl .mx-lg-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-lg-4,
    .rtl .mx-lg-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-lg-4,
    .rtl .mx-lg-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-lg-1,
    .rtl .px-lg-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-lg-1,
    .rtl .px-lg-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-lg-2,
    .rtl .px-lg-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-lg-2,
    .rtl .px-lg-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-lg-3,
    .rtl .px-lg-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-lg-3,
    .rtl .px-lg-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-lg-4,
    .rtl .px-lg-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-lg-4,
    .rtl .px-lg-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-lg-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-lg-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-xl-1,
    .rtl .mx-xl-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-xl-1,
    .rtl .mx-xl-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-xl-2,
    .rtl .mx-xl-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-xl-2,
    .rtl .mx-xl-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-xl-3,
    .rtl .mx-xl-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-xl-3,
    .rtl .mx-xl-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-xl-4,
    .rtl .mx-xl-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-xl-4,
    .rtl .mx-xl-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-xl-1,
    .rtl .px-xl-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-xl-1,
    .rtl .px-xl-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-xl-2,
    .rtl .px-xl-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-xl-2,
    .rtl .px-xl-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-xl-3,
    .rtl .px-xl-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-xl-3,
    .rtl .px-xl-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-xl-4,
    .rtl .px-xl-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-xl-4,
    .rtl .px-xl-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-xl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-xl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1440) {
    .rtl .mr-xxl-0,
    .rtl .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rtl .ml-xxl-0,
    .rtl .mx-xxl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .rtl .mr-xxl-1,
    .rtl .mx-xxl-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    .rtl .ml-xxl-1,
    .rtl .mx-xxl-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    .rtl .mr-xxl-2,
    .rtl .mx-xxl-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    .rtl .ml-xxl-2,
    .rtl .mx-xxl-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
    .rtl .mr-xxl-3,
    .rtl .mx-xxl-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .rtl .ml-xxl-3,
    .rtl .mx-xxl-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    .rtl .mr-xxl-4,
    .rtl .mx-xxl-4 {
        margin-right: 0 !important;
        margin-left: 1.5rem !important;
    }
    .rtl .ml-xxl-4,
    .rtl .mx-xxl-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }
    .rtl .mr-xxl-5,
    .rtl .mx-xxl-5 {
        margin-right: 0 !important;
        margin-left: 3rem !important;
    }
    .rtl .ml-xxl-5,
    .rtl .mx-xxl-5 {
        margin-left: 0 !important;
        margin-right: 3rem !important;
    }
    .rtl .pr-xxl-0,
    .rtl .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .rtl .pl-xxl-0,
    .rtl .px-xxl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rtl .pr-xxl-1,
    .rtl .px-xxl-1 {
        padding-right: 0 !important;
        padding-left: 0.25rem !important;
    }
    .rtl .pl-xxl-1,
    .rtl .px-xxl-1 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .rtl .pr-xxl-2,
    .rtl .px-xxl-2 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
    }
    .rtl .pl-xxl-2,
    .rtl .px-xxl-2 {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
    }
    .rtl .pr-xxl-3,
    .rtl .px-xxl-3 {
        padding-right: 0 !important;
        padding-left: 1rem !important;
    }
    .rtl .pl-xxl-3,
    .rtl .px-xxl-3 {
        padding-left: 0 !important;
        padding-right: 1rem !important;
    }
    .rtl .pr-xxl-4,
    .rtl .px-xxl-4 {
        padding-right: 0 !important;
        padding-left: 1.5rem !important;
    }
    .rtl .pl-xxl-4,
    .rtl .px-xxl-4 {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }
    .rtl .pr-xxl-5,
    .rtl .px-xxl-5 {
        padding-right: 0 !important;
        padding-left: 3rem !important;
    }
    .rtl .pl-xxl-5,
    .rtl .px-xxl-5 {
        padding-left: 0 !important;
        padding-right: 3rem !important;
    }
    .rtl .mr-xxl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .ml-xxl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

.rtl .text-right {
    text-align: left !important;
}

.rtl .text-left {
    text-align: right !important;
}

@media (min-width: 576px) {
    .rtl .text-sm-right {
        text-align: left !important;
    }
    .rtl .text-sm-left {
        text-align: right !important;
    }
}

@media (min-width: 768px) {
    .rtl .text-md-right {
        text-align: left !important;
    }
    .rtl .text-md-left {
        text-align: right !important;
    }
}

@media (min-width: 992px) {
    .rtl .text-lg-right {
        text-align: left !important;
    }
    .rtl .text-lg-left {
        text-align: right !important;
    }
}

@media (min-width: 1200px) {
    .rtl .text-xl-right {
        text-align: left !important;
    }
    .rtl .text-xl-left {
        text-align: right !important;
    }
}

@media (min-width: 1440) {
    .rtl .text-xxl-right {
        text-align: left !important;
    }
    .rtl .text-xxl-left {
        text-align: right !important;
    }
}

.rtl .mx-0 {
    margin-right: auto;
    margin-left: 0 !important;
}

.rtl .mx-0 {
    margin-left: auto;
    margin-right: 0 !important;
}

.rtl .mx-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
}

.rtl .mx-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
}

.rtl .mx-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
}

.rtl .mx-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
}

.rtl .mx-3 {
    margin-right: auto;
    margin-left: 1rem !important;
}

.rtl .mx-3 {
    margin-left: auto;
    margin-right: 1rem !important;
}

.rtl .mx-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
}

.rtl .mx-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
}

.rtl .mx-5 {
    margin-right: auto;
    margin-left: 3rem !important;
}

.rtl .mx-5 {
    margin-left: auto;
    margin-right: 3rem !important;
}

.rtl .px-0 {
    padding-right: auto;
    padding-left: 0 !important;
}

.rtl .px-0 {
    padding-left: auto;
    padding-right: 0 !important;
}

.rtl .px-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
}

.rtl .px-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
}

.rtl .px-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
}

.rtl .px-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
}

.rtl .px-3 {
    padding-right: auto;
    padding-left: 1rem !important;
}

.rtl .px-3 {
    padding-left: auto;
    padding-right: 1rem !important;
}

.rtl .px-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
}

.rtl .px-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
}

.rtl .px-5 {
    padding-right: auto;
    padding-left: 3rem !important;
}

.rtl .px-5 {
    padding-left: auto;
    padding-right: 3rem !important;
}

.rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .rtl .mx-sm-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-sm-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-sm-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-sm-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-sm-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-sm-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-sm-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-sm-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-sm-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-sm-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-sm-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-sm-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-sm-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-sm-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-sm-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-sm-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-sm-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-sm-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-sm-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-sm-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-sm-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-sm-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-sm-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-sm-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-sm-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-sm-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .rtl .mx-md-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-md-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-md-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-md-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-md-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-md-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-md-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-md-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-md-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-md-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-md-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-md-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-md-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-md-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-md-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-md-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-md-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-md-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-md-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-md-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-md-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-md-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-md-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-md-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-md-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-md-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .rtl .mx-lg-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-lg-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-lg-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-lg-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-lg-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-lg-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-lg-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-lg-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-lg-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-lg-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-lg-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-lg-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-lg-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-lg-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-lg-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-lg-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-lg-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-lg-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-lg-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-lg-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-lg-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-lg-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-lg-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-lg-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-lg-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-lg-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .rtl .mx-xl-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-xl-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-xl-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-xl-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-xl-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-xl-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-xl-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-xl-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-xl-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-xl-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-xl-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-xl-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-xl-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-xl-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-xl-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-xl-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-xl-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-xl-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-xl-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-xl-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-xl-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-xl-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-xl-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-xl-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-xl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-xl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 1440) {
    .rtl .mx-xxl-0 {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .rtl .mx-xxl-0 {
        margin-left: auto;
        margin-right: 0 !important;
    }
    .rtl .mx-xxl-1 {
        margin-right: auto;
        margin-left: 0.25rem !important;
    }
    .rtl .mx-xxl-1 {
        margin-left: auto;
        margin-right: 0.25rem !important;
    }
    .rtl .mx-xxl-2 {
        margin-right: auto;
        margin-left: 0.5rem !important;
    }
    .rtl .mx-xxl-2 {
        margin-left: auto;
        margin-right: 0.5rem !important;
    }
    .rtl .mx-xxl-3 {
        margin-right: auto;
        margin-left: 1rem !important;
    }
    .rtl .mx-xxl-3 {
        margin-left: auto;
        margin-right: 1rem !important;
    }
    .rtl .mx-xxl-4 {
        margin-right: auto;
        margin-left: 1.5rem !important;
    }
    .rtl .mx-xxl-4 {
        margin-left: auto;
        margin-right: 1.5rem !important;
    }
    .rtl .mx-xxl-5 {
        margin-right: auto;
        margin-left: 3rem !important;
    }
    .rtl .mx-xxl-5 {
        margin-left: auto;
        margin-right: 3rem !important;
    }
    .rtl .px-xxl-0 {
        padding-right: auto;
        padding-left: 0 !important;
    }
    .rtl .px-xxl-0 {
        padding-left: auto;
        padding-right: 0 !important;
    }
    .rtl .px-xxl-1 {
        padding-right: auto;
        padding-left: 0.25rem !important;
    }
    .rtl .px-xxl-1 {
        padding-left: auto;
        padding-right: 0.25rem !important;
    }
    .rtl .px-xxl-2 {
        padding-right: auto;
        padding-left: 0.5rem !important;
    }
    .rtl .px-xxl-2 {
        padding-left: auto;
        padding-right: 0.5rem !important;
    }
    .rtl .px-xxl-3 {
        padding-right: auto;
        padding-left: 1rem !important;
    }
    .rtl .px-xxl-3 {
        padding-left: auto;
        padding-right: 1rem !important;
    }
    .rtl .px-xxl-4 {
        padding-right: auto;
        padding-left: 1.5rem !important;
    }
    .rtl .px-xxl-4 {
        padding-left: auto;
        padding-right: 1.5rem !important;
    }
    .rtl .px-xxl-5 {
        padding-right: auto;
        padding-left: 3rem !important;
    }
    .rtl .px-xxl-5 {
        padding-left: auto;
        padding-right: 3rem !important;
    }
    .rtl .mr-xxl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }
    .rtl .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .rtl .ml-xxl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .rtl .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

.rtl .float-left {
    float: right !important;
}

.rtl .float-right {
    float: left !important;
}

.rtl .float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .rtl .float-sm-left {
        float: right !important;
    }
    .rtl .float-sm-right {
        float: left !important;
    }
    .rtl .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .rtl .float-md-left {
        float: right !important;
    }
    .rtl .float-md-right {
        float: left !important;
    }
    .rtl .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .rtl .float-lg-left {
        float: right !important;
    }
    .rtl .float-lg-right {
        float: left !important;
    }
    .rtl .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .rtl .float-xl-left {
        float: right !important;
    }
    .rtl .float-xl-right {
        float: left !important;
    }
    .rtl .float-xl-none {
        float: none !important;
    }
}

@media (min-width: 1440) {
    .rtl .float-xxl-left {
        float: right !important;
    }
    .rtl .float-xxl-right {
        float: left !important;
    }
    .rtl .float-xxl-none {
        float: none !important;
    }
}

[direction="rtl"][data-theme-version="dark"] .border,
[direction="rtl"][data-theme-version="dark"] .border-left,
[direction="rtl"][data-theme-version="dark"] .border-right {
    border-color: #424D63 !important;
}

[direction="rtl"] .text-right {
    text-align: left !important;
}

[direction="rtl"] .text-left {
    text-align: right !important;
}

[direction="rtl"] .border-right {
    border-left: 1px solid #eaeaea !important;
    border-right: 0 !important;
}

[direction="rtl"] .border-left {
    border-right: 1px solid #eaeaea !important;
    border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
    left: auto;
}

[direction="rtl"] .dropdown-menu-right {
    left: 0;
    right: auto;
}

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
    left: auto;
    right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
    left: auto;
    right: 0;
}

[direction="rtl"] .nav-header {
    text-align: right;
    right: auto;
}

[direction="rtl"] .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 2.5rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo,
[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
    padding-right: 0;
}

@media (max-width: 767.98px) {
    [direction="rtl"] .nav-header .brand-logo {
        padding-right: 0;
    }
}

[direction="rtl"] .nav-control {
    right: auto;
    left: -3.5rem;
}

@media (max-width: 767.98px) {
    [direction="rtl"] .nav-control {
        left: -3rem;
    }
}

@media (max-width: 575.98px) {
    [direction="rtl"] .nav-control {
        left: -2.5rem;
    }
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
    right: 0;
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line:nth-child(1) {
    transform: translateY(0.4rem) rotate(-45deg);
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-0.4rem) rotate(45deg);
}

[direction="rtl"] .header {
    padding: 0 0.9375rem;
    padding-right: 17.1875rem;
}

@media (max-width: 767.98px) {
    [direction="rtl"] .header {
        padding-right: 3.75rem;
        padding-left: 0;
    }
}

[direction="rtl"] .header .header-content {
    padding-left: 0;
    padding-right: 4.375rem;
}

@media only screen and (max-width: 575px) {
    [direction="rtl"] .header .header-content {
        padding-right: 2.375rem;
    }
}

[direction="rtl"] .header .nav-control {
    right: 0.4375rem;
    left: auto;
}

[direction="rtl"][data-layout="horizontal"] .header {
    padding: 0 0.9375rem;
    padding-right: 9.375rem;
}

[direction="rtl"][data-sidebar-style="mini"] .header {
    padding: 0 0.9375rem;
    padding-right: 3.75rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
    padding: 0 0.9375rem;
    padding-right: 9.375rem;
}

[direction="rtl"] .quixnav {
    text-align: right;
}

@media only screen and (min-width: 1800px) {
    [direction="rtl"] .quixnav {
        right: 3.125rem;
    }
}

[direction="rtl"] .quixnav .metismenu li.active>.has-arrow:after {
    transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .quixnav .metismenu .has-arrow:after {
    left: 2.5rem;
    right: auto;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .quixnav .metismenu li>ul li:hover ul {
    right: 11.8125rem;
    left: 0;
}

[direction="rtl"] .content-body {
    margin-right: 17.1875rem;
    margin-left: auto;
}

[direction="rtl"] .content-body .page-titles {
    text-align: right;
}

[direction="rtl"][data-layout="horizontal"] .content-body {
    margin-right: 0;
}

[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 3.75rem;
}

[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 9.375rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .content-body {
    margin-right: 0;
}

[direction="rtl"] .sidebar-right {
    left: -15.625rem;
    right: auto;
}

[direction="rtl"] .sidebar-right.show {
    left: 0;
    right: auto;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
    left: 100%;
    right: auto;
}

html[dir="rtl"] [direction="rtl"] .footer {
    padding-right: 17.1875rem;
    padding-left: 0;
}

@media (max-width: 767.98px) {
    html[dir="rtl"] [direction="rtl"] .footer {
        padding-right: 0;
    }
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
    padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
    padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
    padding-right: 0;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
    padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
    padding-right: 9.375rem;
}

[data-theme-version="dark"] {
    background: #2A2C32;
    color: #828690;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] h6 {
    color: #fff !important;
}

[data-theme-version="dark"] a.link {
    color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
    color: #50E3C2;
}

[data-theme-version="dark"] a {
    color: #fff;
}

[data-theme-version="dark"] a:hover {
    color: #828690;
}

[data-theme-version="dark"] .border-right {
    border-right: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border-left {
    border-left: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border-top {
    border-top: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border-bottom {
    border-bottom: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .border {
    border: 1px solid #424D63 !important;
}

[data-theme-version="dark"] .dropdown-menu {
    background-color: #323A4B;
}

[data-theme-version="dark"] .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-item:focus {
    background-color: #2A2C32;
    color: #fff;
}

[data-theme-version="dark"] .form-control {
    background-color: #2A2C32;
    border-color: #424D63;
    color: #fff;
}

[data-theme-version="dark"] .card {
    background-color: #323A4B;
}

[data-theme-version="dark"] .header {
    background-color: #593bdb;
}

[data-theme-version="dark"] .header-left input {
    border-color: transparent;
    color: #fff;
}

[data-theme-version="dark"] .header-left input:focus {
    box-shadow: none;
    border-color: #593bdb;
}

[data-theme-version="dark"] .header-left input::placeholder {
    color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
    color: #fff;
}

[data-theme-version="dark"] .header-right .notification_dropdown .dropdown-item a {
    color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
    background-color: #593bdb;
}

[data-theme-version="dark"] .nav-control {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
    color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
    background-color: #593bdb;
}

[data-theme-version="dark"] .nav-control {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo {
    color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
    color: #fff;
}

[data-theme-version="dark"] .quixnav {
    background-color: #2A2C32;
}

[data-theme-version="dark"] .quixnav .metismenu>li>a {
    color: #b3b3b3;
}

[data-theme-version="dark"] .quixnav .metismenu>li:hover>a,
[data-theme-version="dark"] .quixnav .metismenu>li:focus>a,
[data-theme-version="dark"] .quixnav .metismenu>li.mm-active>a {
    background-color: transparent;
    color: #593bdb;
}

[data-theme-version="dark"] .quixnav .metismenu>li:hover>a::after,
[data-theme-version="dark"] .quixnav .metismenu>li:focus>a::after,
[data-theme-version="dark"] .quixnav .metismenu>li.mm-active>a::after {
    border-color: transparent transparent #fff transparent;
}

[data-theme-version="dark"] .quixnav .metismenu>li.mm-active ul ul {
    background-color: transparent;
}

[data-theme-version="dark"] .quixnav .metismenu ul {
    background-color: #2A2C32;
}

[data-theme-version="dark"] .quixnav .metismenu ul a:hover,
[data-theme-version="dark"] .quixnav .metismenu ul a:focus,
[data-theme-version="dark"] .quixnav .metismenu ul a.mm-active {
    color: #fff;
}

[data-theme-version="dark"] .quixnav .metismenu a {
    color: #b3b3b3;
}

[data-theme-version="dark"] .quixnav .metismenu .has-arrow:after {
    border-color: transparent transparent #b3b3b3 transparent;
}

[data-theme-version="dark"] .sidebar-right {
    background-color: #31343b;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs {
    background-color: #31343b;
    border-bottom: 1px solid #2A2C32;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-link.active {
    background-color: #2A2C32;
}

[data-theme-version="dark"] .footer {
    background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
    background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
    color: #fff;
}


/*# sourceMappingURL=style.css.map */